import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Button, IconButton, Toolbar } from "@mui/material";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ThemeSwitchButton from "@/components/buttons/ThemeSwitchButton";
import ToolbarHelpButton from "@/components/buttons/ToolbarHelpButton";
import Notifications from "@/components/notifications/Notifications";
import { useRouter } from "next/router";

type StyledAppBarPropType = AppBarProps & {
  width?: number;
  open: boolean;
};

type FederatedPropType = {
  enabled: boolean;
  email: string | undefined;
  release: () => void;
};

type BaseAppBarPropType = {
  width: number;
  open: boolean;
  position: "fixed" | "sticky" | "absolute" | "static" | "relative";
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  federatedMode: FederatedPropType;
};

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<StyledAppBarPropType>(({ theme, open, width }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${width}px)`,
      marginLeft: `${width}px`,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 0,
    },

    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const FederatedMode: React.FC<FederatedPropType> = ({
  enabled,
  email,
  release,
}) => {
  const router = useRouter();
  const releaseUser = React.useCallback(() => {
    release();
    router.reload();
  }, [release, router]);

  return enabled && email ? (
    <Box
      data-testid="federated-mode"
      sx={{
        py: 1,
        order: { xs: -1, lg: 0 },
        width: { xs: "100%", lg: "auto" },
      }}
    >
      <Button
        fullWidth
        color="warning"
        variant="outlined"
        onClick={releaseUser}
      >
        Release User {email}
      </Button>
    </Box>
  ) : null;
};

const BaseAppBar: React.FC<BaseAppBarPropType> = ({
  open,
  setOpen,
  width,
  position = "relative",
  federatedMode,
}) => {
  const theme = useTheme();

  return (
    <StyledAppBar
      elevation={1}
      position={position}
      width={width}
      open={open}
      sx={{ bgcolor: theme.palette.background.paper }}
    >
      <Toolbar
        data-testid="app-toolbar"
        sx={{
          flexWrap: {
            xs: "wrap",
          },
          justifyContent: "flex-start",
          alignItems: "stretch",
          py: 2,
        }}
      >
        <Box
          sx={{
            display: !open ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={() => setOpen((prev) => !prev)}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <FederatedMode
          enabled={federatedMode.enabled}
          email={federatedMode.email}
          release={federatedMode.release}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            ml: "auto",
          }}
        >
          <ThemeSwitchButton />
          <ToolbarHelpButton />
          <Notifications />
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default BaseAppBar;
