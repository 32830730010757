import { IDVerificationType } from "@/types/IDVerificationType";
import { fetcher } from "@/utils/fetcher";
import { formBackendURL } from "@/utils/formURL";
import { useMemo } from "react";
import useSWR from "swr";
import { useVerificationFlow } from "./useVerificationFlow";
import useVerificationQuestions from "./useVerificationQuestions";
import { canRetryIdVerification } from "@/components/IdVerification/helpers";

export default function useNeedsAccountVerification() {
  const { data: verification, isLoading: idVerificationLoading } =
    useSWR<IDVerificationType>(formBackendURL("/idverification/"), fetcher);

  const { data: { results: flows } = {}, isLoading: areFlowsLoading } =
    useVerificationFlow({
      flowType__in: ["acceptable_use_violation", "fraudulent_payment"],
      state: "inflow",
    });

  const {
    data: { results: questionResults } = {},
    isLoading: areQuestionsLoading,
  } = useVerificationQuestions({
    answer__isnull: true,
  });
  const hasUnansweredQuestions = !!questionResults?.length;

  const needsEvidence = useMemo(
    () => !!flows?.some((item) => item.needs_evidence),
    [flows]
  );

  const { state } = verification || {};

  const needsIdVerification = useMemo(
    () =>
      flows?.filter((item) => item.id_verification_required)?.length &&
      state !== "verified",
    [flows, state]
  );

  const shouldRetryIdVerification = useMemo(() => {
    if (!verification) return false;
    if (
      canRetryIdVerification(verification) &&
      verification.state === "failed"
    ) {
      return true;
    }
    return false;
  }, [verification]);

  return {
    needsEvidence,
    hasUnansweredQuestions,
    needsIdVerification,
    needsAnyVerification:
      needsEvidence ||
      (Number(flows?.length) > 0 && hasUnansweredQuestions) ||
      needsIdVerification,
    flows,
    isLoading: idVerificationLoading || areFlowsLoading || areQuestionsLoading,
    shouldRetryIdVerification: shouldRetryIdVerification && needsIdVerification,
  };
}
