import { styled, DialogContent as MUIDialogContent } from "@mui/material";

/*
  Dialog component currently has a MUI bug where padding's don't work.
  This is causing display issues when children have text fields. 
  Remove DialogContent style when this is fixed. 
  https://github.com/mui/material-ui/issues/27851
*/

export const DialogContentWithInput = styled(MUIDialogContent)(({ theme }) => ({
  paddingTop: `${theme.spacing(1.5)}!important`,
}));
