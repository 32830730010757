import WebshareApi from "..";
import { NotificationObjectType } from "@/types/NotificationTypes";

const webshareApi = WebshareApi.getInstance();

export const dismissNotification = (id: number) =>
  webshareApi.post<NotificationObjectType>(`/notification/${id}/dismiss/`);

export const restoreNotification = (id: number) =>
  webshareApi.post<NotificationObjectType>(`/notification/${id}/restore/`);
