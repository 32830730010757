import type { NotificationItemComponent } from "./NotificationItem";

import React from "react";
import { useRouter } from "next/router";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button } from "@mui/material";
import { useSession } from "@/security/RouteGuards";
import NotificationItem from "./NotificationItem";
import useRecommended from "@/components/subscription/customize/hooks/useRecommended";
import { formCustomizePageHref } from "@/utils/formHref";

const OptimizeProxyCount: React.FC = () => {
  const session = useSession();
  if (!session.plan) {
    return (
      <Box component="span">
        Adding more proxies, you can improve your proxy’s performance.
      </Box>
    );
  }
  return (
    <Box>
      You have{" "}
      {session.plan && session.plan.bandwidth_limit > 0
        ? `${session.plan.bandwidth_limit.toLocaleString()}  GB `
        : "Unlimited bandwidth "}
      bandwidth in your plan and it’s not utilized yet. By adding more proxies,
      you can improve your proxy’s performance.
    </Box>
  );
};

const TooMuchBandwidthTooLittleProxies: NotificationItemComponent = ({
  item,
}) => {
  const router = useRouter();
  const { proxyCount } = useRecommended();

  const handleOptimize = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    router.replace(
      formCustomizePageHref({
        source: "notification",
        showCustomizeSections: ["proxy-count"],
        ...proxyCount,
      })
    );
  };

  return (
    <NotificationItem variant={!item.dismissed_at ? "primary" : "default"}>
      <NotificationItem.Title created_at={item.created_at}>
        <span>Optimize proxy count</span>
      </NotificationItem.Title>
      <NotificationItem.Content>
        <OptimizeProxyCount />
      </NotificationItem.Content>
      <NotificationItem.Actions {...item}>
        <Button
          onClick={handleOptimize}
          size="small"
          variant={!item.dismissed_at ? "contained" : "outlined"}
          color="primary"
          disableElevation
          endIcon={<OpenInNewIcon fontSize="small" />}
        >
          Optimize
        </Button>
      </NotificationItem.Actions>
    </NotificationItem>
  );
};

export default TooMuchBandwidthTooLittleProxies;
