import WebshareApi from "../api";

const baseURL = new URL(String(process.env.NEXT_PUBLIC_BACKEND));

const trailMsg =
  "API pathname should always end with trailing slash. If you need to override this, you may pass `false` as second parameter.";

export function formBackendUrlString(
  path: string,
  trail: boolean = true
): string {
  const url = new URL([baseURL.pathname, path].join(""), baseURL.origin);

  if (!/\/$/.test(url.pathname) && trail) {
    throw new Error(trailMsg);
  }

  return url.href;
}

export function formBackendURL(
  path: string,
  trail: boolean = true
): Array<string> {
  if (!WebshareApi.getSubUserId()) {
    return [formBackendUrlString(path, trail)];
  }

  return [
    formBackendUrlString(path, trail),
    String(WebshareApi.getSubUserId()),
  ];
}

export function formFrontEndUrl(path: string) {
  let href = path;

  if (path.search(/^http[s]?:\/\//)) {
    href = `${process.env.NEXT_PUBLIC_FRONTEND}${href}`;
  }

  return href;
}
