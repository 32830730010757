import type { NotificationItemComponent } from "./NotificationItem";

import React from "react";
import { useRouter } from "next/router";
import { Typography, Button, ButtonProps } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import useRecommended from "@/components/subscription/customize/hooks/useRecommended";
import NotificationItem from "./NotificationItem";
import { stringifyBase64 } from "@/hooks/useQueryParamState";
import { useSession } from "@/security/RouteGuards";
import { DEFAULT_CHECKOUT_PATHNAME } from "@/utils/formHref";
import useIntercom from "@/hooks/useIntercom";

const HighConcurrencyErrorNotification: NotificationItemComponent = ({
  item,
}) => {
  const router = useRouter();
  const { bandwidth } = useRecommended();
  const { plan } = useSession();

  const handleUpgrade = React.useCallback(() => {
    if (!router || !bandwidth) return;

    const formData = {
      isHighConcurrency: true,
    };
    const base64String = stringifyBase64(formData);

    router.replace({
      pathname: DEFAULT_CHECKOUT_PATHNAME,
      query: {
        customizeParams: base64String,
      },
    });
  }, [router, bandwidth]);

  const { showNewMessage } = useIntercom();

  const handleContactSupport = () => {
    showNewMessage(
      "I have received high concurrency while on high concurrency plan."
    );
  };

  const buttonProps: ButtonProps = {
    size: "small",
    disableElevation: true,
    color: !item.dismissed_at ? "warning" : "primary",
  };

  return (
    <NotificationItem variant={!item.dismissed_at ? "warning" : "default"}>
      <NotificationItem.Title created_at={item.created_at}>
        <span>High Concurrency Error Detected</span>
      </NotificationItem.Title>
      <NotificationItem.Content>
        <Typography variant="body2">
          More than 3% of your proxy requests are experiencing concurrency
          errors. It may be causing unstable proxy activity. Add High
          Concurrency feature to avoid any interruptions in proxy activity.
        </Typography>
      </NotificationItem.Content>
      <NotificationItem.Actions {...item}>
        {plan?.is_high_concurrency ? (
          <Button
            variant="outlined"
            onClick={handleContactSupport}
            {...buttonProps}
            data-testid="contact-support-button"
          >
            <span>Contact Support</span>
          </Button>
        ) : (
          <Button
            variant={!item.dismissed_at ? "contained" : "outlined"}
            endIcon={<OpenInNewIcon fontSize="small" />}
            onClick={handleUpgrade}
            {...buttonProps}
            data-testid="upgrade-button"
          >
            <span>Upgrade</span>
          </Button>
        )}
      </NotificationItem.Actions>
    </NotificationItem>
  );
};

export default HighConcurrencyErrorNotification;
