import { styled } from "@mui/material";

const ImgHelper = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default ImgHelper;
