import React from "react";
import { useColorModeContext } from "@/context/ColorModeContext";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { PostHogEvents, useEvents } from "@/tracking/useEvents";

const StyledThemeSwitchButton = styled(Switch)(({ theme }) => ({
  width: 60,
  height: 32,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    margin: 0,
    padding: 2,
    transform: "translateX(0)",
    transition: "transform 0.1s cubic-bezier(0.4, 0, 0.2, 1)",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-checked": {
      transform: "translateX(28px)",
      transition: "transform 0.1s cubic-bezier(0.4, 0, 0.2, 1)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.grey[50]
        )}" d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-.46-.04-.92-.1-1.36-.98 1.37-2.58 2.26-4.4 2.26-2.98 0-5.4-2.42-5.4-5.4 0-1.81.89-3.42 2.26-4.4-.44-.06-.9-.1-1.36-.1z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.common.black
            : theme.palette.grey[200],
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.grey[900]
        : theme.palette.common.white,
    width: 28,
    height: 28,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        theme.palette.mode === "dark"
          ? theme.palette.common.white
          : theme.palette.common.black
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.common.black
        : theme.palette.grey[200],
    borderRadius: 32,
  },
}));

const ThemeSwitchButton: React.FC = () => {
  const { captureEvent } = useEvents();
  const { mode, toggleColorMode } = useColorModeContext();

  const checked = React.useMemo(() => Boolean(mode === "dark"), [mode]);
  const onChange = React.useCallback(() => {
    toggleColorMode();

    captureEvent(PostHogEvents.SwitchedColorScheme, {
      "New Color Scheme": mode === "dark" ? "light" : "dark",
    });
  }, [captureEvent, mode, toggleColorMode]);

  return (
    <Box
      sx={{
        minWidth: 64,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mr: 1,
      }}
    >
      <StyledThemeSwitchButton checked={checked} onChange={onChange} />
    </Box>
  );
};

export default ThemeSwitchButton;
