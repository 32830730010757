import { useSession } from "@/security/RouteGuards";
/* eslint-disable-next-line no-restricted-imports */
import { useIntercom as reactUseIntercom } from "react-use-intercom";

export default function useIntercom() {
  const {
    showSpace,
    showArticle: _showArticle,
    startChecklist,
    showNewMessage,
    shutdown,
    hardShutdown,
    update,
  } = reactUseIntercom();
  const { profile } = useSession();

  function showArticle(article: Parameters<typeof _showArticle>[0]) {
    _showArticle(article);
  }

  let showChat = null;

  if (profile) {
    showChat = async () => {
      showSpace("messages");
    };
  } else {
    showChat = async () => {
      showSpace("messages");
    };
  }

  return {
    showChat,
    showArticle,
    shutdown,
    hardShutdown,
    showSpace,
    showNewMessage,
    startChecklist,
    update,
  };
}
