import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import {
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
  styled,
} from "@mui/material";
import AnnounceKit from "announcekit-react";
import { useSession } from "@/security/RouteGuards";
import useIntercom from "@/hooks/useIntercom";

type AnnounceKitCustomType = {
  open: () => void;
  close: () => void;
  unread: () => Promise<number>;
};

const StyledBox = styled(Box)(() => ({
  ".announcekit-widget-badge": {
    display: "none !important;",
  },
}));

const ToolbarHelpButton: React.FC = () => {
  const session = useSession();
  const announceRef = React.createRef<AnnounceKitCustomType>();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [unread, setUnread] = React.useState(0);

  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const openAnnounceKit = React.useCallback(() => {
    if (!announceRef.current) return;
    announceRef.current.open();
  }, [announceRef]);

  const { showChat } = useIntercom();

  React.useEffect(() => {
    if (!announceRef.current) return;
    const announcekit = announceRef.current;

    announcekit
      .unread()
      .then((unread) => setUnread(unread))
      .catch(() => console.error("Error getting unread count"));
  }, [announceRef]);

  return (
    <StyledBox
      data-testid="toolbar-help-button"
      sx={{
        width: isDesktop ? 64 : 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <IconButton onClick={handleClick} size={isDesktop ? "medium" : "small"}>
        <HelpOutlineIcon fontSize={isDesktop ? "medium" : "small"} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{ "& .announcekit-widget-badge": { display: "none" } }}
        PaperProps={{
          elevation: 1,
          sx: {
            minWidth: 220,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            showChat();
            handleClose();
          }}
        >
          <Typography variant="body2">Get Help</Typography>
        </MenuItem>
        <MenuItem onClick={() => openAnnounceKit()}>
          <Typography
            component="div"
            variant="body2"
            color="neutral"
            sx={{ position: "relative" }}
          >
            What&apos;s New?
          </Typography>

          {Boolean(unread > 0) && (
            <Chip
              sx={{ mx: 2 }}
              size="small"
              label={<Typography variant="caption">{unread}</Typography>}
              color="primary"
            />
          )}
        </MenuItem>
      </Menu>
      <AnnounceKit
        ref={announceRef}
        user={{
          id: String((session.profile && session.profile.tracking_id) ?? ""),
        }}
        widget="https://announcekit.app/widgets/v2/1bTKTu"
        boosters={false}
      />
    </StyledBox>
  );
};

export default ToolbarHelpButton;
