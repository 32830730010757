import { useFeatureFlagVariantKey } from "posthog-js/react";

export default function useIsFlagVariantTest(featureName: string) {
  return useFeatureFlagVariantKey(featureName) === "test";

  // useFeatureFlagVariantKey used to have issues
  // with some logic we have in customize page

  // revert to the below if this issue comes back
  // using the below might cause some hydration errors !

  // try {
  //   const posthog = usePostHog();
  //   return posthog?.getFeatureFlag(featureName) === "test";
  // } catch (e) {
  //   return false;
  // }
}
