import type { PaletteMode } from "@mui/material";
import { createContext, useContext } from "react";

const ColorModeContext = createContext({
  mode: "light" as PaletteMode,
  toggleColorMode: () => {},
});

export function useColorModeContext() {
  return useContext(ColorModeContext);
}

export default ColorModeContext;
