import { SetStateAction, Dispatch } from "react";
import { captureMessage, withScope } from "@sentry/nextjs";
import axios, { AxiosError } from "axios";
import { isObjKey } from "./typeCheckUtils";

export interface IErrorData {
  [key: string]: {
    code: string;
    message: string;
  }[];
}

interface IProps {
  error: unknown;
  errorHandlers?: IErrorHandlers;
  setSnackbarErrorMessage?: (value: string) => void;
}

interface IErrorErrorCodeHandlers {
  [key: string]: Dispatch<SetStateAction<string | null>>;
}

export interface IErrorHandlers {
  [key: string]:
    | Dispatch<SetStateAction<string | null>>
    | IErrorErrorCodeHandlers
    | ((param: string) => void);
}

export function isAxiosError<ResponseType>(
  error: unknown
): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error);
}

export function logAndPrintWhenNoErrorHandler(
  errorData: IErrorData,
  setSnackbarErrorMessage?: (value: string) => void
) {
  const errorMessageList: string[] = [];
  const keyList: string[] = [];

  Object.entries(errorData).forEach(([key, errorArray]) => {
    const errorMessages = errorArray
      ? errorArray?.map(({ message }) => message).join(" ")
      : "No error message";

    errorMessageList.push(errorMessages);
    keyList.push(key);
  });

  //Snackbar should handle long cases.
  const cumErrorMessage = errorMessageList.join(" ");
  if (setSnackbarErrorMessage) {
    setSnackbarErrorMessage(cumErrorMessage);
  }

  withScope((scope) => {
    scope.setLevel("error");
    scope.setExtra("errorHandlers", "None");
    scope.setExtra("keylist", JSON.stringify(keyList));
    scope.setExtra("errorData", JSON.stringify(errorData));
    scope.setExtra("errorMessage", cumErrorMessage);
    captureMessage(`No errors are handled ${JSON.stringify(keyList)}`);
  });
}

export const setUiErrors = ({
  errorData,
  errorHandlers,
  setSnackbarErrorMessage,
}: {
  errorData: IErrorData;
  errorHandlers: IErrorHandlers | undefined;
  setSnackbarErrorMessage?: (value: string) => void;
}) => {
  //Always log and print error when no errorHandler is provided.
  if (!errorHandlers) {
    logAndPrintWhenNoErrorHandler(errorData, setSnackbarErrorMessage);
    return;
  }

  Object.entries(errorData).forEach(([key, errorArray]) => {
    const errorMessages =
      errorArray && Array.isArray(errorArray)
        ? errorArray.map(({ message }) => message).join(", ")
        : null;

    if (!errorMessages) {
      withScope((scope) => {
        scope.setLevel("error");
        scope.setExtra("errorArray", errorArray);
        scope.setExtra("errorData", JSON.stringify(errorData));
        captureMessage("Error should be present");
      });
    }

    if (isObjKey(key, errorHandlers)) {
      // Case errorHandlers are error code based.
      if (typeof errorHandlers[key] === "object") {
        // Loop through error codes and call setters.
        errorArray.forEach(({ code, message }) => {
          if (isObjKey(code, errorHandlers[key])) {
            (errorHandlers[key] as IErrorErrorCodeHandlers)[code](message);
          }
          // Log if error code is not handled for error
          else {
            //Print error on Drawer Error Snackbar. No code specified for this error.
            if (setSnackbarErrorMessage) {
              setSnackbarErrorMessage(message);
            }
            withScope((scope) => {
              scope.setLevel("error");
              scope.setExtra("errorData", JSON.stringify(errorData));
              captureMessage("Unhandled No code error");
            });
          }
        });
      }
      // Case errorHandlers are message based.
      else {
        (errorHandlers[key] as Dispatch<SetStateAction<string | null>>)(
          errorMessages
        );
      }
    } else {
      if (setSnackbarErrorMessage) {
        if (errorMessages) {
          setSnackbarErrorMessage(errorMessages);
        } else {
          withScope((scope) => {
            scope.setLevel("error");
            scope.setExtra("errorData", JSON.stringify(errorData));
            captureMessage(
              "a field is present on the server that is not handled in ui"
            );
          });
        }
      } else {
        withScope((scope) => {
          scope.setLevel("error");
          scope.setExtra("errorData", JSON.stringify(errorData));
          captureMessage("setSnackbarErrorMessage does not exist");
        });
      }
    }
  });
};

export const handleApiError = ({
  error,
  errorHandlers,
  setSnackbarErrorMessage,
}: IProps) => {
  // @ts-ignore
  if (error?.type === "fetch_error" && error?.name === "FetchError") {
    if (setSnackbarErrorMessage) {
      setSnackbarErrorMessage("Something went wrong please try again");
    }

    return;
  }

  // @ts-ignore
  if (error?.code === "ECONNABORTED") {
    if (setSnackbarErrorMessage) {
      setSnackbarErrorMessage("Connection timed out please try again");
    }
    return;
  }

  if (!isAxiosError<IErrorData>(error)) {
    if (setSnackbarErrorMessage) {
      setSnackbarErrorMessage("Something went wrong please try again");
    }
    return;
  }

  if (error?.response?.status !== 400) {
    return;
  }

  const errorData = error.response?.data;

  if (!errorData) {
    return;
  }

  const hasErrorData = Object.keys(errorData).length;

  if (!hasErrorData) {
    return;
  }

  setUiErrors({ errorData, errorHandlers, setSnackbarErrorMessage });
};
