import { IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { articles } from "@/utils/intercom";
import useIntercom from "@/hooks/useIntercom";

/**
 * Usage
 * ```tsx
 *  <Typography
 *    variant="h5"
 *    color="text.primary"
 *    sx={{ display: "flex", alignItems: "center" }} <-- remember this to align the icon
 *  >
 *    Rotating Proxy Endpoint
 *    <HelpArticle id="rotatingProxyEndpoint" /> <-- it's a child of the Typography
 *  </Typography>
 * ```
 * Use these test article id to check it works on dev `8581568` , `8581583`
 */
export default function HelpArticle({
  id,
  isCaption,
}: {
  id: keyof typeof articles;
  isCaption?: boolean;
}) {
  const { showArticle } = useIntercom();
  return (
    <IconButton
      onClick={() => showArticle(articles[id])}
      sx={{ ml: isCaption ? 0 : 1, top: isCaption ? "-2px" : undefined }}
      size="small"
      aria-label="Open help article"
      color="inherit"
    >
      <InfoOutlinedIcon color="inherit" fontSize="inherit" />
    </IconButton>
  );
}
