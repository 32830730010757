import type { Dispatch } from "react";
import type { ProfileType } from "@/types/AuthTypes";
import type { PlanType } from "@/types/PlanTypes";
import type { KeyedMutator } from "swr";
import type { ActivationStatusType } from "@/types/ActivationStatusTypes";
import type { SubscriptionType } from "@/types/SubscriptionTypes";

import { createContext, SetStateAction } from "react";

export type SessionMutateType = {
  mutateProfile?: KeyedMutator<ProfileType>;
  mutateSubscription?: KeyedMutator<SubscriptionType>;
  mutatePlan?: KeyedMutator<PlanType>;
  mutateAll: () => Promise<void>;
  setSessionToken?: Dispatch<SetStateAction<string | undefined>>;
  mutateActivationStatus?: KeyedMutator<ActivationStatusType>;
};

export const SessionMutateContext = createContext<SessionMutateType>({
  mutateAll: () =>
    new Promise((resolve) => {
      resolve(undefined);
    }),
});
