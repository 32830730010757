import { IconButton, Snackbar, SnackbarProps, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface CustomProps {
  isError?: boolean;
  snackbarOpen: boolean;
  setSnackbarOpen: () => void;
  message: string;
}

export default function CustomSnackbar(props: SnackbarProps & CustomProps) {
  const { isError, snackbarOpen, setSnackbarOpen, ...rest } = props;
  const { autoHideDuration, anchorOrigin, onClose, action } = rest;
  const theme = useTheme();

  const handleSnackbarClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen();
  };

  const defaultAction = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSnackbarClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <>
      <Snackbar
        ContentProps={
          isError
            ? {
                sx: {
                  background: theme.palette.error.main,
                },
              }
            : { sx: {} }
        }
        open={snackbarOpen}
        anchorOrigin={anchorOrigin ?? { vertical: "top", horizontal: "center" }}
        autoHideDuration={autoHideDuration ?? 6000}
        onClose={onClose ?? handleSnackbarClose}
        action={action ?? defaultAction}
        {...rest}
      />
    </>
  );
}
