import React from "react";
import { formatISO } from "date-fns";
import queryString from "query-string";
import useSWR from "swr";
import useSubscriptionTimestamps from "./useSubscriptionTimestamps";
import { AggregateStatsType } from "../types/AggregateStatsType";
import TimezoneUtils from "../utils/TimezoneUtils";
import { formBackendURL } from "../utils/formURL";
import { fetcher } from "../utils/fetcher";

export default function useSubscriptionStats() {
  const { timestampLte, timestampGte } = useSubscriptionTimestamps();
  const [queryStats, setQueryStats] = React.useState<string | undefined>();
  const { data } = useSWR<AggregateStatsType>(
    queryStats ? formBackendURL(`/stats/aggregate/?${queryStats}`) : null,
    fetcher
  );

  React.useEffect(() => {
    const qs = queryString.stringify({
      timestamp__lte: timestampLte
        ? formatISO(TimezoneUtils.unzonedDate(timestampLte).setSeconds(0, 0))
        : undefined,
      timestamp__gte: timestampGte
        ? formatISO(TimezoneUtils.unzonedDate(timestampGte).setSeconds(0, 0))
        : undefined,
    });

    if (qs) {
      setQueryStats(qs);
    }
  }, [timestampGte, timestampLte]);

  return data ?? undefined;
}
