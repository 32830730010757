import Image from "next/image";
import { Box, Typography } from "@mui/material";
import { FlexBetween } from "../common/styles";
import { Header } from "./commonStyles";
import { ColoredCard } from "@/ui/ColoredCard";

export default function VipWelcomeBanner() {
  return (
    <ColoredCard type="warning" sx={{ padding: 2 }}>
      <Box sx={{ px: 3, py: 2 }}>
        <FlexBetween>
          <Box>
            <Header variant="h6" type="normal">
              <Image
                src="/vipIcon.png"
                alt="vip_icon"
                width={40}
                height={40}
                style={{ marginBottom: "-12px", marginRight: "16px" }}
              />
              Welcome to VIP Member page
            </Header>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              We can&apos;t help but notice that you&apos;ve become a close
              friend. As a thank you for your loyalty, we are extending the
              Webshare VIP Membership benefits.
            </Typography>
          </Box>
        </FlexBetween>
      </Box>
    </ColoredCard>
  );
}
