import { Typography, styled } from "@mui/material";

export const Header = styled(Typography)<{
  type: "warning" | "error" | "normal";
}>(({ theme, type }) => ({
  color: {
    warning: theme.palette.warning.main,
    error: theme.palette.error.main,
    normal: theme.palette.text.primary,
  }[type],
}));
