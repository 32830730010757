import {
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Image from "next/image";
import dynamic from "next/dynamic";
import type { LottieProps } from "react-lottie-player";

const Lottie = dynamic(
  () => import("react-lottie-player").then((mod) => mod.default),
  {
    ssr: false,
  }
);
function Visual({
  lottieOptions,
  imageSrc,
  imageAlt,
  visualSize = 100,
}: {
  lottieOptions?: LottieProps;
  imageSrc?: string;
  imageAlt?: string;
  visualSize?: number;
}) {
  const theme = useTheme();
  return (
    <Stack sx={{ pt: 2, pb: 2 }} justifyContent="center">
      {lottieOptions ? (
        <Lottie
          {...lottieOptions}
          style={{ width: visualSize, height: visualSize }}
        />
      ) : null}
      {imageSrc ? (
        <Image
          alt={imageAlt || "image"}
          src={`${imageSrc}${theme.palette.mode === "dark" ? "-dark" : ""}.svg`}
          width={visualSize}
          height={visualSize}
          style={{ margin: "0 auto" }}
        />
      ) : null}
    </Stack>
  );
}

export default function InfoCard({
  title,
  description,
  backgroundColor,
  elevation = 0,
  subtitle,
  lottieOptions,
  imageSrc,
  imageAlt,
  visualSize,
  testId,
  children,
}: {
  title?: string;
  description: string;
  elevation?: number;
  backgroundColor?: string;
  subtitle?: string;
  lottieOptions?: LottieProps;
  imageAlt?: string;
  imageSrc?: string;
  visualSize?: number;
  testId?: string;
  children?: React.ReactNode;
}) {
  const theme = useTheme();
  const defaultBackgroundColor = theme.palette.background.paper;

  return (
    <Card
      sx={{ p: 2, backgroundColor: backgroundColor || defaultBackgroundColor }}
      data-testid={testId}
      elevation={elevation}
    >
      <CardContent
        sx={{
          pt: 0,
          pr: 0,
          pb: 2,
          pl: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Visual
          lottieOptions={lottieOptions}
          imageSrc={imageSrc}
          imageAlt={imageAlt}
          visualSize={visualSize}
        />
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ maxWidth: "520px" }}
        >
          {title ? (
            <Typography variant="h5" fontWeight="bold" textAlign="center">
              {title}
            </Typography>
          ) : null}
          <Typography
            variant="body1"
            color="text.secondary"
            textAlign="center"
            style={{ whiteSpace: "pre-line" }}
          >
            {subtitle ? <span>{`${subtitle}\n`}</span> : ""}
            <span>{description}</span>
          </Typography>
        </Stack>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center", p: 1 }}>
        <Stack direction="row" spacing={2}>
          {children}
        </Stack>
      </CardActions>
    </Card>
  );
}
