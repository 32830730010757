import { useState } from "react";
import { resendActivationEmailCall } from "../api/activation";
import useHandleApiErrors from "./useHandleApiError";

export default function useResendVerification() {
  const [isRefreshSuccess, setIsRefreshSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const errorHandlers = {
    non_field_errors: setErrorMessage,
  };

  const handleApiError = useHandleApiErrors(errorHandlers);

  async function resendActivationEmail() {
    try {
      setIsLoading(true);
      await resendActivationEmailCall();
      setIsRefreshSuccess(true);
      setIsLoading(false);
    } catch (error) {
      setIsRefreshSuccess(false);
      setIsLoading(false);
      handleApiError(error);
    }
  }

  return {
    errorMessage,
    setErrorMessage,
    isRefreshSuccess,
    setIsRefreshSuccess,
    resendActivationEmail,
    isLoading,
  };
}
