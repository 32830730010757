import { Box, Typography, useTheme } from "@mui/material";
import WebshareIconLogo from "./WebshareIconLogo";

export default function LoadingView() {
  const theme = useTheme();
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        bgcolor: theme.palette.background.paper,
      }}
    >
      <WebshareIconLogo />
      <Typography
        variant="h6"
        sx={{
          pt: 4,
        }}
      >
        Loading...
      </Typography>
    </Box>
  );
}
