export const mbToBytes = (mb?: number) => {
  if (!mb) return 0;
  const k = 1024;
  return Math.round(mb * Math.pow(k, 2) * 1e3) / 1e3;
};

export const gbToBytes = (gb?: number) => {
  if (!gb) return 0;
  const k = 1024;
  return Math.round(gb * Math.pow(k, 3) * 1e3) / 1e3;
};

export const tbToBytes = (tb?: number) => {
  if (!tb) return 0;
  const k = 1024;
  return Math.round(tb * Math.pow(k, 4) * 1e3) / 1e3;
};
