import type { NotificationObjectType } from "@/types/NotificationTypes";
import type { SxProps } from "@mui/material";

import React from "react";
import { useSWRConfig } from "swr";
import { Box, Button, Theme, Typography, alpha } from "@mui/material";
import TimezoneUtils from "@/utils/TimezoneUtils";
import { timeAgo } from "@/utils/timeUtils";
import { dismissNotification, restoreNotification } from "@/api/notification";
import { formBackendURL } from "@/utils/formURL";

type BasePropType = { children: React.ReactNode };
export type VariantPropType = {
  variant: "default" | "primary" | "info" | "success" | "warning" | "error";
  sx?: SxProps<Theme>;
};

type NotificationComponentType = React.FC<BasePropType & VariantPropType> & {
  Title: React.FC<
    BasePropType & { created_at: NotificationObjectType["created_at"] }
  >;
  Content: React.FC<BasePropType>;
  Actions: React.FC<{
    id: number;
    is_dismissable: boolean;
    dismissed_at: string | null;
    children?: React.ReactNode;
  }>;
};

const Title: NotificationComponentType["Title"] = ({
  created_at,
  children,
}) => {
  return (
    <Box component="header" data-testid="notification-item">
      <Typography variant="caption" color="text.secondary">
        {timeAgo(TimezoneUtils.zonedDate(created_at).toISOString())}
      </Typography>
      <Typography
        component="div"
        variant="body1"
        fontWeight="bold"
        color="text.primary"
        sx={{ mb: 2, cursor: "default" }}
        data-testid="notification-title"
      >
        {children}
      </Typography>
    </Box>
  );
};

const Content: NotificationComponentType["Content"] = ({ children }) => (
  <Typography
    component="div"
    color="text.secondary"
    variant="body2"
    sx={{ cursor: "default" }}
  >
    {children}
  </Typography>
);

const Actions: NotificationComponentType["Actions"] = ({
  id,
  children,
  dismissed_at,
  is_dismissable,
}) => {
  const { mutate } = useSWRConfig();
  const dismiss = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      dismissNotification(id).then(() => {
        mutate(formBackendURL("/notification/?dismissed_at__isnull=True"));
      });
    },
    [id, mutate]
  );

  const restore = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      restoreNotification(id).then(() => {
        mutate(formBackendURL("/notification/?dismissed_at__isnull=False"));
        mutate(formBackendURL("/notification/?dismissed_at__isnull=True"));
      });
    },
    [id, mutate]
  );

  return (
    <Box
      sx={{ mt: 3, mb: 2 }}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
    >
      {children && children}
      {/* TODO: Coloring
        Use theme neutal here instead of sx props after asking design team.
      */}
      {is_dismissable && (
        <Button
          data-testid="notification-dismiss-button"
          size="small"
          variant="text"
          disableElevation
          onClick={dismissed_at ? restore : dismiss}
          sx={{
            ml: children ? 2 : 0,
            px: 3,
            color: ({ palette }) => palette.text.secondary,
          }}
        >
          {dismissed_at ? "Unarchive" : "Archive"}
        </Button>
      )}
    </Box>
  );
};

const NotificationItem: NotificationComponentType = ({
  children,
  variant,
  sx,
}) => {
  return (
    <Box
      data-testid={`notification-${variant}`}
      sx={{
        width: "100%",
        p: 3,
        ...sx,
        backgroundColor: ({ palette }) =>
          variant === "default"
            ? palette.background.default
            : alpha(palette[variant].main, 0.08),
      }}
    >
      {children}
    </Box>
  );
};

NotificationItem.Title = Title;
NotificationItem.Content = Content;
NotificationItem.Actions = Actions;

export type NotificationItemComponent = React.FC<{
  children?: React.ReactNode;
  item: NotificationObjectType;
}>;
export default NotificationItem;
