import { useRouter } from "next/router";
import AccountVerificationBanner from "../banners/AccountVerificationBanner";
import BlackFridayBanner from "../banners/BlackFridayBanner";
import SubUserBanner from "../banners/SubUserBanner";
import VerifyEmailBanner from "../banners/VerifyEmailBanner";
import VipWelcomeBanner from "../banners/VipWelcomeBanner";

import { Stack } from "@mui/material";
import ChromeExtensionBanner from "../banners/ChromeExtensionBanner";

export default function Banners() {
  const router = useRouter();

  // VIP Banner should be displayed alone in VIP page
  if (router.pathname === "/profile/vip") {
    return <VipWelcomeBanner />;
  }

  return (
    <Stack direction="column" spacing={1}>
      <ChromeExtensionBanner />
      <BlackFridayBanner />
      <AccountVerificationBanner />
      <VerifyEmailBanner />

      {/* SubUserBanner should be always the last one in banners  */}
      <SubUserBanner />
    </Stack>
  );
}
