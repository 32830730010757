export const WEBSHARE_PROXY_DNS_ADDRESS =
  process.env.NODE_ENV === "development"
    ? "p.dev.webshare.io"
    : "p.webshare.io";

export const WEBSHARE_IP4_ADDRESS =
  process.env.NODE_ENV === "development"
    ? "ipv4.dev.webshare.io"
    : "ipv4.webshare.io";

export const INTERCOM_APP_ID =
  process.env.NODE_ENV === "development" ? "ruct6b4c" : "zsppwl0f";
