import type { AxiosError } from "axios";
import { RequireProperty } from "src/types";
import WebshareApi from "..";

export interface IAuthParams {
  email: string;
  password: string;
  recaptcha: string;
  tos_accepted?: boolean;
  anonymous_user_token?: string;
  marketing_email_accepted?: boolean;
}

export interface ISocialParams {
  provider: string;
  code: string;
  redirect_uri?: string;
  tos_accepted: boolean;
}

export interface IResetPasswordParams {
  email: string;
  recaptcha: string;
}

export interface ICompleteResetPasswordParams {
  password: string;
  password_reset_token: string;
  recaptcha: string;
}

export type ResponseErrorType<T extends unknown> = RequireProperty<
  AxiosError<T>,
  "response"
>;

export type ChangeEmailRequestType = {
  new_email: string;
  password: string;
};

export type ChangeEmailConfirmType = {
  confirmation_code: string;
};

export type ChangePasswordParamsType = {
  password: string;
  new_password: string;
};

const webshareApi = WebshareApi.getInstance();

export const register = (params: IAuthParams) =>
  webshareApi.post<{ token: string; logged_in_existing_user: boolean }>(
    "/register/",
    { ...params }
  );

export const social = ({
  endpoint,
  params,
}: {
  endpoint: "login" | "register";
  params: ISocialParams;
}) =>
  webshareApi.post<{ token: string; logged_in_existing_user: boolean }>(
    `/${endpoint}/social/`,
    { ...params }
  );

export const login = (params: IAuthParams) =>
  webshareApi.post("/login/", { ...params });

export const resetPassword = (params: IResetPasswordParams) =>
  webshareApi.post("/resetpassword/", { ...params });

export const completeResetPassword = (params: ICompleteResetPasswordParams) =>
  webshareApi.post<{ token: string }>("/resetpassword/complete/", {
    ...params,
  });

export const activate = (params: { activation_token: string }) =>
  webshareApi.post("/activation/complete/", { ...params });

export const logout = () => webshareApi.post("/logout/");

export const changePassword = (params: ChangePasswordParamsType) =>
  webshareApi.post("/changepassword/", params);

export const changeEmailRequest = (params: ChangeEmailRequestType) =>
  webshareApi.post("/changeemail/", params);

export const changeEmailCompleteRequest = (params: ChangeEmailConfirmType) =>
  webshareApi.post("/changeemail/complete/", params);

export const deleteAccount = (params: {
  password: string;
  recaptcha: string;
}) => webshareApi.post("/deleteaccount/", params);
