import {
  CustomizationSectionType,
  CustomizationSourceType,
} from "@/components/subscription/customize/hooks/useSections";
import { pickBy } from "lodash";

export type ShowCustomizeSectionsType = CustomizationSectionType[];

/**
 * @property {ShowCustomizeSectionsType}  showCustomizeSections  - An array of customize sections to display
 * @property {ReplaceRefreshRotatesSectionType}  showRRRTab - Which tab to display under list - rotate - replace
 * @property {string}  source - Source of the object, used for CTA tracking
 */

export type CustomizeHrefInputType = {
  showCustomizeSections?: ShowCustomizeSectionsType;
  showRRRTab?: ReplaceRefreshRotatesSectionType;
  source: CustomizationSourceType;
  recommendOnDemandRefreshes?: string;
  recommendReplace?: string;
  purchaseInitiatorPath?: string;
  menu?: string; //Used only by ResidentialProxyBanner.
};

export type ReplaceRefreshRotatesSectionType =
  | "autorefresh"
  | "replaceAll"
  | "replace";

export type CustomizeQueryParamsType = {
  show_customization_sections?: string; //Used for hiding customize page sections.
  show_rrr_tab?: ReplaceRefreshRotatesSectionType; //Used for showing a specific tab under replace/replaceAll/refresh section.
  source?: string; //Used by posthog for tracking
  recommend_on_demand_refreshes?: string; //Used for incrementing onDemandRefreshes by one.
  recommend_replace?: string; //Used for recommending replacements.
  purchase_initiator_path?: string; //Used for redirect to a page after purchase flow is complete
};

export type HrefType = {
  pathname: string;
  query: NodeJS.Dict<string | boolean>;
};

export const DEFAULT_CUSTOMIZE_PATHNAME = "/subscription/customize";

/**
 * Creates router / href input for customize page calls
 *
 * Used by CTA buttons
 *
 * @param  {Object} options
 * @param  {CustomizeHrefInputType} options.showCustomizeSections  Which option to display.
 * By default subusers section is not displayed by customize page when this field is undefined.
 * ALL_CUSTOMIZE_SECTIONS can be provided to display all sections including sub-users.
 * @param  {String} [options.source]  Source of the object, used for CTA tracking
 * @return {HrefType}  router params to use as href
 */
export function formCustomizePageHref({
  showCustomizeSections,
  showRRRTab,
  source,
  recommendOnDemandRefreshes,
  purchaseInitiatorPath,
  recommendReplace,
  ...others
}: CustomizeHrefInputType): HrefType {
  const cleanedObject = pickBy(
    {
      ["show_customization_sections"]:
        showCustomizeSections && showCustomizeSections.length > 0
          ? showCustomizeSections.join(",")
          : undefined,
      [SHOW_REPLACE_REFRESH_ROTATE_QUERY_PARAM_KEY]: showRRRTab,
      source,
      ["recommend_on_demand_refreshes"]: recommendOnDemandRefreshes,
      ["purchase_initiator_path"]: purchaseInitiatorPath,
      ["recommend_replace"]: recommendReplace,
      ...others,
    },
    (v) => v !== undefined
  );

  return {
    pathname: DEFAULT_CUSTOMIZE_PATHNAME,
    query: cleanedObject,
  };
}

export const SHOW_REPLACE_REFRESH_ROTATE_QUERY_PARAM_KEY = "show_rrr_tab";
export const DEFAULT_CHECKOUT_PATHNAME = "/subscription/customize/checkout";
export const DEFAULT_CUSTOMIZE_DONE_PATHNAME = "/subscription/customize/done";

export type CheckoutHrefInputType = {
  source?: string;
  renew?: boolean;
  customizeParams?: string;
  term?: "monthly" | "yearly";
};

export function formCheckoutPageHref({
  source,
  renew,
  term,
  customizeParams,
}: CheckoutHrefInputType): HrefType {
  return {
    pathname: DEFAULT_CHECKOUT_PATHNAME,
    query: {
      customizeParams,
      source,
      renew,
      term,
    },
  };
}

export function convertHrefObjectToString(hrefObject: HrefType) {
  const { pathname, query } = hrefObject;
  const searchParams = new URLSearchParams(query as {}).toString();
  return `${pathname}?${searchParams}`;
}
