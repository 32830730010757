import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ApiIcon from "@mui/icons-material/Api";
import AffiliateNavItem from "./NavMenu/AffiliateNavItem";

const subroutes = [
  {
    title: "Affiliate",
    titleComponent: <AffiliateNavItem />,
    href: "/earn/referral",
    icon: <CardGiftcardOutlinedIcon />,
    newUI: true,
    alernativeHrefs: [],
  },
  {
    title: "Sub-users",
    href: "/subuser",
    icon: <PeopleOutlineOutlinedIcon />,
    newUI: true,
  },
  {
    title: "API",
    icon: <ApiIcon />,

    items: [
      {
        title: "Docs",
        href: "https://apidocs.webshare.io/",
        newUI: true,
        external: true,
      },
      {
        title: "Keys",
        href: "/userapi/keys",
        alternativeHrefs: [],
        newUI: true,
      },
    ],
  },
];

export default subroutes;
