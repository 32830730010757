import { useRouter } from "next/router";
import { useMemo } from "react";
import { Button, Box, Link, Typography } from "@mui/material";
import Alert from "@/components/alerts/Alert";
import useResendVerification from "@/hooks/useResendVerification";
import CustomSnackbar from "../snakcbars/CustomSnackBar";
import { FlexBox } from "../common/styles";
import { useSession } from "@/security/RouteGuards";
import { FamousPlatformButtonType } from "../buttons/FamousPlatformButton";
import { RequireProperty, SessionType } from "@/types/index";
import VerifyEmailExceedingBandwidthModal from "../modals/VerifyEmailExceedingBandwidthModal";
import { DEFAULT_CUSTOMIZE_PATHNAME } from "@/utils/formHref";

const resolveOpenEmailClientDetails = (
  email: string
): [FamousPlatformButtonType?, string?] => {
  if (!email) return [undefined, undefined];

  const providers: {
    [key in FamousPlatformButtonType]?: { domains: string[]; link: string };
  } = {
    gmail: {
      domains: ["@gmail.com", "@googlemail.com"],
      link: `https://mail.google.com/mail/u/${email}/#search/from%3A(contact%40webshare.io)+in%3Aanywhere`,
    },
    yahoo: {
      domains: ["@yahoo.com", "@myyahoo.com"],
      link: "https://mail.yahoo.com/d/search/keyword=from%253Acontact%40webshare.io",
    },
    outlook: {
      domains: ["@outlook.com", "@hotmail.com", "@live.com", "@msn.com"],
      link: `https://outlook.live.com/mail/?login_hint=${encodeURIComponent(
        email
      )}`,
    },
    "apple-mail": {
      domains: ["@icloud.com", "@mac.com", "@me.com"],
      link: "https://www.icloud.com/mail/",
    },
  };

  for (let providerKey in providers) {
    const provider = providerKey as FamousPlatformButtonType;
    if (providers[provider]?.domains.some((domain) => email.includes(domain))) {
      return [provider, providers[provider]?.link || ""];
    }
  }

  return [undefined, undefined];
};

export default function VerifyEmailBanner() {
  const router = useRouter();

  const { profile, activationStatus } = useSession() as RequireProperty<
    SessionType,
    "profile"
  >;
  const [openEmailPlatform, openEmailLink] = useMemo(
    () => resolveOpenEmailClientDetails(profile.email),
    [profile]
  );

  const {
    errorMessage,
    setErrorMessage,
    isRefreshSuccess,
    setIsRefreshSuccess,
    resendActivationEmail,
  } = useResendVerification();

  if (!activationStatus) return null;
  if (activationStatus.email_is_verified) return null;

  if (router.pathname.includes(DEFAULT_CUSTOMIZE_PATHNAME)) return null;

  return (
    <>
      <CustomSnackbar
        isError
        snackbarOpen={!!errorMessage}
        setSnackbarOpen={() => {
          setErrorMessage(null);
        }}
        message={errorMessage ?? ""}
      />
      <CustomSnackbar
        snackbarOpen={isRefreshSuccess}
        setSnackbarOpen={() => {
          setIsRefreshSuccess(false);
        }}
        message="Verification email sent. Check your inbox."
      />

      <VerifyEmailExceedingBandwidthModal
        openEmailLink={openEmailLink}
        resendAction={resendActivationEmail}
      />

      <Box sx={{ px: 5, pt: 5, pb: 5 }}>
        <FlexBox maxWidth={"100%"}>
          <Alert
            sx={{
              width: "100%",
            }}
            action={
              openEmailPlatform ? (
                <FlexBox>
                  <Button
                    disableElevation
                    color="inherit"
                    size="small"
                    variant="contained"
                    sx={{ width: "110px" }}
                    data-testid="verifyBtn"
                    component="a"
                    href={openEmailLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Verify Email
                  </Button>
                </FlexBox>
              ) : undefined
            }
            severity="error"
          >
            <Box>
              <Typography
                component="div"
                fontWeight="500"
                variant="body1"
                marginBottom={2}
                data-testid="verifyEmailBannerTitleV2"
              >
                Check your inbox to verify your email
              </Typography>
              <Typography
                component="div"
                variant="body2"
                lineHeight={1}
                data-testid="verifyEmailBannerDescV2"
              >
                We have sent you an activation email. Please click the link
                within the email to ensure that your bandwidth usage is not
                limited. <br /> You don’t see an email? Please click{" "}
                <Link
                  underline="none"
                  color="inherit"
                  fontWeight={700}
                  sx={{ mt: "12px", cursor: "pointer" }}
                  display="inline-block"
                  onClick={resendActivationEmail}
                >
                  here
                </Link>{" "}
                to resend an email.
              </Typography>
            </Box>
          </Alert>
        </FlexBox>
      </Box>
    </>
  );
}
