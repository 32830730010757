import { createContext, useContext, useState } from "react";
import {
  getObjectFromStorage,
  setObjectToStorage,
} from "@/utils/localStorageUtils";
import { LocalStorageKeys } from "@/config/localStorageKeys";

type ConfigKeys = "federation.enabled";
type ConfigValueType = string | boolean | number;
type ConfigType = { [key in ConfigKeys]: ConfigValueType };
type ConfigContextType = {
  config: ConfigType;
  updateConfig: (key: ConfigKeys, value: ConfigValueType) => void;
};

const configInLocalStorage = getObjectFromStorage<ConfigType>(
  LocalStorageKeys.GlobalConfig
);
const initialValue = {
  config: configInLocalStorage ?? {
    "federation.enabled": false,
  },
  updateConfig: () => null,
};

const ConfigContext = createContext<ConfigContextType>(initialValue);

const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [config, setConfigs] = useState(initialValue.config);

  const updateConfig = (key: ConfigKeys, value: ConfigValueType) => {
    const newConfig = {
      ...config,
      [key]: value,
    };
    setConfigs(newConfig);
    setObjectToStorage(LocalStorageKeys.GlobalConfig, newConfig);
  };

  return (
    <ConfigContext.Provider
      value={{
        config,
        updateConfig,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

const useConfigContext = () => useContext(ConfigContext);

export { ConfigProvider, useConfigContext };
