import { setUser } from "@sentry/nextjs";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { useSession } from "../security/RouteGuards";
import { PostHogEvents, useEvents } from "@/tracking/useEvents";
import { DEFAULT_CUSTOMIZE_PATHNAME } from "@/utils/formHref";

export default function useTracking() {
  const posthog = usePostHog();
  const { captureEvent } = useEvents();
  const { profile } = useSession();
  const router = useRouter();

  useEffect(() => {
    if (!posthog) {
      return;
    }

    if (profile && profile.tracking_id) {
      posthog.identify(profile.tracking_id);
    }

    const trackOnBrowserReload = (event: Event) => {
      event.stopImmediatePropagation();
      const url = window.location.href;

      captureEvent(PostHogEvents.PageView, { $current_url: url });
    };

    const handleRouteChange = (url: string) => {
      const pathname = url.split("?")[0];
      const excludePaths = ["/login", "/register", DEFAULT_CUSTOMIZE_PATHNAME];

      if (!excludePaths.includes(pathname)) {
        captureEvent(PostHogEvents.PageView, { $current_url: url });
      }

      // posthog.reset() should be called from the logout page
      // anything that kills the user's session.
      posthog.startSessionRecording();
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    window.addEventListener("load", trackOnBrowserReload, {
      once: true,
      passive: true,
    });
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      window.removeEventListener("load", trackOnBrowserReload);
    };

    // let's only trigger a re-render when tracking_id value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.tracking_id, captureEvent]);

  useEffect(() => {
    if (profile && profile.email) {
      setUser({ email: profile.email });
    } else {
      setUser(null);
    }
  }, [profile]);
}
