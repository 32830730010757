import { IDVerificationType } from "@/types/IDVerificationType";

export const canRetryIdVerification = (
  verification: IDVerificationType | undefined
) => {
  if (!verification) return false;

  const { verification_failure_times, max_verification_failure_times } =
    verification;

  return Boolean(verification_failure_times < max_verification_failure_times);
};

export const shouldContactSupport = (
  verification: IDVerificationType | undefined
) => {
  if (!verification) return false;

  const { state } = verification;

  const shouldContactSupport =
    !["processing", "verified"].includes(state) &&
    !canRetryIdVerification(verification);

  return shouldContactSupport;
};
