import { useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  alpha,
  useTheme,
} from "@mui/material";
import { NextLink } from "@/components/common/styles";
import { RouteTypes, getParentIndexOfRoute } from "@/utils/routeUtils";
import { formFrontEndUrl } from "@/utils/formURL";
import routes from "../routes";
import secondaryroutes from "../secondaryroutes";
import DrawerCollapse from "./DrawerCollapse";
import DrawerNonCollapseWithItems from "./DrawerNonCollapseWithItems";

export default function NavMenu({
  autoCloseDrawer,
}: {
  autoCloseDrawer: () => void;
}) {
  const theme = useTheme();
  const router = useRouter();

  const getRouterIndex = useCallback(
    (routerVariant: RouteTypes) =>
      getParentIndexOfRoute(router.pathname, routerVariant),
    [router]
  );

  const collapsableRoutes = useMemo(
    () => routes.filter((m) => !m.alwaysVisible && m.items),
    []
  );

  const nonCollapsableRoutes = useMemo(
    () => routes.filter((m) => m.alwaysVisible || !m.items),
    []
  );

  const parentRouteIndex = getRouterIndex(collapsableRoutes);
  const secondaryRouteIndex = getRouterIndex(secondaryroutes);

  const listStyle = {
    mt: 0,
    pr: 3,
    pl: 3,
    color: "text.primary",
    "&& .Mui-selected, && .Mui-selected:hover": {
      bgcolor: alpha(theme.palette.primary.main, 0.08),
      borderRadius: "8px",
      "&, & .MuiListItemIcon-root": {
        color:
          theme.palette.mode === "light" ? theme.palette.primary.dark : "white",
      },
    },

    "& .MuiListItemButton-root": {
      borderRadius: "8px",
    },
    // hover states
    "& .MuiListItemButton-root:hover": {
      bgcolor: alpha(theme.palette.primary.main, 0.08),
      "&, & .MuiListItemIcon-root": {
        color:
          theme.palette.mode === "light" ? theme.palette.primary.dark : "white",
      },
    },
  };

  return (
    <>
      <List sx={listStyle}>
        {nonCollapsableRoutes.map(
          ({ title, href, icon, newUI, items }, index) =>
            items ? (
              <DrawerNonCollapseWithItems
                key={`DrawerNonCollapseWithItems_${index}`}
                title={title}
                items={items}
                index={index}
                autoCloseDrawer={autoCloseDrawer}
              />
            ) : (
              <ListItem
                key={"key-list-item-" + title}
                disablePadding
                selected={href === router.pathname}
              >
                <NextLink
                  href={newUI ? href : formFrontEndUrl(href)}
                  passHref
                  style={{ flex: 1 }}
                >
                  <ListItemButton
                    onClick={autoCloseDrawer}
                    data-testid={`NavLink_${title.replace(/ /g, "_")}`}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItemButton>
                </NextLink>
              </ListItem>
            )
        )}
        {collapsableRoutes.map(({ title, href, icon, newUI, items }, index) =>
          items ? (
            <DrawerCollapse
              isOpen={index === parentRouteIndex}
              key={"key-list-item-" + title}
              title={title}
              icon={icon}
              items={items}
              autoCloseDrawer={autoCloseDrawer}
            />
          ) : (
            <ListItem
              key={"key-list-item-" + title}
              disablePadding
              selected={href === router.pathname}
            >
              <NextLink
                href={newUI ? href : formFrontEndUrl(href)}
                passHref
                style={{ flex: 1 }}
              >
                <ListItemButton
                  onClick={autoCloseDrawer}
                  data-testid={`NavLink_${title.replace(/ /g, "_")}`}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              </NextLink>
            </ListItem>
          )
        )}
      </List>

      <List sx={listStyle}>
        {secondaryroutes.map(
          ({ title, titleComponent, href, icon, items, newUI }, index) =>
            items ? (
              <DrawerCollapse
                isOpen={index === secondaryRouteIndex}
                key={"key-list-item-" + title}
                title={title}
                titleComponent={titleComponent}
                icon={icon}
                items={items}
                autoCloseDrawer={autoCloseDrawer}
              />
            ) : (
              <ListItem
                key={"key-list-item-" + title}
                disablePadding
                selected={href === router.pathname}
              >
                <NextLink
                  href={newUI ? href : formFrontEndUrl(href)}
                  passHref
                  style={{ flex: 1 }}
                >
                  <ListItemButton
                    onClick={autoCloseDrawer}
                    data-testid={`NavLink_${title.replace(/ /g, "_")}`}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={titleComponent ?? title} />
                  </ListItemButton>
                </NextLink>
              </ListItem>
            )
        )}
      </List>
    </>
  );
}
