export function isObjKey<T extends object>(
  key: PropertyKey,
  obj: T
): key is keyof T {
  return key in obj;
}

export function isNumber(value?: string | number): boolean {
  return value != null && value !== "" && !isNaN(Number(value.toString()));
}
