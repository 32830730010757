import { PagiantedQuestionsType } from "@/api/verification";
import { fetcher } from "@/utils/fetcher";
import { formBackendURL } from "@/utils/formURL";
import useSWR from "swr";

export default function useVerificationQuestions({
  flowType,
  answer__isnull,
}: {
  flowType?: "acceptable_use_violation" | "fraudulent_payment";
  answer__isnull?: boolean;
}) {
  const queryString = new URLSearchParams();
  if (answer__isnull) {
    queryString.set("answer__isnull", answer__isnull.toString());
  }
  if (flowType) {
    queryString.set("flow__type", flowType);
  }
  return useSWR<PagiantedQuestionsType>(
    formBackendURL(`/verification/question/?${queryString.toString()}`),
    fetcher
  );
}
