import type { SessionType } from "../types/AuthTypes";
import { createContext, useContext } from "react";
import { SessionMutateContext } from "../context/SessionMutateContext";

const sessionDefaultValues: SessionType = {
  profile: undefined,
  isLoggedIn: false,
  subscription: undefined,
  plan: undefined,
  activationStatus: undefined,
  isLoading: false,
};

export const AuthContext = createContext<SessionType>(sessionDefaultValues);

export function useSession() {
  return useContext(AuthContext);
}

export function useSessionMutate() {
  return useContext(SessionMutateContext);
}
