import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import ListIcon from "@mui/icons-material/List";
import {
  DEFAULT_CHECKOUT_PATHNAME,
  DEFAULT_CUSTOMIZE_PATHNAME,
} from "@/utils/formHref";

const routes = [
  {
    title: "Dashboard",
    href: "/dashboard",
    icon: <DashboardOutlinedIcon />,
    newUI: true,
  },
  {
    title: "Proxy",
    icon: <ListAltIcon />,
    alwaysVisible: true,
    items: [
      {
        title: "Proxy List",
        href: "/proxy/list",
        icon: <ListIcon />,
        newUI: true,
        alternativeHrefs: ["/proxy/list/replaced", "/proxy/list/replace"],
      },
      {
        title: "Settings",
        href: "/proxy/settings",
        icon: <SettingsOutlinedIcon />,
        newUI: true,
        alternativeHrefs: ["/proxy/replace"],
      },
    ],
  },
  {
    title: "Statistics",
    icon: <AnalyticsOutlinedIcon />,
    alwaysVisible: true,

    items: [
      {
        title: "Bandwidth",
        href: "/stats/bandwidth",
        icon: <CloudOutlinedIcon />,
        newUI: true,
        alternativeHrefs: undefined,
      },
      {
        title: "Error",
        href: "/stats/error",
        icon: <ErrorOutlineOutlinedIcon />,
        newUI: true,
      },
      {
        title: "Activity",
        href: "/stats/activity",
        icon: <ShowChartOutlinedIcon />,
        newUI: true,
      },
    ],
  },
  {
    title: "Subscription",
    icon: <AccountCircleOutlinedIcon />,

    items: [
      {
        title: "My Plan",
        href: "/subscription",
        newUI: true,
        alternativeHrefs: ["/subscription/proxyreplacement"],
      },
      {
        title: "Browse Plans",
        href: DEFAULT_CUSTOMIZE_PATHNAME,
        newUI: true,
        alternativeHrefs: [
          DEFAULT_CHECKOUT_PATHNAME,
          DEFAULT_CUSTOMIZE_PATHNAME,
        ],
      },
      {
        title: "Invoices",
        href: "/subscription/invoices",
        newUI: true,
      },
    ],
  },
];

export default routes;
