import React from "react";
import { useSession } from "@/security/RouteGuards";
import { stringifyBase64 } from "@/hooks/useQueryParamState";
import useGetMenu from "./useGetMenu";

type RecommendBandwidth = {
  menu: string;
  recommend_bandwidth: number;
};

type RecommedProxyCount = {
  menu: string;
  recommend_proxyCount: number;
};

type UseRecommendedReturnType = {
  bandwidth: RecommendBandwidth;
  proxyCount: RecommedProxyCount;
};

export default function useRecommended(): UseRecommendedReturnType {
  const menu = useGetMenu();
  const { plan } = useSession();

  // memorize recommendation: tier above the current plan
  const bandwidth = React.useMemo((): RecommendBandwidth => {
    if (
      !plan ||
      (plan && plan.proxy_type === "free") ||
      !(menu && menu.userDefaultSubmenuItem)
    ) {
      const bandwidth = 250;
      return {
        menu: stringifyBase64({
          selectedBandwidth: bandwidth,
          selectedNumberOfProxies: plan?.proxy_count,
        }),
        recommend_bandwidth: bandwidth,
      };
    }

    const pickBandwidth = menu.userDefaultSubmenuItem?.bandwidthMenuItems
      .map((item) => item.bandwidth)
      .filter((item) => item > plan.bandwidth_limit);

    const bandwidth =
      pickBandwidth && pickBandwidth.length > 0
        ? Number(pickBandwidth.at(0))
        : 0;

    return {
      menu: stringifyBase64({
        selectedBandwidth: bandwidth,
        selectedNumberOfProxies: plan?.proxy_count,
      }),
      recommend_bandwidth: bandwidth,
    };
  }, [menu, plan]);

  // memoize recommendation: tier above the current plan
  const proxyCount = React.useMemo((): RecommedProxyCount => {
    if (
      !plan ||
      (plan && plan.proxy_type === "free") ||
      !(menu && menu.userDefaultSubmenuItem)
    ) {
      const numberOfProxies = 250;
      return {
        menu: stringifyBase64({
          selectedBandwidth: plan?.bandwidth_limit,
          selectedNumberOfProxies: numberOfProxies,
        }),
        recommend_proxyCount: numberOfProxies,
      };
    }

    const pickProxyCount = menu.userDefaultSubmenuItem?.numberOfProxiesMenuItems
      .map((item) => item.proxyCount)
      .filter((item) => item > plan.proxy_count);

    const selectedNumberOfProxies =
      pickProxyCount && pickProxyCount.length > 0
        ? Number(pickProxyCount.at(0))
        : plan.proxy_count;

    return {
      menu: stringifyBase64({
        selectedBandwidth: plan?.bandwidth_limit,
        selectedNumberOfProxies,
      }),
      recommend_proxyCount: selectedNumberOfProxies,
    };
  }, [menu, plan]);

  return {
    bandwidth,
    proxyCount,
  };
}
