import type { NotificationItemComponent } from "./NotificationItem";

import React from "react";
import Notification from "./NotificationItem";

const NotificationDefault: NotificationItemComponent = ({ item }) =>
  item && item.context && Object.keys(item.context).length > 0 ? (
    <Notification variant="default">
      <Notification.Title created_at={item.created_at}>
        <span>{item.context.title}</span>
      </Notification.Title>
      <Notification.Content>{item.context.description}</Notification.Content>
      <Notification.Actions {...item} />
    </Notification>
  ) : null;

export default NotificationDefault;
