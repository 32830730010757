import type { NotificationItemComponent } from "./NotificationItem";

import React from "react";
import { Typography, Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import NotificationItem from "./NotificationItem";
import Link from "next/link";

const ForbiddenHostErrorNotification: NotificationItemComponent = ({
  item,
}) => {
  return (
    <NotificationItem variant={!item.dismissed_at ? "info" : "default"}>
      <NotificationItem.Title created_at={item.created_at}>
        <span>You Are Accessing Blocked Website</span>
      </NotificationItem.Title>
      <NotificationItem.Content>
        <Typography variant="body2">
          You are experiencing a connection error because the website(s) you are
          trying to access is blocked. Please check the Activity Page on our
          Dashboard, and if you still have further questions, please do not
          hesitate to contact the customer success team. Thank you for using
          Webshare Proxy.
        </Typography>
      </NotificationItem.Content>
      <NotificationItem.Actions {...item}>
        <Button
          size="small"
          variant={!item.dismissed_at ? "contained" : "outlined"}
          color="primary"
          disableElevation
          endIcon={<OpenInNewIcon fontSize="small" />}
          href="/stats/activity"
          LinkComponent={Link}
        >
          <span>Go To Activity Page</span>
        </Button>
      </NotificationItem.Actions>
    </NotificationItem>
  );
};

export default ForbiddenHostErrorNotification;
