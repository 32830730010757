import React from "react";
import { useState } from "react";
import useSWR, { SWRConfig } from "swr";
import { NotificationsOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Popover,
  Tabs,
  Tab,
  Typography,
  Paper,
  Badge,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { fetcher } from "@/utils/fetcher";
import NotificationsList from "./NotificationList";
import { PaginatedNotificationType } from "@/types/NotificationTypes";
import { formBackendURL } from "@/utils/formURL";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const Notifications: React.FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [activeTab, setActiveTab] = React.useState<number>(0);

  const { data } = useSWR<PaginatedNotificationType>(
    formBackendURL(["/notification/?dismissed_at__isnull=True"].join("")),
    fetcher
  );

  const notificationsCount = data && data.results ? data.results.length : 0;

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleActiveTab = (event: React.SyntheticEvent, tab: number) => {
    event.stopPropagation();
    setActiveTab(tab);
  };

  return (
    <SWRConfig value={{ fetcher }}>
      <IconButton
        aria-describedby="notifications-menu"
        onClick={handleClick}
        data-testid="notificationsBtn"
        size={isDesktop ? "medium" : "small"}
      >
        <Typography color="neutral" lineHeight={0}>
          {notificationsCount > 0 ? (
            <Badge
              badgeContent={notificationsCount}
              color="primary"
              data-testid="new-notifications-badge"
            >
              <NotificationsOutlined
                color="inherit"
                fontSize={isDesktop ? "medium" : "small"}
              />
            </Badge>
          ) : (
            <NotificationsOutlined
              color="inherit"
              fontSize={isDesktop ? "medium" : "small"}
            />
          )}
        </Typography>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        data-testid="notifications-content"
        id="notifications-content"
        open={open}
        onClick={handleClose}
        PaperProps={{
          elevation: 2,
          sx: {
            minWidth: 320,
            maxWidth: 320,
            maxHeight: 496,
            overflowY: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 2,
            py: 0,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          component={Paper}
          elevation={1}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleActiveTab}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="Notifications"
          >
            <Tab label="Inbox" value={0} data-testid="inbox" />
            <Tab label="Archived" value={1} data-testid="archived" />
          </Tabs>
        </Box>
        <TabPanel value={activeTab} index={0}>
          <NotificationsList archived={false}>
            <Typography component="div" variant="body2">
              <span>You&apos;re all caught up! 🙌</span>
            </Typography>
          </NotificationsList>
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <NotificationsList archived={true}>
            <Typography variant="body2" color="text.secondary">
              There are no archived updates.
            </Typography>
          </NotificationsList>
        </TabPanel>
      </Popover>
    </SWRConfig>
  );
};

export default Notifications;
