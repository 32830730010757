import { useRouter } from "next/router";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton, Typography, useTheme } from "@mui/material";
import { NextLink } from "@/components/common/styles";
import { formFrontEndUrl } from "@/utils/formURL";
import CircleIcon from "@mui/icons-material/Circle";
import { NavItemType, NavItemVariants } from "./types";
import checkIfItemSelected from "./utils/checkIfItemSelected";

export default function NavItem({
  title,
  href,
  newUI,
  alternativeHrefs,
  autoCloseDrawer,
  icon,
  variant = "list-item",
  external,
}: NavItemType & { autoCloseDrawer: () => void; variant?: NavItemVariants }) {
  const router = useRouter();
  const theme = useTheme();
  const selected = checkIfItemSelected(router, { href, alternativeHrefs });

  return (
    <ListItem
      key={"key-list-sub-item-" + title}
      disablePadding
      selected={variant === "button" && selected}
    >
      <NextLink
        href={newUI ? href : formFrontEndUrl(href)}
        passHref
        style={{ flex: 1 }}
        target={external ? "_blank" : "_self"}
      >
        {variant === "button" ? (
          <ListItemButton onClick={autoCloseDrawer}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText
              color="text.primary"
              primary={title}
              sx={{ pl: icon ? 0 : 6 }}
            />
          </ListItemButton>
        ) : (
          <ListItemButton onClick={autoCloseDrawer}>
            <ListItemIcon sx={{ minWidth: "36px" }}>
              <CircleIcon
                sx={{ width: "6px", height: "6px", ml: 4 }}
                style={{
                  color: selected
                    ? theme.palette.primary.main
                    : theme.palette.grey[300],
                }}
              />
            </ListItemIcon>
            <ListItemText
              color="text.primary"
              disableTypography
              primary={
                <Typography
                  fontSize="16px"
                  variant="body2"
                  color="text.primary"
                  sx={{ fontWeight: selected ? 600 : 400 }}
                >
                  {title}
                </Typography>
              }
            />
          </ListItemButton>
        )}
      </NextLink>
    </ListItem>
  );
}
