import type { NotificationItemComponent } from "./NotificationItem";
import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Typography, Button } from "@mui/material";
import NotificationItem from "./NotificationItem";
import { useSession } from "@/security/RouteGuards";
import { useRouter } from "next/router";
import useRecommended from "@/components/subscription/customize/hooks/useRecommended";
import { formCustomizePageHref } from "@/utils/formHref";

const HundredPercentBandwidthUsedNotification: NotificationItemComponent = ({
  item,
}) => {
  const router = useRouter();
  const { bandwidth } = useRecommended();
  const { plan, subscription } = useSession();

  const computedMessage = React.useMemo((): string => {
    if (!plan && !subscription)
      return "You have reached the proxy limit in your current plan.";

    const term = subscription?.term === "monthly" ? "month" : "year";
    const bandwidth = plan?.bandwidth_limit ?? 100;

    return [
      "You have reached the proxy limit of",
      `${bandwidth}GB/${term}`,
      "in your plan. ",
    ].join(" ");
  }, [plan, subscription]);

  const handleUpgrade = React.useCallback(() => {
    router.replace(
      formCustomizePageHref({
        source: "notification",
        showCustomizeSections: ["bandwidth-plan", "plan-terms"],
        ...bandwidth,
      })
    );
  }, [bandwidth, router]);

  return (
    <NotificationItem variant={!item.dismissed_at ? "error" : "default"}>
      <NotificationItem.Title created_at={item.created_at}>
        <span>Your proxy activity is suspended</span>
      </NotificationItem.Title>
      <NotificationItem.Content>
        <Typography variant="body2">
          <span>{computedMessage}</span>
          <span>
            Your proxy activity is currently suspended. Upgrade to get more
            bandwidth.
          </span>
        </Typography>
      </NotificationItem.Content>
      <NotificationItem.Actions {...item}>
        <Button
          size="small"
          variant={!item.dismissed_at ? "contained" : "outlined"}
          color={!item.dismissed_at ? "error" : "primary"}
          disableElevation
          onClick={handleUpgrade}
          endIcon={<OpenInNewIcon fontSize="small" />}
        >
          <span>Upgrade</span>
        </Button>
      </NotificationItem.Actions>
    </NotificationItem>
  );
};

export default HundredPercentBandwidthUsedNotification;
