import useSWR from "swr";
import { formBackendUrlString } from "@/utils/formURL";
import { fetcher } from "@/utils/fetcher";
import { getToken } from "@/security/authentication";

import { ProfileType } from "../types";

export default function useProfile() {
  const sessionToken = getToken();

  const {
    data: profile,
    mutate: mutateProfile,
    error: profileError,
    isLoading: isProfileLoading,
  } = useSWR<ProfileType>(
    () =>
      sessionToken ? [formBackendUrlString(`/profile/`), sessionToken] : null,
    fetcher
  );

  return {
    mutateProfile,
    profile,
    profileError,
    isProfileLoading,
  };
}
