import simplur from "simplur";
import {
  AutomaticRefreshFrequencyMenuItem,
  OnDemandRefreshesMenuItem,
  ProxyPlanCategoryUIType,
  ProxyReplacementsMenuItem,
  SubUserMenuItem,
} from "./CustomizeMenuTypes";

export const automaticRefreshFrequencyMenuItems: AutomaticRefreshFrequencyMenuItem[] =
  [
    {
      period: 0,
      name: "No Refreshes",
      isDefault: true,
      description: "Proxies remain the same",
    },
    {
      period: 2592000,
      name: "Every Month",
      description: "Replace all proxies",
      isPopular: true,
    },
    {
      period: 604800,
      name: "Every Week",
      description: "Replace all proxies",
    },
    { period: 86400, name: "Every Day", description: "Replace all proxies" },
    {
      period: 14400,
      name: "Every 4 hours",
      description: "Replace all proxies",
    },
    {
      period: 3600,
      name: "Every 1 hour",
      description: "Replace all proxies",
    },
    {
      period: 900,
      name: "Every 15 minutes",
      description: "Replace all proxies",
    },
    {
      period: 300,
      name: "Every 5 minutes",
      description: "Replace all proxies",
    },
  ];

export const rotatingISPAutomaticRefreshFrequencyMenuItems: AutomaticRefreshFrequencyMenuItem[] =
  [
    {
      period: 1200,
      name: "Up to 5,000 IPs/month",
      description: "Regular",
      isPopular: true,
      isDefault: true,
    },
    {
      period: 300,
      name: "Up to 20,000 IPs/month",
      description: "Large",
    },
  ];

export const rotatingAutomaticRefreshFrequencyMenuItems: AutomaticRefreshFrequencyMenuItem[] =
  [
    {
      period: 300,
      name: "Up to 50,000 IPs/month",
      description: "Regular",
      isPopular: true,
      isDefault: true,
    },
    {
      period: 60,
      name: "Up to 200,000 IPs/month",
      description: "Large",
    },
  ];

export const onDemandRefreshesMenuItems: OnDemandRefreshesMenuItem[] = [
  {
    onDemandRefreshesCount: 0,
    name: "No Refreshes",
    isDefault: true,
    description: "Proxies remain the same",
  },
  {
    onDemandRefreshesCount: 1,
    name: "1 Refresh",
    description: "Replace all proxies",
    isPopular: true,
  },
  {
    onDemandRefreshesCount: 2,
    name: "2 Refreshes",
    description: "Replace all proxies",
  },
  {
    onDemandRefreshesCount: 3,
    name: "3 Refreshes",
    description: "Replace all proxies",
  },
  {
    onDemandRefreshesCount: 5,
    name: "5 Refreshes",
    description: "Replace all proxies",
  },
  {
    onDemandRefreshesCount: 10,
    name: "10 Refreshes",
    description: "Replace all proxies",
  },
  {
    onDemandRefreshesCount: 25,
    name: "25 Refreshes",
    description: "Replace all proxies",
  },
  {
    onDemandRefreshesCount: 50,
    name: "50 Refreshes",
    description: "Replace all proxies",
  },
];

export const proxyReplacementsMenuItems: ProxyReplacementsMenuItem[] = [
  {
    proxyReplacementsCount: 10,
    name: "10 Replacements",
    isDefault: true,
    description: "Replace individual proxies",
  },
  {
    proxyReplacementsCount: 50,
    name: "50 Replacements",
    description: "Replace individual proxies",
    isPopular: true,
  },
  {
    proxyReplacementsCount: 100,
    name: "100 Replacements",
    description: "Replace individual proxies",
  },
  {
    proxyReplacementsCount: 250,
    name: "250 Replacements",
    description: "Replace individual proxies",
  },
  {
    proxyReplacementsCount: 500,
    name: "500 Replacements",
    description: "Replace individual proxies",
  },
  {
    proxyReplacementsCount: 1000,
    name: "1,000 Replacements",
    description: "Replace individual proxies",
  },
  {
    proxyReplacementsCount: 2500,
    name: "2,500 Replacements",
    description: "Replace individual proxies",
  },
  {
    proxyReplacementsCount: 5000,
    name: "5,000 Replacements",
    description: "Replace individual proxies",
  },
];

export const subUserMenuItems: SubUserMenuItem[] = [
  {
    subUserCount: 3,
    name: "3 Sub-users",
    isDefault: true,
    description: "Included for free",
  },
  {
    subUserCount: 10,
    name: "10 Sub-users",
    description: "-",
  },
  {
    subUserCount: 50,
    name: "50 Sub-users",
    description: "-",
  },
];

function hideSingular(qty: number) {
  return qty == 1 ? null : qty.toLocaleString();
}

export const rootMenu = (): ProxyPlanCategoryUIType[] => {
  return [
    {
      name: "Free Proxy",
      menuType: "free",
      chips: [],
      description: "10 high performance free proxies.",
      price: 0,
      subType: [
        {
          type: "free",
          subType: "default",
          name: "Free Proxy",
          simplurDisplayName: (count: number) =>
            simplur`${[count, hideSingular]} Free Prox[y|ies]`,
          minCountForUnlimitedBandwidth: 0,
          bandwidthMenuItems: [{ bandwidth: 1, name: "Free", isDefault: true }],
          chips: [],
          description: "",
          showFreeFormatNumberOfProxies: 0,
          numberOfProxiesMenuItems: [{ proxyCount: 10, isDefault: true }],
          automaticRefreshFrequencyMenuItems: [
            {
              period: 0,
              name: "No Refreshes",
              isDefault: true,
              description: "Proxies remain the same",
            },
          ],
          onDemandRefreshesMenuItems: [
            {
              onDemandRefreshesCount: 0,
              name: "No Refreshes",
              isDefault: true,
              description: "Proxies remain the same",
            },
          ],
          subUserMenuItems: [
            {
              subUserCount: 0,
              name: "No Sub-users",
              isDefault: true,
              description: "Included for free",
            },
          ],
          proxyReplacementsMenuItems: [
            {
              proxyReplacementsCount: 0,
              name: "No Replacements",
              isDefault: true,
              description: "Replace individual proxies",
            },
          ],
        },
      ],
    },
    {
      name: "Datacenter",
      menuType: "shared",
      chips: [],
      description: "",
      price: 2.99,
      subType: [
        {
          type: "shared",
          subType: "default",
          name: "Shared",
          simplurDisplayName: (count: number) =>
            simplur`${[count, hideSingular]} Proxy Server[|s]`,
          minCountForUnlimitedBandwidth: 1000,
          chips: [],
          description: "Shared with more than 2 users",
          showFreeFormatNumberOfProxies: 1000,
          automaticRefreshFrequencyMenuItems,
          onDemandRefreshesMenuItems,
          proxyReplacementsMenuItems,
          numberOfProxiesMenuItems: [
            { proxyCount: 100 },
            { proxyCount: 250 },
            { proxyCount: 500 },
            { proxyCount: 1000, isDefault: true },
            { proxyCount: 2500 },
            { proxyCount: 5000, isPopular: true },
            { proxyCount: 10000 },
            { proxyCount: 15000 },
            { proxyCount: 25000 },
            { proxyCount: 40000 },
            { proxyCount: 60000 },
            { proxyCount: 100000 },
          ],
          bandwidthMenuItems: [
            { bandwidth: 250, name: "Saver" },
            { bandwidth: 1000, name: "Plus" },
            {
              bandwidth: 5000,
              name: "Premium",
              isDefault: true,
              isPopular: true,
            },
            { bandwidth: 0, name: "Unlimited" },
          ],
          subUserMenuItems,
        },
        {
          type: "semidedicated",
          subType: "premium",
          name: "Private",
          simplurDisplayName: (count: number) =>
            simplur`${[count, hideSingular]} Private Proxy Server[|s]`,
          minCountForUnlimitedBandwidth: 100,
          chips: [],
          description: "Shared with 1-2 users",
          showFreeFormatNumberOfProxies: 100,
          automaticRefreshFrequencyMenuItems,
          onDemandRefreshesMenuItems,
          proxyReplacementsMenuItems,
          numberOfProxiesMenuItems: [
            { proxyCount: 25 },
            { proxyCount: 50, isDefault: true },
            { proxyCount: 100 },
            { proxyCount: 250 },
            { proxyCount: 500 },
            { proxyCount: 1000 },
            { proxyCount: 2000 },
            { proxyCount: 3000 },
            { proxyCount: 5000 },
            { proxyCount: 10000 },
            { proxyCount: 25000 },
          ],
          bandwidthMenuItems: [
            { bandwidth: 250, name: "Saver" },
            { bandwidth: 1000, name: "Plus" },
            {
              bandwidth: 5000,
              name: "Premium",
              isDefault: true,
              isPopular: true,
            },
            { bandwidth: 0, name: "Unlimited" },
          ],
          subUserMenuItems,
        },
        {
          type: "dedicated",
          subType: "premium",
          name: "Dedicated",
          simplurDisplayName: (count: number) =>
            simplur`${[count, hideSingular]} Dedicated Proxy Server[|s]`,
          minCountForUnlimitedBandwidth: 75,
          chips: [],
          description: "Fully owned by you",
          showFreeFormatNumberOfProxies: 50,
          automaticRefreshFrequencyMenuItems,
          onDemandRefreshesMenuItems,
          proxyReplacementsMenuItems,
          numberOfProxiesMenuItems: [
            { proxyCount: 20 },
            { proxyCount: 50 },
            { proxyCount: 75, isPopular: true, isDefault: true },
            { proxyCount: 100 },
            { proxyCount: 250 },
            //Discount unkown for items below.
            { proxyCount: 500 },
            { proxyCount: 1000 },
            { proxyCount: 2000 },
            { proxyCount: 3000 },
            { proxyCount: 5000 },
            { proxyCount: 10000 },
          ],
          bandwidthMenuItems: [
            { bandwidth: 250, name: "Saver" },
            { bandwidth: 1000, name: "Plus" },
            {
              bandwidth: 5000,
              name: "Premium",
              isDefault: true,
              isPopular: true,
            },
            { bandwidth: 0, name: "Unlimited" },
          ],
          subUserMenuItems,
        },
      ],
    },
    {
      name: "Static Residential",
      menuType: "dedicated",
      chips: [],
      description: "",
      price: 6,
      subType: [
        {
          type: "shared",
          subType: "isp",
          name: "Shared",
          simplurDisplayName: (count: number) =>
            simplur`${[count, hideSingular]} Static Residential Prox[y|ies]`,

          minCountForUnlimitedBandwidth: 100,
          chips: [],
          bandwidthMenuItems: [
            { bandwidth: 250, name: "Saver" },
            { bandwidth: 1000, name: "Plus" },
            {
              bandwidth: 5000,
              name: "Premium",
              isDefault: true,
              isPopular: true,
            },
            { bandwidth: 0, name: "Unlimited" },
          ],
          showFreeFormatNumberOfProxies: 100,
          automaticRefreshFrequencyMenuItems,
          onDemandRefreshesMenuItems,
          proxyReplacementsMenuItems,
          numberOfProxiesMenuItems: [
            { proxyCount: 20 },
            { proxyCount: 50 },
            { proxyCount: 75 },
            { proxyCount: 100, isDefault: true },
            { proxyCount: 250 },
            { proxyCount: 500 },
            { proxyCount: 1000, isPopular: true },
            { proxyCount: 2000 },
            { proxyCount: 3000 },
            { proxyCount: 5000 },
            { proxyCount: 10000 },
          ],
          subUserMenuItems,
          description: "Shared with more than 2 users",
        },
        {
          type: "semidedicated",
          subType: "isp",
          name: "Private",
          simplurDisplayName: (count: number) =>
            simplur`${[
              count,
              hideSingular,
            ]} Private Static Residential Prox[y|ies]`,
          minCountForUnlimitedBandwidth: 100,
          chips: [],
          showFreeFormatNumberOfProxies: 100,
          automaticRefreshFrequencyMenuItems,
          onDemandRefreshesMenuItems,
          proxyReplacementsMenuItems,
          numberOfProxiesMenuItems: [
            { proxyCount: 25 },
            { proxyCount: 50, isDefault: true },
            { proxyCount: 100 },
            { proxyCount: 250 },
            { proxyCount: 500 },
            { proxyCount: 1000 },
            { proxyCount: 2000 },
            { proxyCount: 3000 },
            { proxyCount: 5000 },
            { proxyCount: 10000 },
          ],
          bandwidthMenuItems: [
            { bandwidth: 250, name: "Saver" },
            { bandwidth: 1000, name: "Plus" },
            {
              bandwidth: 5000,
              name: "Premium",
              isDefault: true,
              isPopular: true,
            },
            { bandwidth: 0, name: "Unlimited" },
          ],
          subUserMenuItems,
          description: "Shared with 1-2 users",
        },
        {
          type: "dedicated",
          subType: "isp",
          name: "Dedicated",
          simplurDisplayName: (count: number) =>
            simplur`${[
              count,
              hideSingular,
            ]} Dedicated Static Residential Prox[y|ies]`,
          minCountForUnlimitedBandwidth: 50,
          chips: [],
          showFreeFormatNumberOfProxies: 50,
          automaticRefreshFrequencyMenuItems,
          onDemandRefreshesMenuItems,
          proxyReplacementsMenuItems,
          numberOfProxiesMenuItems: [
            { proxyCount: 20 },
            { proxyCount: 50 },
            { proxyCount: 75, isPopular: true, isDefault: true },
            { proxyCount: 100 },
            { proxyCount: 250 },
            //Discount unkown for items below.
            { proxyCount: 500 },
            { proxyCount: 1000 },
            { proxyCount: 2000 },
            { proxyCount: 3000 },
            { proxyCount: 5000 },
            { proxyCount: 10000 },
          ],
          bandwidthMenuItems: [
            { bandwidth: 250, name: "Saver" },
            { bandwidth: 1000, name: "Plus" },
            {
              bandwidth: 5000,
              name: "Premium",
              isDefault: true,
              isPopular: true,
            },
            { bandwidth: 0, name: "Unlimited" },
          ],
          subUserMenuItems,

          description: "Fully owned by you",
        },
      ],
    },
    {
      name: "Residential",
      menuType: "residential",
      chips: [],
      description: "",
      price: 7,
      subType: [
        {
          type: "shared",
          subType: "residential",
          name: "Residential",
          simplurDisplayName: (count: number) =>
            simplur`${[count, hideSingular]} Residential Prox[y|ies]`,
          minCountForUnlimitedBandwidth: 0,
          chips: [],
          showBandwidthAnnouncement: {
            title: "Pay As You Go",
            description: "Coming Soon",
          },
          showFreeFormatNumberOfProxies: 0,
          subUserMenuItems,
          bandwidthMenuItems: [
            {
              bandwidth: 1,
              name: "Testing Out",
              isDefault: true,
              isPopular: true,
            },
            { bandwidth: 3, name: "Micro" },
            { bandwidth: 10, name: "Mini" },
            {
              bandwidth: 25,
              name: "Starter",
            },
            { bandwidth: 50, name: "Regular" },
            { bandwidth: 100, name: "Advanced" },
            { bandwidth: 250, name: "Premium" },
            { bandwidth: 500, name: "Professional" },
            {
              bandwidth: 1000,
              name: "Enterprise",
            },
            { bandwidth: 3000, name: "Enterprise" },
          ],
          automaticRefreshFrequencyMenuItems: [
            {
              period: 0,
              name: "Default",
              isDefault: true,
              description: "Proxies will be replaced every 5h on average", // This is not displayed for residential.
            },
          ],
          numberOfProxiesMenuItems: [
            {
              proxyCount: 30000000,
              isDefault: true,
              title: "Up to 30 million IPs/month",
              description: "Standard", //TODO: Make sure description is no longer used and remove it.
            },
          ],
          description:
            "Many IPs of real residential devices / Highly diverse / Best for smaller bandwidth usage ",
        },
      ],
    },
    {
      name: "Verified",
      menuType: "verified",
      chips: [],
      description: "",
      price: 7,
      subType: [
        {
          type: "shared",
          subType: "verified",
          name: "Google Search (Datacenter Shared)",
          simplurDisplayName: (count: number) =>
            simplur`${[count, hideSingular]} Proxy Server[|s]`,
          minCountForUnlimitedBandwidth: 1000,
          chips: [],
          description: "",
          showFreeFormatNumberOfProxies: 1000,
          automaticRefreshFrequencyMenuItems,
          onDemandRefreshesMenuItems,
          proxyReplacementsMenuItems,
          numberOfProxiesMenuItems: [
            { proxyCount: 100 },
            { proxyCount: 250 },
            { proxyCount: 500 },
            { proxyCount: 1000, isDefault: true },
            { proxyCount: 2500 },
            { proxyCount: 5000, isPopular: true },
            { proxyCount: 10000 },
            { proxyCount: 15000 },
            { proxyCount: 25000 },
            { proxyCount: 40000 },
            { proxyCount: 60000 },
            { proxyCount: 100000 },
          ],
          bandwidthMenuItems: [
            { bandwidth: 250, name: "Saver" },
            { bandwidth: 1000, name: "Plus" },
            {
              bandwidth: 5000,
              name: "Premium",
              isDefault: true,
              isPopular: true,
            },
            { bandwidth: 0, name: "Unlimited" },
          ],
          subUserMenuItems,
        },
      ],
    },
  ];
};
