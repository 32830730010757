import {
  AutomaticRefreshFrequencyMenuItem,
  PlanCategorySubMenuType,
  SyntheticPlanCategorySubType,
} from "@/components/subscription/menu/CustomizeMenuTypes";
import {
  rotatingAutomaticRefreshFrequencyMenuItems,
  rotatingISPAutomaticRefreshFrequencyMenuItems,
} from "@/components/subscription/menu/RootMenu";
import {
  PlanCategorySubType,
  PlanCategoryType,
  PlanType,
  _Plan_MainPropsType,
  _Plan_RefreshesPropsType,
} from "@/types/PlanTypes";

export function getSelectedBackendPlanSubCategory({
  selectedPlanSubCategory,
  selectedPlanCategory,
}: {
  selectedPlanSubCategory: PlanCategorySubMenuType;
  selectedPlanCategory?: PlanCategoryType;
}): PlanCategorySubType {
  if (selectedPlanSubCategory === "verified") {
    if (selectedPlanCategory === "shared") {
      return "default";
    }
    if (
      selectedPlanCategory === "semidedicated" ||
      selectedPlanCategory === "dedicated"
    ) {
      return "premium";
    }
  }

  if (selectedPlanSubCategory === "verifiedIsp") {
    return "isp";
  }

  return selectedPlanSubCategory;
}

export function isInRotatingPeriod(
  automaticRefreshFrequencyMenuItems: AutomaticRefreshFrequencyMenuItem[],
  automaticRefreshFrequency: number
): boolean {
  return (
    automaticRefreshFrequencyMenuItems.findIndex(
      (rotatingAutomaticRefreshFrequencyMenuItem) => {
        return (
          rotatingAutomaticRefreshFrequencyMenuItem.period ===
          automaticRefreshFrequency
        );
      }
    ) > -1
  );
}

export function isPlanRotating(
  plan: PlanType | (_Plan_MainPropsType & _Plan_RefreshesPropsType)
): SyntheticPlanCategorySubType | null {
  if (!plan.proxy_subtype) {
    //There are some old users who don't have proxy subtypes.
    return null;
  } else {
    // This means this proxy is rotating see issue #919
    if (
      plan.proxy_type === "shared" &&
      plan.proxy_subtype === "default" &&
      plan.proxy_count === 10 &&
      //Options specified in Root Menu
      isInRotatingPeriod(
        rotatingAutomaticRefreshFrequencyMenuItems,
        plan.automatic_refresh_frequency
      ) &&
      plan.proxy_replacements_total === 0 &&
      plan.on_demand_refreshes_total === 0
    ) {
      return "rotating";
    }
    // This means this proxy is rotating see issue #919
    if (
      plan.proxy_type === "shared" &&
      plan.proxy_subtype === "isp" &&
      plan.proxy_count === 5 &&
      //Options specified in Root Menu
      isInRotatingPeriod(
        rotatingISPAutomaticRefreshFrequencyMenuItems,
        plan.automatic_refresh_frequency
      ) &&
      plan.proxy_replacements_total === 0 &&
      plan.on_demand_refreshes_total === 0
    ) {
      return "rotatingIsp";
    }
    return null;
  }
}

export function isPlanVerified(
  plan: PlanType
): SyntheticPlanCategorySubType | null {
  if (!plan) return null;
  if (!plan.required_site_checks?.length) return null;
  if (plan.proxy_subtype === "isp") return "verifiedIsp";
  return "verified";
}

export function isPlanResidential(
  plan: PlanType | (_Plan_MainPropsType & _Plan_RefreshesPropsType)
) {
  if (!plan.proxy_subtype) {
    //There are some old users who don't have proxy subtypes.
    return false;
  } else {
    if (plan.proxy_type === "shared" && plan.proxy_subtype === "residential") {
      return true;
    }
    return false;
  }
}

export function isRotatingProxyMenu(
  selectedPlanSubCategory: PlanCategorySubMenuType
): boolean {
  return ["rotating", "rotatingIsp"].includes(selectedPlanSubCategory);
}
