import {
  PlanCategoryType,
  PlanType,
  _Plan_MainPropsType,
  _Plan_RefreshesPropsType,
} from "@/types/PlanTypes";
import { isPlanRotating } from "./customize/checkout/utils/rotatingPlanUtils";

import {
  PlanCategorySubMenuType,
  ProxyPlanCategorySubUIType,
  ProxyPlanCategoryUIType,
  SyntheticPlanCategorySubType,
} from "./menu/CustomizeMenuTypes";

export function findUserPlanMainMenuItem(
  menu: ProxyPlanCategoryUIType[],
  plan: PlanType | (_Plan_MainPropsType & _Plan_RefreshesPropsType)
) {
  return menu.find((mainMenuItem) =>
    mainMenuItem.subType.find((subMenuItem) => {
      return (
        subMenuItem.type === plan.proxy_type &&
        subMenuItem.subType === plan.proxy_subtype
      );
    })
  ) as ProxyPlanCategoryUIType;
}

export function findSelectedMainMenuItem(
  menu: ProxyPlanCategoryUIType[],
  selectedPlanCategory: PlanCategoryType,
  selectedPlanSubCategory: PlanCategorySubMenuType,
  handleTypeSelect: (
    selectedSubMenuCategory: ProxyPlanCategorySubUIType
  ) => void
) {
  const mainMenuItem = menu.find((mainMenuItem) =>
    mainMenuItem.subType.find((subMenuItem) => {
      return (
        subMenuItem.type === selectedPlanCategory &&
        subMenuItem.subType === selectedPlanSubCategory
      );
    })
  );
  //There are some cases where due to experiments such as customize_page_hide_static_residential which causes a main menu item no longer exists. In such cases assign a default.
  if (!mainMenuItem) {
    const tempMenuItem = menu[1].subType[0];
    handleTypeSelect(tempMenuItem);
    return menu[1] as ProxyPlanCategoryUIType;
  }
  return mainMenuItem as ProxyPlanCategoryUIType;
}

export function findUserPlanSubMenuItem(
  menu: ProxyPlanCategoryUIType[],
  plan: PlanType | (_Plan_MainPropsType & _Plan_RefreshesPropsType),
  isPlanRotating: SyntheticPlanCategorySubType | null
) {
  const mainMenuItem = findUserPlanMainMenuItem(menu, plan);

  if (mainMenuItem) {
    return mainMenuItem.subType.find((subMenuItem) => {
      if (isPlanRotating) {
        return (
          subMenuItem.type === plan.proxy_type &&
          subMenuItem.subType === isPlanRotating
        );
      }
      return (
        subMenuItem.type === plan.proxy_type &&
        subMenuItem.subType === plan.proxy_subtype
      );
    });
  }

  return null;
}

export function isUsersCurrentPlan(
  plan: PlanType,
  selectedSubMenuCategory: ProxyPlanCategorySubUIType
) {
  const isRotatingPlan = isPlanRotating(plan);
  if (isRotatingPlan) {
    return (
      selectedSubMenuCategory.type === plan.proxy_type &&
      selectedSubMenuCategory.subType === isRotatingPlan
    );
  }
  return (
    selectedSubMenuCategory.type === plan.proxy_type &&
    selectedSubMenuCategory.subType === plan.proxy_subtype
  );
}

export function findSelectedSubMenuItem(
  selectedMainMenuItem: ProxyPlanCategoryUIType,
  selectedPlanCategory: PlanCategoryType,
  selectedPlanSubCategory: PlanCategorySubMenuType
) {
  return selectedMainMenuItem.subType.find((subMenuItem) => {
    return (
      subMenuItem.type === selectedPlanCategory &&
      subMenuItem.subType === selectedPlanSubCategory
    );
  }) as ProxyPlanCategorySubUIType;
}

export function fixPlanValues({
  sessionPlan,
}: {
  sessionPlan: PlanType | undefined;
}) {
  const plan = { ...sessionPlan } as PlanType;
  if (plan.proxy_type === "dedicated" || plan.proxy_type === "semidedicated") {
    if (plan.proxy_subtype === "default") {
      plan.proxy_subtype = "premium";
    }
  }
  // In case of new top level types, there is no standard proxy type, we need to switch users to premium mode
  if (plan.proxy_type === "dedicated" || plan.proxy_type === "semidedicated") {
    if (plan.proxy_subtype === "non-premium") {
      plan.proxy_subtype = "premium";
    }
  }

  return plan;
}
