import { useSessionMutate } from "@/security/RouteGuards";
import {
  PendingPaymentStatus,
  PendingPaymentType,
} from "@/types/PaymentMethodTypes";
import { PlanType } from "@/types/PlanTypes";
import { TransactionType } from "@/types/TransactionTypes";
import { fetcher } from "@/utils/fetcher";
import { formBackendURL } from "@/utils/formURL";
import { captureException } from "@sentry/nextjs";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import useSWR, { KeyedMutator } from "swr";

export type PaymentPollingContextType = {
  startPolling: (paymentId: number) => void;
  transactionData: TransactionType | undefined;
  planResponse: PlanType | undefined;
  paymentData: PendingPaymentType | undefined;
  mutatePayment: KeyedMutator<PendingPaymentType>;
  mutateTransaction: KeyedMutator<TransactionType>;
  paymentId: number | null;
  status: PendingPaymentStatus | undefined;
};

const PaymentPollingContext = React.createContext<
  PaymentPollingContextType | undefined
>(undefined);

/**
 * This hooks starts polling for payment status when mounted.
 *
 * The polling itself happens in a provider, so that even if user navigates away from the page,
 * the polling continues.
 */
export const usePaymentPollingContext = () => {
  const context = React.useContext(PaymentPollingContext);

  const { query } = useRouter();

  const { paymentid } = query;
  const paymentIdString = (paymentid as string) || "";

  const paymentIdInt = parseInt(paymentIdString);

  if (!context) {
    throw new Error(
      "usePaymentPollingContext must be used within a PaymentPollingProvider"
    );
  }

  useEffect(() => {
    context.startPolling(paymentIdInt);
  }, [context, paymentIdInt]);

  return context;
};

type PaymentPollingProviderProps = {
  children: React.ReactNode;
};

const SECONDS_8 = 8000;
const MINUTES_5 = 60 * 5 * 1000;

/**
 * After payment payment is initiated we start polling for payment status in a *provider*,
 * so that even if user navigates away from the page, the polling continues and plan is updated.
 *
 * It times out after 5 minutes. It's used as part of the checkout flow.
 */
export const PaymentPollingProvider = ({
  children,
}: PaymentPollingProviderProps) => {
  const pollingId = React.useRef(-1);
  const timeoutPollingId = React.useRef(-1);
  const [paymentId, setPaymentId] = React.useState<number | null>(null);
  const { mutateAll } = useSessionMutate();

  const { data: paymentData, mutate: mutatePayment } =
    useSWR<PendingPaymentType>(
      paymentId ? formBackendURL(`/payment/pending/${paymentId}/`) : null,
      fetcher
    );

  const {
    transaction: transactionId,
    status,
    plan: planID,
  } = paymentData ?? {};

  const { data: planResponse } = useSWR<PlanType>(
    planID ? formBackendURL(`/subscription/plan/${planID}/`) : null,
    fetcher
  );

  const { data: transactionData, mutate: mutateTransaction } =
    useSWR<TransactionType>(
      transactionId
        ? formBackendURL(`/payment/transaction/${transactionId}/`)
        : null,
      fetcher
    );

  useEffect(() => {
    if (status === "successful") {
      mutateTransaction();
      mutateAll();
      clearInterval(pollingId.current);
      clearTimeout(timeoutPollingId.current);
    }
  }, [status, mutateTransaction, mutateAll, paymentId]);

  const executePollCallback = async () => {
    if (paymentId) {
      try {
        await mutatePayment();
      } catch (error) {
        captureException(error);
      }
    }
  };

  /**
   * This function is executed when `usePaymentPollingContext` is called.
   *
   * It ensures there is 1 polling interval running at any time.
   * It also initiates a timeout to stop polling after 5 minutes.
   */
  const startPolling = (paymentId: number) => {
    if (status === "successful") {
      return;
    }
    setPaymentId(paymentId);
    clearInterval(pollingId.current);
    clearTimeout(timeoutPollingId.current);

    // Start polling for payment status
    pollingId.current = window.setInterval(executePollCallback, SECONDS_8);
    executePollCallback();
    // Stop polling after 5 minutes
    timeoutPollingId.current = window.setTimeout(() => {
      clearInterval(pollingId.current);
      setPaymentId(null);
    }, MINUTES_5);
  };

  return (
    <PaymentPollingContext.Provider
      value={{
        startPolling,
        transactionData,
        planResponse,
        paymentData,
        mutatePayment,
        mutateTransaction,
        paymentId,
        status,
      }}
    >
      {children}
    </PaymentPollingContext.Provider>
  );
};
