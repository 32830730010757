import { useEffect } from "react";
import useSWR from "swr";
import { useRouter } from "next/router";
import { SubuserType } from "@/types/SubuserTypes";
import { formBackendUrlString } from "@/utils/formURL";
import { fetcher } from "@/utils/fetcher";
import WebshareApi from "../api";

export default function useSubuser() {
  const router = useRouter();
  const { subuser, ...query } = router.query;
  const subUserIdQueryString = subuser as string;

  const {
    data: subuserResponse,
    mutate: mutateSubuser,
    error: subuserError,
  } = useSWR<SubuserType>(
    subUserIdQueryString
      ? formBackendUrlString(`/subuser/${subUserIdQueryString}/`)
      : null,
    fetcher,
    {
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    if (!subUserIdQueryString && WebshareApi.getSubUserId() !== undefined) {
      router.push(
        {
          pathname: router.pathname,
          query: { ...query, subuser: WebshareApi.getSubUserId() },
        },
        undefined,
        { shallow: true }
      );
    }

    //Handle errors here. Cases where user edits query param manually.
    if (subUserIdQueryString && subuserError) {
      router.push({
        pathname: router.pathname,
        query,
      });
    }

    if (subuserResponse) {
      //To prevent frequent calls. Check for change.
      if (subuserResponse.id !== WebshareApi.getSubUserId()) {
        WebshareApi.setSubuserHeader(subuserResponse.id);
      }
    }
  }, [subuserResponse, subUserIdQueryString, query, router, subuserError]);

  return { mutateSubuser };
}
