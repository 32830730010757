import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import Link from "next/link";

export const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const FlexCentered = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

export const FlexBetween = styled(FlexBox)(() => ({
  justifyContent: "space-between",
}));

export const DisabledOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  background: theme.palette.background.paper,
  height: "100%",
  width: "100%",
  left: 0,
  top: 0,
  zIndex: 9,
  opacity: 0.7,
}));

export const NextLink = styled(Link)(() => ({
  textDecoration: "none",
  color: "inherit",
}));
