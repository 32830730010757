import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect } from "react";

/**
 * This hooks offers a consistent way to track modal opens.
 *
 * As a convienence, it also:
 *  - allows you to track the modal open event on mount (trackOnMount).
 *  - allows you to track the modal open event when a variable changes (trackOpenVariable).
 *
 * By default you need to call the returned function to track the event.
 */
export default function useModalTracking({
  modalName,
  trackOnMount,
  trackOpenVariable,
}: {
  modalName: string;
  trackOnMount?: true;
  trackOpenVariable?: boolean;
}) {
  const posthog = usePostHog();

  const trackOpen = useCallback(() => {
    posthog.capture("Modal Open", {
      modal: modalName,
    });
  }, [posthog, modalName]);

  useEffect(() => {
    if (trackOnMount) {
      trackOpen();
    }
  }, [trackOpen, trackOnMount]);

  useEffect(() => {
    if (trackOpenVariable) {
      trackOpen();
    }
  }, [trackOpen, trackOpenVariable]);

  return trackOpen;
}
