import { ButtonBase } from "@mui/material";
import ImgHelper from "./ImgHelper";

interface LogoProps {
  width?: number;
}

export default function WebshareIconLogo(props: LogoProps) {
  return (
    <ButtonBase sx={{ width: props.width || 150 }}>
      <ImgHelper alt="webshare-logo" src="/logoWithIcon.svg" />
    </ButtonBase>
  );
}
