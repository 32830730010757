import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import TimezoneUtils from "./TimezoneUtils";

import {
  formatDistanceStrict,
  differenceInDays,
  format,
  addMinutes,
  formatDistance,
  isValid,
} from "date-fns";
import logToSentry from "./logToSentry";

TimeAgo.addLocale(en);
const timeAgoUs = new TimeAgo("en-US");

export function timeAgo(startDateString: string) {
  const startDate = new Date(startDateString);
  return timeAgoUs.format(startDate, "round");
}

export function timeAgoInMins(startDateString: string) {
  const startDate = new Date(startDateString);
  return timeAgoUs.format(startDate, "round-minute");
}

const duration = (seconds: number) =>
  formatDistance(0, seconds * 1000, {
    addSuffix: false,
    includeSeconds: true,
  });

/**
 * Converts seconds to duration
 * @param {number } seconds
 * @returns {string}  1 month | 8 days | 3 hours
 */
export function secondsDuration(seconds: number) {
  return duration(seconds).replace("about ", "");
}

type DurationTypes = "month" | "day" | "hour" | "min" | "sec";
const HowMuchSecondsInDuration: {
  [key in Exclude<DurationTypes, "sec">]: number;
} = {
  month: 60 * 60 * 24 * 30,
  day: 60 * 60 * 24,
  hour: 60 * 60,
  min: 60,
};
const NextDetailedDuration: {
  [key in Exclude<DurationTypes, "sec">]: DurationTypes;
} = {
  month: "day",
  day: "hour",
  hour: "min",
  min: "sec",
};
/**
 * Converts seconds to detailed duration
 * @param {number } seconds
 * @param {string} by the highest duration unit used to display the result
 * @returns {string}  1 month | 8 days | 3 hours | 1 month and 8 days | 1 day and 4 hours
 */
export function secondsDetailedDuration(
  seconds: number,
  by: DurationTypes | undefined = "month"
): string {
  if (by !== "sec") {
    const nextDurationType = NextDetailedDuration[by];
    if (seconds <= HowMuchSecondsInDuration[by]) {
      return secondsDetailedDuration(seconds, nextDurationType);
    }

    const remaningSeconds = seconds % HowMuchSecondsInDuration[by];
    if (remaningSeconds > 0) {
      return `${secondsDuration(
        Math.floor(seconds - remaningSeconds)
      )}, ${secondsDetailedDuration(remaningSeconds, nextDurationType)}`;
    }
  }

  return secondsDuration(seconds);
}

/**
 * Returns time difference in x days y hours format using an ISO string.
 * @param {string } startDateString
 * @returns {string}  5 days 1 hour ago | just now | 3 days 3 seconds ago | 3 seconds ago like formatted string
 */
export function timeAgoInDaysHours(startDateString: string) {
  let oldDate = new Date(startDateString);
  return differenceInDaysHours(new Date(), oldDate);
}

export function formatTimeStats(dateString: string) {
  let newDate = TimezoneUtils.zonedDate(dateString);
  return format(newDate, "yyyy-MM-dd HH:mm:ss");
}

/**
 * Formats date in Sep 28, 2022 format.
 * @param {string } dateString
 * @returns {string} Sep 28, 2022
 */
export function formatTimeTransaction(dateString: string) {
  return format(new Date(dateString), "MMM dd, yyyy");
}

/**
 * Returns time in ["13:08:36", "May 18, 2022"] format.
 * @param {string} dateString
 * @param {Boolean | null} isUTC return utc timezone if true
 * @returns {string[]}
 */
export function arrayFormatTime(
  dateString: string | null | undefined,
  isUTC: boolean
) {
  if (!dateString) {
    throw new Error("Invalid or missing dateString");
  }

  if (isUTC) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid dateString format for UTC");
    }

    return [
      format(addMinutes(date, date.getTimezoneOffset()), "MMM dd, yyyy"),
      format(addMinutes(date, date.getTimezoneOffset()), "HH:mm:ss"),
    ];
  }

  const zonedDate = TimezoneUtils.zonedDate(dateString);

  if (!isValid(zonedDate)) {
    logToSentry("zonedDate invalid in arrayFormatTime", {
      extras: [["errorData", JSON.stringify({ dateString, zonedDate })]],
    });
    throw new Error("Invalid dateString format for zoned time");
  }

  return [format(zonedDate, "MMM dd, yyyy"), format(zonedDate, "HH:mm:ss")];
}

export function differenceInDaysHours(newDate: Date, oldDate: Date) {
  const daysAgo = formatDistanceStrict(newDate, oldDate, {
    unit: "day",
    roundingMethod: "floor",
  });
  const dayDifference = differenceInDays(newDate, oldDate);
  if (dayDifference > 0) {
    newDate.setDate(newDate.getDate() - dayDifference);
    const hoursAgo = formatDistanceStrict(newDate, oldDate);

    return `${daysAgo}, ${hoursAgo} ago`;
  } else {
    return timeAgo(oldDate.toISOString());
  }
}

export function differenceShort(newDate: Date, oldDate: Date) {
  const daysAgo = formatDistanceStrict(newDate, oldDate, {
    unit: "day",
    roundingMethod: "floor",
  });
  const dayDifference = differenceInDays(newDate, oldDate);
  if (dayDifference > 0) {
    return `${daysAgo} ago`;
  } else {
    return timeAgo(oldDate.toISOString());
  }
}

export function convertHMS(sec: number) {
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;
  return { hours, minutes, seconds };
}

export function convertHMStoSeconds({
  hours,
  minutes,
  seconds,
}: {
  hours: number;
  minutes: number;
  seconds: number;
}) {
  return hours * 3600 + minutes * 60 + seconds;
}

export function getDatesInRange(
  timestampGte: Date | null,
  timestampLte: Date | null
) {
  if (!timestampGte || !timestampLte) return [];
  const date = new Date(timestampGte.getTime());

  const dates = [];

  while (date <= timestampLte) {
    dates.push(date.getTime());
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export function nextEarnOutDate(
  future: Date | number,
  date: Date | number
): string {
  const days = formatDistanceStrict(new Date(future), new Date(date), {
    unit: "day",
    roundingMethod: "floor",
  });
  return ["in", Number(days.split(" ")[0]) <= 30 ? days : "0 days"].join(" ");
}

export type TimePeriod = "h" | "min" | "w" | "mo";
export function timePeriodToSeconds(timePeriod: TimePeriod): number {
  switch (timePeriod) {
    case "h": {
      return 3600;
    }
    case "min": {
      return 60;
    }
    case "w": {
      return 604800;
    }
    case "mo": {
      return 2592000;
    }
  }
}

export function isoStringToMonthDay(dateString: string) {
  let newDate = TimezoneUtils.zonedDate(dateString);
  return format(newDate, "MMM d");
}
export function isoStringToFullMonthDay(dateString: string) {
  let newDate = TimezoneUtils.zonedDate(dateString);
  return format(newDate, "MMMM d");
}
