import type {
  PaginatedNotificationType,
  NotificationObjectType,
} from "@/types/NotificationTypes";

import React from "react";
import useSWR from "swr";
import { LinearProgress, Box } from "@mui/material";
import { Divider } from "@mui/material";
import { fetcher } from "@/utils/fetcher";
import { formBackendURL } from "@/utils/formURL";
import NotificationComponents from "./Notifications/NotificationComponents";

type NotificationItemPropsType = {
  item: NotificationObjectType;
  divider: boolean;
};

const NotificationContent = React.memo(function Content({
  item,
  divider,
}: NotificationItemPropsType) {
  const ContentComponent = NotificationComponents[item.type] ?? null;
  if (!ContentComponent) return null;
  return (
    <>
      <ContentComponent item={item} />
      {divider && <Divider sx={{ mx: -2, my: 0 }} />}
    </>
  );
});

export const NotificationItem: React.FC<NotificationItemPropsType> = ({
  item,
  divider,
}) => <NotificationContent divider={divider} item={item} />;

const ProgressBar: React.FC = () => (
  <Box sx={{ width: "100%", my: 4 }}>
    <LinearProgress variant="indeterminate" />
  </Box>
);

const BlankSlate: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box sx={{ p: 4, textAlign: "center", cursor: "default" }}>{children}</Box>
);

const NotificationsList: React.FC<{
  archived: boolean;
  children: React.ReactNode;
}> = ({ archived, children }) => {
  const { data, isLoading } = useSWR<PaginatedNotificationType>(
    formBackendURL(
      [
        "/notification/?dismissed_at__isnull=",
        archived ? "False" : "True",
      ].join("")
    ),
    fetcher
  );

  const notifications =
    React.useMemo((): PaginatedNotificationType["results"] => {
      if (data && data.results.length > 0 && archived) {
        return data.results.filter((i) => i.dismissed_at !== null);
      }
      return data && data.results.length > 0 ? data.results : [];
    }, [data, archived]);

  return (
    <Box className="notifications-parent">
      {isLoading && !data && <ProgressBar />}

      {Boolean(data && notifications.length === 0) && (
        <BlankSlate>{children}</BlankSlate>
      )}

      {data &&
        notifications.length > 0 &&
        notifications.map(
          (
            item: NotificationObjectType,
            index: number,
            arr: NotificationObjectType[]
          ) => (
            <NotificationItem
              key={String(item.id)}
              item={item}
              divider={Boolean(arr.length !== index + 1)}
            />
          )
        )}
    </Box>
  );
};

export default NotificationsList;
