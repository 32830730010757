import WebshareApi from "../api";
import { getCookie, setCookie, deleteCookie } from "cookies-next";

const TOKEN_COOKIE_KEY = "ssotoken";
const DEPRECATED_COOKIE_KEY = "newDesignLoginToken";
const DARK_MODE_COOKIE_KEY = "darkModeSelection";

export function getToken(): string | undefined {
  const cookieString = getCookie(TOKEN_COOKIE_KEY)?.toString();
  if (cookieString === '""') {
    return undefined;
  }
  return cookieString;
}

export const setDomain = (strict: boolean = false) =>
  process.env.NEXT_PUBLIC_COOKIE_DOMAIN
    ? {
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        secure: true,
        sameSite: strict,
        maxAge: 60 * 60 * 24 * 365, // year
      }
    : undefined;

export function setToken(token: string) {
  WebshareApi.setAuthToken(token);
  setCookie(TOKEN_COOKIE_KEY, token, setDomain(true));
  setCookie(DEPRECATED_COOKIE_KEY, token, setDomain(true));
}

export function deleteToken() {
  setCookie(TOKEN_COOKIE_KEY, "", setDomain());
  //Removing sessionid causes old dashboard to logout too.
  setCookie("sessionid", "", setDomain());
  deleteCookie(TOKEN_COOKIE_KEY, setDomain(true));
  deleteCookie(DEPRECATED_COOKIE_KEY, setDomain(true));
}

//Updates _tid cookie.
//_tid may become different than ProfileType tracking_id after some time.
export function setTrackingIdCookie(trackingId: string) {
  setCookie("_tid", trackingId, setDomain());
}

export async function hasDarkmodeCookie() {
  const cookieString = await getCookie(DARK_MODE_COOKIE_KEY)?.toString();
  if (!cookieString) {
    return null;
  }
  if (cookieString === "true") {
    return true;
  }
  return false;
}

export function setDarkModeCookie() {
  setCookie(DARK_MODE_COOKIE_KEY, "true", setDomain());
}

export function removeDarkModeCookie() {
  setCookie(DARK_MODE_COOKIE_KEY, "false", setDomain());
}
