import * as React from "react";
import "@stripe/stripe-js";
import { ReactElement, ReactNode, useEffect } from "react";
import type { NextPage } from "next";
import { AppProps } from "next/app";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { RecaptchaProvider } from "@/providers/RecaptchaProvider";
import { useAppTheme } from "src/theme";
import DashboardLayout from "@/components/navigation/DashboardLayout";
import RouteGuard from "@/security/RouteGuard";
import { LicenseInfo } from "@mui/x-date-pickers-pro";

// fonts
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Script from "next/script";
import { SnackbarErrorsProvider } from "@/providers/SnackbarErrorsProvider";
import { SnackbarProvider } from "@/providers/SnackbarProvider";
import { Box, PaletteMode, useMediaQuery } from "@mui/material";
import ColorModeContext from "@/context/ColorModeContext";
import { ConfigProvider } from "@/context/ConfigContext";
import {
  hasDarkmodeCookie,
  removeDarkModeCookie,
  setDarkModeCookie,
} from "@/security/authentication";

import PHProvider from "@/providers/PHProvider";
import { PaymentPollingProvider } from "@/context/PaymentPollingContext";

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

LicenseInfo.setLicenseKey(
  "b3148cf17761337ab3a7ec7788b52368Tz04MDYwNCxFPTE3MzQyMDQ2MDQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const tagManageScript = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', 'GTM-W4N5FDC');
`;

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ??
    ((page) => (
      <DashboardLayout>
        {page}
        <Box height={90} />
      </DashboardLayout>
    ));
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [mode, setMode] = React.useState<PaletteMode>("light");

  useEffect(() => {
    async function readDarkModeCookie() {
      const isDarkMode = await hasDarkmodeCookie();
      if (isDarkMode === true) {
        setMode("dark");
      } else if (isDarkMode === false) {
        setMode("light");
      } else {
        if (prefersDarkMode) {
          setMode("dark");
        } else {
          setMode("light");
        }
      }
    }
    readDarkModeCookie();
  }, [prefersDarkMode]);

  const toggleColorMode = React.useCallback(() => {
    setMode((prevMode: PaletteMode) => {
      if (prevMode === "light") {
        setDarkModeCookie();
      } else {
        removeDarkModeCookie();
      }
      return prevMode === "light" ? "dark" : "light";
    });
  }, []);

  // Update the theme only if the mode changes
  const theme = useAppTheme(mode);

  return (
    <PHProvider>
      <ColorModeContext.Provider
        value={{
          mode,
          toggleColorMode,
        }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <RecaptchaProvider>
            <RouteGuard>
              <PaymentPollingProvider>
                {/* this needs to be added to resolve global this errors - https://github.com/vercel/next.js/discussions/49771 */}
                <Script
                  src="https://unpkg.com/@ungap/global-this@0.4.4/min.js"
                  noModule
                  async
                />
                <Script
                  id="gtag-base"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{ __html: tagManageScript }}
                />
                <SnackbarErrorsProvider>
                  <SnackbarProvider>
                    <ConfigProvider>
                      <Script
                        id="trust-pilot"
                        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                      />
                      {getLayout(<Component {...pageProps} />)}
                    </ConfigProvider>
                  </SnackbarProvider>
                </SnackbarErrorsProvider>
              </PaymentPollingProvider>
            </RouteGuard>
          </RecaptchaProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </PHProvider>
  );
}
