import { formatISO, isValid, startOfDay, subDays } from "date-fns";
import { useSession } from "../security/RouteGuards";
import TimezoneUtils from "../utils/TimezoneUtils";
import logToSentry from "@/utils/logToSentry";

export default function useSubscriptionTimestamps() {
  const { subscription } = useSession();
  const dateRange = {
    timestampGte: null,
    timestampLte: null,
  };
  if (!subscription) {
    return dateRange;
  }
  const { start_date, end_date } = subscription;

  const minDate = subDays(startOfDay(TimezoneUtils.zonedDate()), 90);
  const maxDate = TimezoneUtils.zonedDate(end_date);

  if (!isValid(minDate) || !isValid(maxDate)) {
    logToSentry(
      "subscription date error undefined dates useSubscriptionTimestamps",
      {
        extras: [
          [
            "errorData",
            JSON.stringify({
              subscription,
              timezone: TimezoneUtils.getTimezone(),
              minDate,
              minValid: isValid(minDate),
              maxDate,
              maxValid: isValid(maxDate),
            }),
          ],
        ],
      }
    );
  }

  if (formatISO(minDate) > start_date) {
    return { timestampGte: minDate, timestampLte: maxDate };
  }

  return {
    timestampGte: TimezoneUtils.zonedDate(start_date),
    timestampLte: maxDate,
  };
}
