import { ButtonBase, useTheme } from "@mui/material";
import ImgHelper from "./ImgHelper";

interface LogoProps {
  width?: number | string;
  onClick?: () => void;
}

export default function WebshareLogo(props: LogoProps) {
  const { width, onClick } = props;
  const theme = useTheme();
  return (
    <ButtonBase sx={{ width: width || 240 }} onClick={onClick}>
      <ImgHelper
        alt="webshare-logo"
        src={
          theme.palette.mode === "light"
            ? "/logoWithText.svg"
            : "/darkThemeLogoWithText.svg"
        }
      />
    </ButtonBase>
  );
}
