import { useCallback } from "react";
import { usePostHog } from "posthog-js/react";
// eslint-disable-next-line no-restricted-imports
import { Properties } from "posthog-js";
import { useFederatedUser } from "@/security/federation";

export const PostHogEvents = {
  CancellationCompleted: "Subscription cancellation survey completed",
  PageView: "$pageview",
  NpsAsked: "Asked NPS feedback",
  NpsCompleted: "Completed NPS feedback",
  SwitchedColorScheme: "Switched color scheme",
  CustomerSatisfactionSurveyCompleted: "Customer satisfaction survey completed",
  CustomerSatisfactionSurveyDismissed: "Customer satisfaction survey dismissed",
  CustomerSatisfactionSurveyDisplayed: "Customer satisfaction survey displayed",
};

const FederatedAccessTrackingValue = "utku@webshare.io";

export function useEvents() {
  const posthog = usePostHog();
  const { federationToken } = useFederatedUser();

  const captureEvent = useCallback(
    (
      eventName: (typeof PostHogEvents)[keyof typeof PostHogEvents],
      payload?: Properties
    ) => {
      if (!posthog) return;

      if (Boolean(federationToken)) {
        posthog.capture(eventName, {
          ...payload,
          federated_access: FederatedAccessTrackingValue,
        });
      } else {
        posthog.capture(eventName, payload);
      }
    },
    [federationToken, posthog]
  );

  return { captureEvent };
}
