import useSWR from "swr";
import { fetcher } from "../utils/fetcher";
import { formBackendUrlString } from "../utils/formURL";
import { ActivationStatusType } from "@/types/ActivationStatusTypes";

export default function useAccountActivation(sessionToken: string | undefined) {
  const {
    data: activationStatus,
    mutate: mutateActivationStatus,
    error: activationError,
  } = useSWR<ActivationStatusType>(
    sessionToken ? formBackendUrlString(`/activation/`) : null,
    fetcher,
    {
      onErrorRetry: (error) => {
        if (error.response?.status === 401) return;
      },
      revalidateOnFocus: false,
    }
  );

  return { activationStatus, mutateActivationStatus, activationError };
}
