import { toZonedTime, fromZonedTime } from "date-fns-tz";

class TimezoneUtils {
  private timezone: string | undefined = undefined;

  private useDate = (date?: string | number | Date | null) => {
    if (date instanceof Date) {
      return date;
    } else {
      return date ? new Date(date) : new Date();
    }
  };

  // Primary use is for testing. Avoid using it in runtime code
  public clearTimezone() {
    this.timezone = undefined;
  }

  public setTimezone(timezone: string) {
    this.timezone = timezone;
  }

  public getTimezone(): string {
    // There is a race condition where the timezone is not set yet, so we fallback to the browser timezone
    return this.timezone || Intl?.DateTimeFormat().resolvedOptions().timeZone;
  }

  public zonedDate = (date?: string | number | Date | null) => {
    const _date = this.useDate(date);
    return toZonedTime(_date, this.getTimezone());
  };

  public unzonedDate = (date?: string | number | Date) => {
    const _date = this.useDate(date);
    return fromZonedTime(_date, this.getTimezone());
  };
}

const timezoneUtils = new TimezoneUtils();
export default timezoneUtils;

export function compareDates(date1: Date, date2: Date): boolean {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

/**
 * Based on the label of a timezone from timezone-list package, it returns the continent
 */
export function getContinent(timezone: string) {
  return timezone.split("/")[0];
}

/**
 * Based on the label of a timezone from timezone-list package, it returns the offset.
 * Example:
 * "America/Cuiaba (GMT-04:00)" -> -04:00
 */

export function getOffset(string: string) {
  const offset = string.split(" ")[1];
  return offset;
}

/**
 * Based on the label of a timezone from timezone-list package, it returns the user friendly label.
 *
 * Backend returns labels as defined by timezones-util, this function converts it for a format that
 * makes sense in our Autocomplete selection
 *
 * Input: `America/Argentina/Buenos_Aires (GMT-03:00)`
 * Output: `-03:00 Argentina - Buenos Aires`
 */
export function getLabel(label: string) {
  // eg. GMT+00:00
  let offset = getOffset(label);
  // eg. +00:00
  offset = offset.slice(4, -1);

  // eg. America/Argentina/Buenos_Aires
  const location = label.split(" ")[0];
  const segments = location.split("/");
  // Remove continent (America)
  segments.shift();

  // eg. Argentina - Buenos_Aires
  const locationText = segments.join(" - ");

  // eg. -03:00 Argentina - Buenos Aires
  return [offset, locationText].join(" ").replace(/_/g, " ");
}
