import { useState, useEffect } from "react";
import Image from "next/image";
import { DRAWER_WIDTH, DrawerContext } from "@/providers/DrawerProvider";
import { useSession } from "@/security/RouteGuards";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import StarsIcon from "@mui/icons-material/Stars";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NextLink } from "@/components/common/styles";
import WebshareLogo from "../common/WebshareLogo";
import Menu from "@mui/material/Menu";
import { Backdrop, MenuItem, useMediaQuery } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ImgHelper from "../common/ImgHelper";
import { useRouter } from "next/router";
import { useFederatedUser } from "@/security/federation";
import useAccountLogout from "@/hooks/useAccountLogout";
import BaseAppBar from "@/components/navigation/BaseAppBar";
import Banners from "./Banners";
import NavMenu from "./NavMenu/NavMenu";

interface PersistentDrawerLeftProps {
  children: React.ReactNode;
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: `${DRAWER_WIDTH}px`,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: `${DRAWER_WIDTH}px`,
    },

    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft({
  children,
}: PersistentDrawerLeftProps) {
  const theme = useTheme();
  const router = useRouter();
  const { profile } = useSession();
  const { deleteFederationToken, federationToken } = useFederatedUser();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(!isMd);
  const [mobileSwitch, setMobileSwitch] = useState(false);

  const handleLogout = useAccountLogout();

  useEffect(() => {
    if (isMd && !mobileSwitch) {
      setMobileSwitch(true);
      open && setOpen(false);
    }
    if (!isMd && mobileSwitch) {
      setMobileSwitch(false);
      !open && setOpen(true);
    }
  }, [isMd, mobileSwitch, open]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openSettings = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function autoCloseDrawer() {
    if (open && isMd) {
      handleDrawerClose();
    }

    setAnchorEl(null);
  }

  const handleLogoClick = () => {
    router.push("/dashboard");
  };

  const settingsListStyle = {
    mt: "-15px",
    mb: "-15px",
    pr: 3,
    pl: 3,
    "&& .Mui-selected, && .Mui-selected:hover": {
      bgcolor: alpha(theme.palette.common.black, 0.04),
      borderRadius: "8px",
    },
    "& .MuiListItemButton-root": {
      borderRadius: "8px",
    },
    // hover states
    "& .MuiListItemButton-root:hover": {
      bgcolor: alpha(theme.palette.common.black, 0.04),
    },
  };

  return (
    <Box sx={{ flexDirection: "row" }}>
      <CssBaseline />
      <BaseAppBar
        position="fixed"
        width={DRAWER_WIDTH}
        open={open}
        setOpen={setOpen}
        federatedMode={{
          enabled: Boolean(federationToken),
          email: profile?.email,
          release: deleteFederationToken,
        }}
      />
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box
            sx={{ margin: 0, pr: "50px" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <WebshareLogo width={150} onClick={handleLogoClick} />
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ImgHelper
                alt="doubleChevron"
                src={
                  theme.palette.mode === "dark"
                    ? "/doubleChevronDarkMode.svg"
                    : "/doubleChevron.svg"
                }
              />
            ) : (
              <>
                <ChevronRightIcon />
                <ChevronRightIcon />
              </>
            )}
          </IconButton>
        </DrawerHeader>

        <List sx={settingsListStyle}>
          <ListItemButton onClick={(event) => handleClick(event)}>
            {profile?.is_vip_customer ? (
              <ListItemIcon>
                <Image
                  alt="avatar"
                  src={"/avatar.svg"}
                  width={40}
                  height={40}
                />
              </ListItemIcon>
            ) : null}
            <ListItemText
              primary={
                <Box>
                  <Typography
                    noWrap
                    variant="body1"
                    sx={{
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                  >
                    {profile?.email ? (
                      <span>{profile.email}</span>
                    ) : (
                      <span>user@gmail.com</span>
                    )}
                  </Typography>
                  {profile?.is_vip_customer ? (
                    <Typography variant="body2" color="warning.light">
                      VIP Member
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      Settings
                    </Typography>
                  )}
                </Box>
              }
            />
            {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </ListItemButton>
        </List>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openSettings}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <NextLink href="/profile/profile" passHref>
            <MenuItem onClick={autoCloseDrawer}>
              <SettingsOutlinedIcon sx={{ pr: 2 }} />
              Settings
            </MenuItem>
          </NextLink>
          {profile?.is_vip_customer ? (
            <NextLink href="/profile/vip" passHref legacyBehavior>
              <MenuItem onClick={autoCloseDrawer} component="a">
                <StarsIcon sx={{ pr: 2 }} />
                VIP Member
              </MenuItem>
            </NextLink>
          ) : null}
          <MenuItem onClick={handleLogout}>
            <LogoutOutlinedIcon sx={{ pr: 2 }} />
            Logout
          </MenuItem>
        </Menu>

        <NavMenu autoCloseDrawer={autoCloseDrawer} />
      </Drawer>
      <Box sx={{ overflowX: "hidden" }}>
        <Main open={open}>
          <DrawerHeader />
          <DrawerContext.Provider
            value={{
              open,
              drawerWidth: DRAWER_WIDTH,
            }}
          >
            <Banners />
            <Box sx={{ p: isMd ? 0 : 3 }}>{children}</Box>
          </DrawerContext.Provider>
          <Backdrop
            sx={{ color: theme.palette.common.white }}
            open={open && isMd}
            onClick={autoCloseDrawer}
          />
        </Main>
      </Box>
    </Box>
  );
}
