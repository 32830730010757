import { PaginatedVerificationType } from "@/api/verification";
import { fetcher } from "@/utils/fetcher";
import { formBackendURL } from "@/utils/formURL";
import useSWR from "swr";

export function useVerificationFlow({
  flowType,
  flowType__in,
  state = "inflow",
}: {
  flowType?: "acceptable_use_violation" | "fraudulent_payment";
  flowType__in?: ("acceptable_use_violation" | "fraudulent_payment")[];
  state?: "inflow";
}) {
  const queryString = new URLSearchParams();
  queryString.set("state", state);
  if (flowType) {
    queryString.set("type", flowType);
  }
  if (flowType__in) {
    queryString.set("type__in", flowType__in.join(","));
  }

  return useSWR<PaginatedVerificationType>(
    formBackendURL(`/verification/flow/?${queryString.toString()}`),
    fetcher
  );
}
