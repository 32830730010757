import { useFeatureFlagEnabled } from "posthog-js/react";

//Checks whether a flag is available on posthog.
//Don't use it for posthog experiments.
export default function useIsFlagEnabled(featureName: string) {
  return useFeatureFlagEnabled(featureName);

  // try {
  //   const posthog = usePostHog();
  //   return !!posthog?.isFeatureEnabled(featureName);
  // } catch {
  //   return false;
  // }
}
