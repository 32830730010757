import type { NotificationItemComponent } from "./NotificationItem";

import React from "react";
import { Typography, Button, ButtonProps } from "@mui/material";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import NotificationItem from "./NotificationItem";
import useIntercom from "@/hooks/useIntercom";

const UnlimitedBandwidthGetsThrottledNotification: NotificationItemComponent =
  ({ item }) => {
    const { showNewMessage } = useIntercom();

    const handleContactSupport = () => {
      showNewMessage(
        "I have received reduced proxy speed while on unlimited bandwidth."
      );
    };

    const buttonProps: ButtonProps = {
      size: "small",
      disableElevation: true,
      color: !item.dismissed_at ? "warning" : "primary",
    };

    return (
      <NotificationItem variant={!item.dismissed_at ? "warning" : "default"}>
        <NotificationItem.Title created_at={item.created_at}>
          <span>Reduced proxy speed</span>
        </NotificationItem.Title>
        <NotificationItem.Content>
          <Typography variant="body2">
            You may be experiencing reduced speed due to the extreme bandwidth
            usage with small number of proxies in your subscription plan.
          </Typography>
        </NotificationItem.Content>
        <NotificationItem.Actions {...item}>
          <Button
            variant="contained"
            onClick={handleContactSupport}
            {...buttonProps}
            data-testid="contact-support-button"
            endIcon={<MessageOutlinedIcon />}
          >
            <span>Contact Support</span>
          </Button>
        </NotificationItem.Actions>
      </NotificationItem>
    );
  };

export default UnlimitedBandwidthGetsThrottledNotification;
