import WebshareApi from "..";
import CountryType from "@/types/CountryType";
const webshareApi = WebshareApi.getInstance();

interface IResetDownlaodTokenResponse {
  proxy_list_download_token: string;
}
export const resetDownloadToken = () =>
  webshareApi.post<IResetDownlaodTokenResponse>(
    "/proxy/config/reset_download_token/"
  );

export const proxyListRefresh = () => webshareApi.post("/proxy/list/refresh/");

type CreateIpAuthorizationType = { ip_address: string };

export const createIpAuthorization = (params: CreateIpAuthorizationType) =>
  webshareApi.post("/proxy/ipauthorization/", { ...params });

type AllocateUnallocatedCountriesType = { new_countries: CountryType };

export const allocateUnallocatedCountries = (
  params: AllocateUnallocatedCountriesType
) =>
  webshareApi.post("/proxy/config/allocate_unallocated_countries/", {
    ...params,
  });

type PatchProxyConfigType = {
  ip_authorization_country_codes?: string[] | null;
};

export interface IProxyConfig {
  username: string;
  password: string;
  request_timeout: number;
  request_idle_timeout: number;
}

export const patchProxyConfig = <T>(params: T) =>
  webshareApi.patch<PatchProxyConfigType>("/proxy/config/", {
    ...params,
  });
