import { captureException } from "@sentry/nextjs";
import React, { createContext, createRef, useCallback } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const RECAPTCHA_KEY =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "dev"
    ? "6LdeHBAlAAAAAEcwi3J7q756tGukMk3Vq7cTpuOa" // development mode key
    : "6LeHZ6UUAAAAAKat_YS--O2tj_by3gv3r_l03j9d"; // production mode key

const isDev = process.env.NEXT_PUBLIC_ENVIRONMENT === "dev";
interface IRecaptcha {
  onSubmitWithReCAPTCHA: () => Promise<string | null | undefined>;
}

const RecaptchaContextDefaultValue: IRecaptcha = {
  onSubmitWithReCAPTCHA: () =>
    new Promise((resolve) => {
      resolve(undefined);
    }),
};

export const RecaptchaContext = createContext<IRecaptcha>(
  RecaptchaContextDefaultValue
);

export const RecaptchaProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const recaptchaRef = createRef<ReCAPTCHA>();

  const onSubmitWithReCAPTCHA = useCallback(async () => {
    if (isDev) return "...";

    if (!recaptchaRef || !recaptchaRef.current) {
      captureException(new Error("No recaptchaRef or recaptchaRef.current"));
      return undefined;
    }

    try {
      recaptchaRef.current.reset();
      recaptchaRef.current.getValue();
      const token = await recaptchaRef.current.executeAsync();
      return token;
    } catch (err: unknown) {
      captureException(err as Error);
      return undefined;
    }
  }, [recaptchaRef]);

  return (
    <RecaptchaContext.Provider value={{ onSubmitWithReCAPTCHA }}>
      {children}
      {isDev ? null : (
        <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={RECAPTCHA_KEY}
          style={{ display: "none" }}
        />
      )}
    </RecaptchaContext.Provider>
  );
};
