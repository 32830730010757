import type { NotificationItemComponent } from "./NotificationItem";
import React from "react";
import { useRouter } from "next/router";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Typography } from "@mui/material";
import NotificationItem from "./NotificationItem";
import {
  DEFAULT_CUSTOMIZE_PATHNAME,
  DEFAULT_CHECKOUT_PATHNAME,
} from "@/utils/formHref";

const SECOND_TIME_EFFECT = "proxies_stopped_working";
const THIRD_TIME_EFFECT = "converted_to_free_plan";
const SubscriptionRenewFailedNotification: NotificationItemComponent = ({
  item,
}) => {
  const router = useRouter();

  const handleRenew = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    router.push({
      pathname: DEFAULT_CHECKOUT_PATHNAME,
      query: {
        source: "renew-plan-notification",
        renew: true,
      },
    });
  };

  const handleUpgrade = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    router.push({
      pathname: DEFAULT_CUSTOMIZE_PATHNAME,
    });
  };

  return (
    <NotificationItem variant={!item.dismissed_at ? "error" : "default"}>
      <NotificationItem.Title created_at={item.created_at}>
        {item.context.effect === THIRD_TIME_EFFECT ? (
          <span>Subscription Cancelled</span>
        ) : (
          <>
            <span>Renew Your Plan</span>
            {item.context.effect === SECOND_TIME_EFFECT && (
              <span>&nbsp;(Final Notice)</span>
            )}
          </>
        )}
      </NotificationItem.Title>
      <NotificationItem.Content>
        {item.context.effect === THIRD_TIME_EFFECT ? (
          <Box component="div">
            Your subscription renewal attempt was unsuccessful and your account
            is now switched to Free Plan with 1GB bandwidth and 10 proxies. If
            you wish to continue using Webshare Proxy, please upgrade your plan.
          </Box>
        ) : (
          <>
            <Box component="div">
              Your subscription renewal attempt was unsuccessful. To ensure
              uninterrupted access to your proxies, please review your payment
              method and renew your plan.
            </Box>
            {item.context.effect === SECOND_TIME_EFFECT && (
              <Typography component="div" color="error">
                Please note that your proxy activity will be temporarily paused
                until the payment issue is resolved.
              </Typography>
            )}
          </>
        )}
      </NotificationItem.Content>
      <NotificationItem.Actions {...item}>
        <Button
          onClick={
            item.context.effect === THIRD_TIME_EFFECT
              ? handleUpgrade
              : handleRenew
          }
          size="small"
          variant={!item.dismissed_at ? "contained" : "outlined"}
          color={!item.dismissed_at ? "error" : "primary"}
          disableElevation
          endIcon={<OpenInNewIcon fontSize="small" />}
        >
          {item.context.effect === THIRD_TIME_EFFECT ? "Upgrade" : "Renew Plan"}
        </Button>
      </NotificationItem.Actions>
    </NotificationItem>
  );
};

export default SubscriptionRenewFailedNotification;
