import { Box, Typography, Button, Stack } from "@mui/material";
import { NextLink } from "@/components/common/styles";
import { useRouter } from "next/router";

import HelpArticle from "../buttons/HelpArticle";
import { Header } from "./commonStyles";
import { ColoredCard } from "@/ui/ColoredCard";
import { useMemo, useState } from "react";
import useNeedsAccountVerification from "@/hooks/useNeedsAccountVerification";
import useIsFlagVariantTest from "@/hooks/useIsFlagVariantTest";
import { Warning } from "@mui/icons-material";
import InfoCardDialog from "@/ui/dialogs/InfoCardDialog";
import { getObjectFromStorage, setToStorage } from "@/utils/localStorageUtils";

export default function AccountVerificationBanner() {
  const inExp = useIsFlagVariantTest("account_verification_red_retry_banner");
  const [isModalOpen, setModalOpen] = useState(true);
  const route = useRouter();

  const {
    needsEvidence,
    needsIdVerification,
    hasUnansweredQuestions,
    flows,
    shouldRetryIdVerification,
  } = useNeedsAccountVerification();

  const type =
    needsEvidence ||
    hasUnansweredQuestions ||
    needsIdVerification ||
    (shouldRetryIdVerification && inExp)
      ? "error"
      : "warning";

  const { title, body, buttonText } = useMemo(() => {
    if (hasUnansweredQuestions)
      return {
        title: "Additional Information Required",
        body: "Please answer some questions about your activity.",
        buttonText: "Submit Additional Info",
      };
    if (needsEvidence)
      return {
        title: "Verification Required",
        body: "Please answer some questions and verify your account.",
        buttonText: "Verify Account",
      };

    if (shouldRetryIdVerification && inExp) {
      return {
        title: "ID Verification Failed",
        body: "Please retry verification to avoid any interruptions on proxy activities.",
        buttonText: "Retry Verification",
      };
    }

    if (needsIdVerification)
      return {
        title: "ID Verification Required",
        body: "Please verify your ID.",
        buttonText: "Verify ID",
      };
    return {
      title: "Pending Verification",
      body: "We have received your reply and will get back to you within 2 business days.",
      buttonText: "",
    };
  }, [
    hasUnansweredQuestions,
    inExp,
    needsEvidence,
    needsIdVerification,
    shouldRetryIdVerification,
  ]);

  const shouldHideBanner =
    route.pathname === "/profile/profile" ||
    route.pathname === "/accountverification" ||
    route.pathname === "/accountverificationforms" ||
    route.pathname === "/subscription/customize" ||
    route.pathname === "/subscription/customize/checkout" ||
    !flows?.length;

  return shouldHideBanner ? null : (
    <ColoredCard type={type}>
      <Box sx={{ px: 3, py: 2 }}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction="row" alignItems={"center"} spacing={3}>
            {buttonText === "Retry Verification" ? (
              <Box>
                <Warning color="error" />
              </Box>
            ) : null}
            <Box>
              <Header
                variant="body1"
                fontWeight="bold"
                type={type}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {title}
                <HelpArticle id="pendingVerification" />
              </Header>
              <Typography variant="body2">{body}</Typography>
            </Box>
          </Stack>

          {type === "error" ? (
            <NextLink href={"/accountverificationforms"} passHref>
              <Button variant="contained" color="error">
                {buttonText}
              </Button>
            </NextLink>
          ) : null}
        </Stack>
      </Box>

      <InfoCardDialog
        open={
          buttonText === "Retry Verification" &&
          !getObjectFromStorage("hasSeenIdVerificationRetryModal") &&
          isModalOpen
        }
        onClose={() => {
          setModalOpen(false);
          setToStorage("hasSeenIdVerificationRetryModal", "true");
        }}
        imageAlt="error"
        imageSrc="/error"
        title="ID Verification Failed"
        description="Please retry verification to avoid any interruptions on proxy activities."
      >
        <NextLink href="/accountverificationforms" passHref>
          <Button
            variant="contained"
            data-testid="verify-account-button"
            onClick={() => {
              setToStorage("hasSeenIdVerificationRetryModal", "true");
            }}
          >
            Retry Verification
          </Button>
        </NextLink>
      </InfoCardDialog>
    </ColoredCard>
  );
}
