import { getCookie } from "cookies-next";
// eslint-disable-next-line no-restricted-imports
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (typeof window !== "undefined") {
  //Set by middleware.page.tsx

  const flags = getCookie("bootstrapData")?.toString();
  let bootstrapData;
  if (flags) {
    bootstrapData = JSON.parse(flags);
  }

  //TODO: bootstrapData keeps a set of all flags adding it to sentry will be helpfull.

  posthog.init(process.env.NEXT_PUBLIC_POST_HOG_API_KEY as string, {
    api_host: "https://app.posthog.com",
    capture_pageview: false,
    autocapture: true,
    enable_recording_console_log: true,
    persistence: "localStorage+cookie",
    session_recording: {
      maskAllInputs: false,
    },
    bootstrap: bootstrapData,
    loaded(posthog_instance) {
      if (!window.posthog) {
        window.posthog = posthog_instance;
      }
    },
  });
}

export default function PHProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
