import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Why?
 * Because this doesn't appear to be a standard thing in MUI so we need our
 * own one. I also don't want every close icon to be slightly different in our
 * app, with slightly different spacing.
 */
export default function DialogCloseButton({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <IconButton
      aria-label="close"
      onClick={onClick}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
      }}
    >
      <CloseIcon />
    </IconButton>
  );
}
