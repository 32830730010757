import type { NotificationTypes } from "@/types/NotificationTypes";
import type { NotificationItemComponent } from "./NotificationItem";
import NotificationDefault from "./NotificationDefault";
import EightyPercentBandwidthUsedNotification from "./EightyPercentBandwidthUsedNotification";
import HundredPercentBandwidthUsedNotification from "./HundredPercentBandwidthUsedNotification";
import TooMuchBandwidthTooLittleProxiesNotification from "./TooMuchBandwidthTooLittleProxiesNotification";
import RenewFailedNotification from "./RenewFailedNotification";
import ProjectedProxyUsageOver100 from "./ProjectedProxyUsageOver100";
import HighConcurrencyErrorNotification from "./HighConcurrencyErrorNotification";
import CCExpiringSoonNotification from "./CCExpiringSoonNotification";
import UnlimitedBandwidthGetsThrottledNotification from "./UnlimitedBandwidthGetsThrottledNotification";
import QuestionIsAddedNotification from "./QuestionIsAddedNotification";
import ForbiddenHostErrorNotification from "./ForbiddenHostErrorNotification";

const NotificationComponents: Record<
  NotificationTypes,
  NotificationItemComponent
> = {
  default: NotificationDefault,
  too_much_bandwidth_too_little_proxies:
    TooMuchBandwidthTooLittleProxiesNotification,
  subscription_renew_failed: RenewFailedNotification,
  projected_proxy_usage_over_100: ProjectedProxyUsageOver100,
  high_concurrency_error: HighConcurrencyErrorNotification,
  "80_percent_bandwidth_used": EightyPercentBandwidthUsedNotification,
  "100_percent_bandwidth_used": HundredPercentBandwidthUsedNotification,
  subscription_cc_will_expire_soon: CCExpiringSoonNotification,
  unlimited_bandwidth_gets_throttled:
    UnlimitedBandwidthGetsThrottledNotification,
  question_is_added: QuestionIsAddedNotification,
  forbidden_host_error: ForbiddenHostErrorNotification,
};

export default NotificationComponents;
