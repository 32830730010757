import { useMemo } from "react";
import { getMenu } from "../../menu/getMenu";
import { ProxyPlanCategoryUIType } from "../../menu/CustomizeMenuTypes";
import {
  findUserPlanMainMenuItem,
  findUserPlanSubMenuItem,
  fixPlanValues,
} from "../../subscriptionHelpers";
import { useSession } from "@/security/RouteGuards";
import { isPlanRotating } from "../checkout/utils/rotatingPlanUtils";
// import { isPlanRotating } from "../checkout/utils/rotatingPlanUtils";

export default function useGetMenu() {
  const { plan: sessionPlan } = useSession();

  const plan = fixPlanValues({
    sessionPlan,
  });

  const hasVerifiedIrregularPlan = useMemo(() => {
    if (!plan) return false;
    if (!plan.required_site_checks?.length) return false;
    if (plan.proxy_type === "shared" && plan.proxy_subtype === "default")
      return false;
    return true;
  }, [plan]);

  const {
    paidPlanCategories,
    menu: PlanCategories,
    currentCategoryUI,
    userDefaultSubmenuItem,
  } = useMemo(() => {
    const menuOriginal = getMenu();

    const planIsRotating = isPlanRotating(plan);

    const PaidPlanCategories: ProxyPlanCategoryUIType[] = [];

    const currentCategoryUI = plan
      ? findUserPlanMainMenuItem(menuOriginal, plan)
      : undefined;

    const userDefaultSubmenuItem = plan
      ? findUserPlanSubMenuItem(menuOriginal, plan, planIsRotating)
      : undefined;

    const menu: ProxyPlanCategoryUIType[] = [];

    menuOriginal.forEach((planCategory) => {
      if (
        hasVerifiedIrregularPlan &&
        currentCategoryUI &&
        planCategory.menuType === "verified" &&
        userDefaultSubmenuItem
      ) {
        menu.push({
          ...planCategory,
          subType: [
            ...planCategory.subType,
            {
              ...userDefaultSubmenuItem,
              subType:
                userDefaultSubmenuItem.subType === "isp"
                  ? "verifiedIsp"
                  : "verified",
              name: `Google Search (${currentCategoryUI.name} ${userDefaultSubmenuItem.name})`,
              description: "",
            },
          ],
        });
      } else {
        menu.push(planCategory);
      }
    });

    menu.forEach((planCategory) => {
      if (planCategory.menuType !== "free") {
        PaidPlanCategories.push(planCategory);
      }
    });

    return {
      paidPlanCategories: PaidPlanCategories,
      currentCategoryUI,
      userDefaultSubmenuItem,
      menu,
    };
  }, [hasVerifiedIrregularPlan, plan]);

  return {
    paidPlanCategories,
    PlanCategories,
    currentCategoryUI,
    userDefaultSubmenuItem,
  };
}
