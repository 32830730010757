import { Dispatch, SetStateAction, useMemo, useReducer, useState } from "react";

import { NextRouter } from "next/router";
import { isEqual } from "lodash";

const decodeBase64 = (str: string): string =>
  Buffer.from(str, "base64").toString("binary");

const encodeBase64 = (str: string): string =>
  Buffer.from(str, "binary").toString("base64");

export function parseWithDate(jsonString: string): any {
  var reDateDetect = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/; // startswith: 2015-04-29T22:06:55
  var resultObject = JSON.parse(jsonString, (_key: any, value: any) => {
    if (typeof value == "string" && reDateDetect.exec(value)) {
      return new Date(value);
    }
    return value;
  });
  return resultObject;
}

/**
 * Returns a decoded object / {} depending on query variable.
 * @param {string} string
 * @returns
 */
export function parseBase64(queryParam: string | undefined | null) {
  if (!queryParam) {
    return {};
  }
  try {
    return parseWithDate(decodeBase64(decodeURIComponent(queryParam)));
  } catch {
    //If old string throws a decoding error, return an empty array to re-initialize.
    return {};
  }
}

/**
 * Returns a url safe encoded version of object
 * @param {object } obj
 * @returns
 */
export function stringifyBase64(obj: any) {
  return encodeURIComponent(encodeBase64(JSON.stringify(obj)));
}

type QueryParamMapper<T> = {
  [K in keyof T]: [T[K], Dispatch<SetStateAction<T[K]>>, string];
};
/**
 * Returns an object with stateful values for params, and corresponding function to update them making use of queryParams for initial values.
 * @param {object} params Object with state names as keys, initial values.
 * @param {NextRouter} router NextRouter
 * @param {string} group Name displayed on query parameters.
 * @returns
 */
export default function createQueryParamStates<S>(
  params: S,
  router: NextRouter,
  group: string
): QueryParamMapper<S> {
  //Object entries requires a cast.
  return Object.entries(params as Object).reduce(
    (p, [key, value]) =>
      Object.assign(p, {
        [key]: useQueryParamState<typeof value>(value, router, key, group),
      }),
    {}
  ) as QueryParamMapper<S>;
}

/**
 * Returns an object with stateful values for params, and corresponding function to update them making use of queryParams for initial values.
 * @param {object} params Object with state names as keys, initial values.
 * @param {NextRouter} router NextRouter
 * @param {string} group Name displayed on query parameters.
 * @returns
 */
export function useMemorySyncedStates<S>(
  params: S,
  router: NextRouter,
  group: string
): QueryParamMapper<S> {
  const queryDefaultParamObject = useMemo(() => {
    const queryState = getStateWithGroupName(group, router);

    return queryState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type Action = {
    name: string;
    action: SetStateAction<S>;
  };

  function reducer(prevState: S, action: Action) {
    const query = {
      ...router.query,
      //Add or override the hash code with the hash.
      [group]: stringifyBase64({
        ...prevState,
        [action.name]: action.action,
      }),
    };

    if (
      isEqual(prevState, {
        ...prevState,
        [action.name]: action.action,
      })
    ) {
      return prevState;
    }
    router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true }
    );
    return {
      ...prevState,
      [action.name]: action.action,
    };
  }

  //reducer is the safer choice here, as it can be used sync mode.
  const [paramsHook, dispatch] = useReducer(reducer, {
    ...params,
    ...queryDefaultParamObject,
  });

  function updateQueryParams(name: string, action: SetStateAction<S>) {
    //Call reducer with key name and value.
    dispatch({ name, action });
  }

  //Object entries requires a cast.
  return Object.entries(paramsHook as Object).reduce(
    (p, [key, value]) =>
      Object.assign(p, {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [key]: useSavedState<typeof value>(
          value,
          router,
          key,
          updateQueryParams
        ),
      }),
    {}
  ) as QueryParamMapper<S>;
}

/**
 * Returns a stateful value, and a function to update it. Updated initialState depending on query params.
 * @param {string | null | number} initialState Initial state value
 * @param {NextRouter} router NextRouter
 * @param {string} name Parameter name.
 * @param {function} group Name displayed on query parameters.
 * @returns  [getter, setter, name]
 */
function useSavedState<S>(
  initialState: S,
  router: NextRouter,
  name: string,
  updateQueryParams: (name: string, action: SetStateAction<S>) => void
): [S, Dispatch<SetStateAction<S>>, string] {
  const [getter, setter] = useState<S>(initialState);

  function setterWrapper(action: SetStateAction<S>) {
    //Check existence of router.

    if (router) {
      if (action === getter) {
        return;
      }

      updateQueryParams(name, action);
    }

    setter(action);
  }

  return [getter, setterWrapper, name];
}

/**
 * Returns base64 encoded string in query params with group name.
 * @param {string} groupName Name displayed on query parameters.
 * @param {NextRouter} router NextRouter
 * @returns {string}
 */
export function getBase64WithGroupName(
  groupName: string,
  router: NextRouter
): string | undefined {
  if (router) {
    const { [groupName]: oldQuery } = router.query;
    return oldQuery as string;
  }
  return undefined;
}

/**
 * Returns object in query params with group name.
 * @param {string} groupName Name displayed on query parameters.
 * @param {NextRouter} router NextRouter
 * @returns {object}
 */
export function getStateWithGroupName(groupName: string, router: NextRouter) {
  if (router) {
    const base64EncodedString = getBase64WithGroupName(groupName, router);
    if (base64EncodedString) {
      const queryParamObject = parseBase64(base64EncodedString);
      return queryParamObject;
    }
  }
  return undefined;
}

/**
 * Returns a stateful value, and a function to update it. Updated initialState depending on query params.
 * @param {string | null | number} initialState Initial state value
 * @param {NextRouter} router NextRouter
 * @param {string} group Name displayed on query parameters.
 * @returns  [getter, setter, name]
 */
function useQueryParamState<S>(
  initialState: S | (() => S),
  router: NextRouter,
  name: string,
  group: string
): [S, Dispatch<SetStateAction<S>>, string] {
  let startingState = initialState;
  //
  const queryParamObject = getStateWithGroupName(group, router);
  if (queryParamObject) {
    if (queryParamObject[name]) {
      startingState = queryParamObject[name];
    }
  }

  const [getter, setter] = useState<S>(startingState);

  function setterWrapper(action: SetStateAction<S>) {
    const oldQueryParamObject = getStateWithGroupName(group, router);
    //Check existence of router.
    if (router) {
      //Check for current hash.
      let newQueryParamObject = {};
      if (oldQueryParamObject) {
        newQueryParamObject = {
          ...oldQueryParamObject,
          [name]: action,
        };
      } else {
        newQueryParamObject = {
          //Add or override the hash code with the hash.
          [name]: action,
        };
      }
      //Add query with group name to existing query.
      const query = {
        ...router.query,
        //Add or override the hash code with the hash.
        [group]: stringifyBase64(newQueryParamObject),
      };

      router.push(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true }
      );
    }
    setter(action);
  }

  return [getter, setterWrapper, name];
}
