import type { NotificationItemComponent } from "./NotificationItem";

import React from "react";
import { useRouter } from "next/router";
import { Typography, Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import useRecommended from "@/components/subscription/customize/hooks/useRecommended";
import NotificationItem from "./NotificationItem";
import { formCustomizePageHref } from "@/utils/formHref";

const EightyPercentBandwidthUsedNotification: NotificationItemComponent = ({
  item,
}) => {
  const router = useRouter();
  const { bandwidth } = useRecommended();

  const handleUpgrade = React.useCallback(() => {
    if (!router || !bandwidth) return;

    router.replace(
      formCustomizePageHref({
        source: "notification",
        showCustomizeSections: ["bandwidth-plan", "plan-terms"],
        ...bandwidth,
      })
    );
  }, [router, bandwidth]);

  return (
    <NotificationItem variant={!item.dismissed_at ? "warning" : "default"}>
      <NotificationItem.Title created_at={item.created_at}>
        <span>80% High bandwidth usage detected</span>
      </NotificationItem.Title>
      <NotificationItem.Content>
        <Typography variant="body2">
          Based on your current usage, you will likely exceed your bandwidth
          limit before end of the subscription period. Upgrade your bandwidth
          limit to avoid any interruptions in proxy activity.
        </Typography>
      </NotificationItem.Content>
      <NotificationItem.Actions {...item}>
        <Button
          size="small"
          variant={!item.dismissed_at ? "contained" : "outlined"}
          color={!item.dismissed_at ? "warning" : "primary"}
          disableElevation
          endIcon={<OpenInNewIcon fontSize="small" />}
          onClick={handleUpgrade}
        >
          <span>Upgrade</span>
        </Button>
      </NotificationItem.Actions>
    </NotificationItem>
  );
};

export default EightyPercentBandwidthUsedNotification;
