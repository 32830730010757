import { Button, Link, Box, IconButton } from "@mui/material";
import Alert from "@/components/alerts/Alert";
import Close from "@mui/icons-material/Close";
import WebshareApi from "../../api";
import { useRouter } from "next/router";
import useSWR from "swr";
import { NextLink } from "@/components/common/styles";
import { formBackendUrlString } from "../../utils/formURL";
import { fetcher } from "../../utils/fetcher";
import { SubuserType } from "@/types/SubuserTypes";
import { FlexBox, FlexCentered } from "../common/styles";
import { useSearchParams } from "next/navigation";

export default function SubUserBanner() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const paramsQuery = Object.fromEntries(searchParams);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { subuser, ...query } = paramsQuery;

  const { data } = useSWR<SubuserType>(
    subuser ? formBackendUrlString(`/subuser/${subuser}/`) : null,
    fetcher
  );

  const handleRemoveSubuserId = () => {
    WebshareApi.removeSubUserHeader();
    router.push({
      pathname: router.pathname,
      query,
    });
  };

  const isSubUserRoute =
    router.pathname.includes("/proxy/") || router.pathname.includes("/stats");

  if (!data || !data.id) return null;

  return (
    <Box sx={{ px: 5, pt: 5 }}>
      <FlexBox>
        <Alert
          action={
            isSubUserRoute ? (
              <Button
                disableElevation
                color="inherit"
                size="small"
                variant="contained"
                onClick={handleRemoveSubuserId}
              >
                Stop Viewing As This User
              </Button>
            ) : (
              <FlexCentered>
                <NextLink passHref href={`/subuser/user/${data.id}/detail`}>
                  <Button
                    disableElevation
                    color="inherit"
                    size="small"
                    variant="contained"
                  >
                    Back To Subuser’s Page
                  </Button>
                </NextLink>
                <IconButton
                  color="inherit"
                  sx={{ p: 0, ml: 2 }}
                  onClick={handleRemoveSubuserId}
                >
                  <Close fontSize="small" />
                </IconButton>
              </FlexCentered>
            )
          }
          severity={isSubUserRoute ? "info" : "warning"}
        >
          {isSubUserRoute ? (
            <>
              You are viewing as{" "}
              <Link
                component={NextLink}
                passHref
                href={`/subuser/user/${data.id}/detail`}
              >
                {data.label} (#{data.id})
              </Link>
              .
            </>
          ) : (
            "View as sub-user is not available on this page. "
          )}
        </Alert>
      </FlexBox>
    </Box>
  );
}
