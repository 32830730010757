import { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import useSubscriptionStats from "@/hooks/useSubscriptionStats";
import { mbToBytes } from "@/utils/conversionUtils";
import BaseModal from "./BaseModal";

interface VerifyEmailModalProps {
  resendAction: () => void;
  openEmailLink?: string;
}

export default function VerifyEmailExceedingBandwidthModal({
  openEmailLink,
  resendAction,
}: VerifyEmailModalProps) {
  const [popupOpened, setPopupOpened] = useState(true);
  const aggregateStatsData = useSubscriptionStats();

  if (!aggregateStatsData) {
    return null;
  }

  const { bandwidth_total } = aggregateStatsData;
  if (!popupOpened || bandwidth_total < mbToBytes(10)) {
    return null;
  }

  return (
    <BaseModal
      setOpen={(open) => setPopupOpened(open)}
      open={popupOpened}
      maxWidth="sm"
      title="Check your inbox to verify your email"
    >
      <Typography variant="body1">
        We have sent you an activation email. Please click the link within the
        email to ensure that your bandwidth usage is not limited.
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "right",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Button
          data-testid="resend-button"
          variant="text"
          color="primary"
          onClick={resendAction}
          sx={{ mr: 2 }}
        >
          Resend Email
        </Button>
        {openEmailLink ? (
          <Button
            data-testid="verify-button"
            variant="contained"
            color="primary"
            component="a"
            href={openEmailLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Verify Email
          </Button>
        ) : null}
      </Box>
    </BaseModal>
  );
}
