import WebshareApi from "../api";
const webshareApi = WebshareApi.getInstance();

export type FetcherParamType = string[] | string;

export const fetcher = (fetcherParams: FetcherParamType) => {
  const url: string = Array.isArray(fetcherParams)
    ? fetcherParams[0]
    : fetcherParams;
  return webshareApi.get(url).then((res) => res.data);
};

export const subUserFetcher = (fetcherParams: FetcherParamType) => {
  // This can't be covered by typescript because swr requires the fetcher to be
  // a function that accepts a string[] or string
  if (!Array.isArray(fetcherParams)) {
    throw new Error(
      "subUserFetcher expects an array of strings as a parameter"
    );
  }
  const url: string = fetcherParams[0];
  const subUserId: string = fetcherParams[1];
  const token = WebshareApi.getAuthToken();
  return webshareApi
    .get(url, {
      headers: { Authorization: `Token ${token}`, "X-Subuser": subUserId },
    })
    .then((res) => res.data);
};
