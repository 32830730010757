import * as React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { NavItemType } from "./types";
import NavItem from "./NavItem";

export default function DrawerNonCollapseWithItems({
  title,
  items,
  autoCloseDrawer,
  index,
}: {
  title: React.ReactNode;
  items: NavItemType[];
  autoCloseDrawer: () => void;
  index: number;
}) {
  return (
    <Box key={`NonCollapseGroup_${index}`}>
      <Box padding="8px 8px 4px 8px">
        <Typography
          fontWeight={800}
          fontSize="13px"
          sx={{ textTransform: "uppercase" }}
        >
          {title}
        </Typography>
      </Box>
      {items.map((item, subIndex) => (
        <NavItem
          key={`NonCollapseItem_${subIndex}`}
          {...item}
          autoCloseDrawer={autoCloseDrawer}
          variant="button"
        />
      ))}
      <Divider sx={{ mx: 2, my: 2 }} />
    </Box>
  );
}
