import {
  Alert,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import { useCallback } from "react";
import Image from "next/image";
import { ChevronRight } from "@mui/icons-material";
import useIsFlagVariantTest from "@/hooks/useIsFlagVariantTest";
import { getCookie } from "cookies-next";

export const CHROME_WEBSTORE_LINK =
  "https://chromewebstore.google.com/detail/webshare/bdokeillmfmaogjpficejjcjekcflkdh";

export default function ChromeExtensionBanner() {
  const inExtensionBannerExp = useIsFlagVariantTest(
    "dashboard_add_install_extension_widget"
  );

  const hasExtension = !!getCookie("webshare_extension_version");

  const router = useRouter();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const onClickAction = useCallback(() => {
    window.open(CHROME_WEBSTORE_LINK, "_blank");
  }, []);

  const shouldHideBanner =
    router.pathname.includes("subscription/pending") ||
    inExtensionBannerExp ||
    hasExtension;

  return shouldHideBanner ? null : (
    <>
      <Alert
        severity="info"
        icon={false}
        sx={{
          py: 1,
          px: { xs: 3, md: 5 },
          display: "flex",
          justifyContent: { xs: "center" },
        }}
        onClick={onClickAction}
        action={
          isSmall ? null : (
            <Button
              disableElevation
              color="info"
              size="small"
              variant="contained"
              onClick={onClickAction}
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <>
                <span>Add Extension For Free</span>
                <ChevronRight />
              </>
            </Button>
          )
        }
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          {isSmall ? null : (
            <Image
              alt={"image"}
              src={`/chrome-extension-icon.svg`}
              width={24}
              height={24}
            />
          )}

          <Typography fontWeight={"bold"} variant={isSmall ? "body2" : "body1"}>
            Free Proxy Extension for Chrome by Webshare
          </Typography>
        </Stack>
      </Alert>
    </>
  );
}
