import type { NotificationItemComponent } from "./NotificationItem";
import React from "react";
import { useRouter } from "next/router";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button } from "@mui/material";
import NotificationItem from "./NotificationItem";
import { DEFAULT_CHECKOUT_PATHNAME } from "@/utils/formHref";

const CCExpiringSoonNotification: NotificationItemComponent = ({ item }) => {
  const router = useRouter();

  const handleRenew = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    router.push({
      pathname: DEFAULT_CHECKOUT_PATHNAME,
      query: {
        source: "cc-expiring-soon-notification",
        renew: true,
      },
    });
  };

  return (
    <NotificationItem variant={!item.dismissed_at ? "error" : "default"}>
      <NotificationItem.Title created_at={item.created_at}>
        Your payment method is expiring
      </NotificationItem.Title>
      <NotificationItem.Content>
        Your payment method expires in 1 month. Renew your plan now to avoid
        proxy activity interruptions.
      </NotificationItem.Content>
      <NotificationItem.Actions {...item}>
        <Button
          onClick={handleRenew}
          size="small"
          variant={!item.dismissed_at ? "contained" : "outlined"}
          color={!item.dismissed_at ? "error" : "primary"}
          disableElevation
          endIcon={<OpenInNewIcon fontSize="small" />}
        >
          Renew Plan
        </Button>
      </NotificationItem.Actions>
    </NotificationItem>
  );
};

export default CCExpiringSoonNotification;
