import type { AlertColor, AlertProps } from "@mui/material/Alert";
import React from "react";
import { Alert as MuiAlert } from "@mui/material";
import { Palette, useTheme, alpha } from "@mui/material/styles";

const Alert: React.FC<AlertProps & { mode?: Palette["mode"] }> = (props) => {
  const theme = useTheme();
  const isDarkMode = props.mode ?? theme.palette.mode === "dark";
  return (
    <MuiAlert
      {...props}
      variant="standard"
      severity={props.severity ?? "info"}
      sx={{
        ...props.sx,

        ".MuiButton-colorInherit": {
          color: ({ palette }) =>
            isDarkMode ? palette.common.black : palette.common.white,

          backgroundColor: ({ palette }) =>
            palette[props.severity as AlertColor].main,

          "&:hover": {
            backgroundColor: ({ palette }) =>
              alpha(palette[props.severity as AlertColor].main, 0.9),
          },
        },
      }}
    />
  );
};

export default Alert;
