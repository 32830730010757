import type { PaletteMode } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { Shadows } from "@mui/material/styles/shadows";
import { useMemo } from "react";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    tertiary: Palette["primary"];
    google: Palette["google"];
    paypal: Palette["paypal"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
    tertiary?: PaletteOptions["primary"];
    google: PaletteOptions["google"];
    paypal: PaletteOptions["paypal"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
    tertiary: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    neutral: true;
    tertiary: true;
  }
}

export const lightPaletteOptions = {
  background: {
    default: "#F5F5F5",
  },
  primary: {
    main: "#1BB394",
    dark: "#008266",
    light: "#60E6C4",
    contrastText: "#FFFFFF",
  },
  //Unused
  secondary: {
    main: "#B243C4",
    dark: "#8216AC",
    light: "#C166CF",
    contrastText: "#FFFFFF",
  },
  info: {
    main: "#1976D2",
    dark: "#0D47A1",
    light: "#2196F3",
    contrastText: "#FFFFFF",
  },
  warning: {
    main: "#FC9800",
    dark: "#EF6C00",
    light: "#FFB74D",
    contrastText: "#FFFFFF",
  },
  error: {
    main: "#F44336",
    dark: "#C62828",
    light: "#EF9A9A",
    contrastText: "#FFFFFF",
  },
  success: {
    main: "#4CAF50",
    dark: "#2E7D32",
    light: "#66BB6A",
    contrastText: "#FFFFFF",
  },
  grey: {
    50: "#FAFAFA",
    100: "#F5F5F5",
    200: "#EEEEEE",
    300: "#E0E0E0",
    400: "#BDBDBD",
    500: "#9E9E9E",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
  },
  neutral: {
    main: "#616161",
    contrastText: "#FFFFFF",
  },
  //Same as mui default
  text: {
    secondary: "rgba(0, 0, 0, 0.6)",
  },
  //Same as mui default
  action: {
    active: "rgba(0, 0, 0, 0.54)",
    main: "#e8e8e8",
    dark: "#cbcbcb",
    contrastText: "#1F1F1F",
  },
  google: {
    blue: "#4285F4",
    green: "#34A853",
    red: "#EA4335",
    yellow: "#FBBC04",
    blueShadow: "#BED2F5",
    blueDark: "#3467D6",
  },
  paypal: {
    blue: "#113984",
    blueLight: "#009EE3",
    goldDefault: "#FDC438",
    goldHover: "#E6B739",
  },
};

export const darkPaletteOptions = {
  background: {
    paper: "#121212",
    default: "#121212",
    defaultChannel: "18 18 18",
  },
  primary: {
    main: "#60E6C4",
    dark: "#46AD93",
    light: "#60E6C4",
    contrastText: "#1F1F1F",
  },
  secondary: {
    main: "#D393DD",
    dark: "#C166CF",
    light: "#E4BEEA",
    contrastText: "#1F1F1F",
  },
  warning: {
    main: "#FDCC81",
    dark: "#F57C00",
    light: "#FFB74D",
    contrastText: "#202020",
  },
  success: {
    main: "#A5D6A7",
    dark: "#388E3C",
    light: "#81C784",
    contrastText: "#000000",
  },
  info: {
    main: "#90CAF9",
    dark: "#1488D1",
    light: "#4FC3F7",
    contrastText: "#000000",
  },
  error: {
    main: "#EF9A9A",
    dark: "#D32F2F",
    light: "#E57373",
    contrastText: "#000000",
  },
  grey: {
    50: "#212121",
    100: "#2B2B2B",
    200: "#353535",
    300: "#3F3F3F",
    400: "#535353",
    500: "#676767",
    600: "#999999",
    700: "#B7B7B7",
    800: "#DFDFDF",
    900: "#FDFDFD",
  },

  neutral: {
    main: "#D9D9D9",
    dark: "#9C9C9C",
    light: "#F5F5F5",
    contrastText: "#1F1F1F",
  },
  //Same as mui default
  text: {
    secondary: "rgba(255, 255, 255, 0.7)",
  },
  //Same as mui default
  action: {
    active: "#fff",
    main: "#313131",
    dark: "#616161",
    contrastText: "#fff",
  },
  google: {
    blue: "#4285F4",
    green: "#34A853",
    red: "#EA4335",
    yellow: "#FBBC04",
    blueShadow: "#BED2F5",
    blueDark: "#3467D6",
  },
  paypal: {
    blue: "#2E4FC2",
    goldDefault: "#FDC438",
    goldHover: "#E6B739",
  },
};

export const defaultShadows = createTheme({}).shadows;

export const lightShadows: Shadows = [
  ...(defaultShadows.map((shadow, i) => {
    //Make first elevation one for cards.
    if (i === 1) {
      return "none";
    }
    if (i === 2) {
      return "0px 12px 24px -4px rgba(158, 158, 158, 0.25)";
    }
    return shadow;
  }) as Shadows),
];

export const themeOptions = {
  // spacing: [0, 4, 8, 16, 24, 32, 64],
  spacing: (factor: number) => {
    const values = [0, 4, 8, 16, 24, 32, 64, 128, 256, 512, 1024, 2048];
    const index = Math.floor(factor);
    const currentSpace = values[index];
    const nextSpace = values[index + 1] || currentSpace * 2;
    const space = currentSpace + (nextSpace - currentSpace) * (factor - index);
    return `${space}px`;
  },
  typography: {
    body2: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "20px",
      letterSpacing: "0.17px",
    },
    h5: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: "32px",
      letterSpacing: "0.17px",
    },
    button: {
      textTransform: "none" as const,
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        elevation: 1,
      },
    },
  },
};

export const getDesignTokens = (mode: PaletteMode) => ({
  ...themeOptions,
  // Overriding components theme defaults
  ...(mode === "light"
    ? {
        components: {
          MuiSelect: {
            defaultProps: {
              sx: {
                backgroundColor: "#fff",
              },
            },
          },
        },
      }
    : {
        components: {
          MuiSelect: {
            defaultProps: {
              sx: {
                backgroundColor: "#1F1F1F",
              },
            },
          },
        },
      }),
  palette:
    mode === "light"
      ? { mode, ...lightPaletteOptions }
      : { mode, ...darkPaletteOptions },
  shadows: mode === "dark" ? defaultShadows : lightShadows,
});

export function useAppTheme(mode: PaletteMode) {
  return useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
}
