export const getFromStorage = (key: string) => {
  if (typeof window === "undefined") return;
  return localStorage?.getItem(key);
};

export const getObjectFromStorage = <T>(key: string): T | undefined => {
  const value = getFromStorage(key);
  if (value) {
    let obj;

    try {
      obj = JSON.parse(value);

      return obj;
    } catch (ex) {}
  }

  return undefined;
};

export const setToStorage = (key: string, value: string) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, value);
  }
};

export const setObjectToStorage = (key: string, value: unknown) => {
  setToStorage(key, JSON.stringify(value));
};

export const removeFromStorage = (key: string) => {
  localStorage.removeItem(key);
};
