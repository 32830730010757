import { useCallback, useEffect, useState } from "react";
import WebshareApi from "../api";
import { useRouter } from "next/router";
import { deleteToken, getToken, setTrackingIdCookie } from "./authentication";
import React from "react";
import { formBackendUrlString } from "../utils/formURL";
import { PlanType } from "../types/PlanTypes";
import useSWR from "swr";
import { SubscriptionType } from "../types/SubscriptionTypes";
import { fetcher } from "../utils/fetcher";
import { AuthContext } from "./RouteGuards";
import { SessionMutateContext } from "../context/SessionMutateContext";
import { useFederatedUser } from "./federation";
import useSubuser from "@/hooks/useSubuser";
import useProfile from "@/hooks/useProfile";
import LoadingView from "@/components/common/LoadingView";
import useAccountActivation from "@/hooks/useAccountActivation";
import timezoneUtils from "@/utils/TimezoneUtils";
/* eslint-disable-next-line no-restricted-imports */
import { IntercomProvider } from "react-use-intercom";
import { INTERCOM_APP_ID } from "@/config/constants";
import { patchProxyConfig } from "@/api/proxy";

const loggedoutPaths = [
  "/login",
  "/register",
  "/activation/[activationtoken]/confirm",
  "/resetpassword/[passwordresettoken]/confirm",
];
const publicPaths = [
  ...loggedoutPaths,
  "/_error",
  "/403",
  "/404",
  "/logout",
  "/login/forgotpassword", // Forget password page should be reached even if the user is signed in
  "/extension-captcha-challenge",
  "/accountsuspension",
  "/profile/deleteaccount/deleted",
  "/express-checkout",
  "/auth/google",
];

type GuardProps = React.PropsWithChildren<{}>;

export default function RouteGuard({ children }: GuardProps) {
  const { deleteFederationToken } = useFederatedUser();
  const { mutateSubuser } = useSubuser();
  const { mutateProfile, profile, profileError, isProfileLoading } =
    useProfile();
  const router = useRouter();

  //Called by login, register and logout functions.
  const [sessionToken, setSessionToken] = useState<string | undefined>(
    getToken()
  );
  const { activationStatus, mutateActivationStatus } =
    useAccountActivation(sessionToken);
  const [isTimeZoneSet, setIsTimeZoneSet] = useState(false);

  if (sessionToken) {
    WebshareApi.setAuthToken(sessionToken);
  }

  //Re-rendered on each call to SessionMutateContext.setSetSessinToken()s
  //Uses sesseionToken based conditional fetching with extra token argument.
  //Fetch conditionally https://swr.vercel.app/docs/conditional-fetching#conditional
  //Multiple arguments https://swr.vercel.app/docs/arguments#multiple-arguments
  const {
    data: subscription,
    mutate: mutateSubscription,
    error: subscriptionError,
    isLoading: isSubscriptionLoading,
  } = useSWR<SubscriptionType>(
    sessionToken
      ? [formBackendUrlString(`/subscription/`), sessionToken]
      : null,
    fetcher
  );
  const {
    data: plan,
    mutate: mutatePlan,
    error: planError,
    isLoading: isPlanLoading,
  } = useSWR<PlanType>(
    subscription && sessionToken
      ? [
          formBackendUrlString(`/subscription/plan/${subscription.plan}/`),
          sessionToken,
        ]
      : null,
    fetcher
  );

  const mutateAll = async () => {
    //Calling subscription will also cause plan to mutate due to dependant id.
    await mutateSubscription();
    await mutateSubuser();
    await patchProxyConfig({ ip_authorization_country_codes: null });
  };

  const authCheck = useCallback(() => {
    const url = router.asPath;
    const token = getToken();
    const urlAndParams = url.split("?");
    const path = urlAndParams[0];

    if (!router.isReady) return;

    // Getting SWR errors
    if (token && (profileError || subscriptionError)) {
      if (
        profileError?.response?.status === 403 ||
        subscriptionError?.response?.status === 403 ||
        planError?.response?.status === 403
      ) {
        if (profileError?.response?.data?.code === "account_deleted") {
          if (
            !!WebshareApi.getFederationToken() &&
            !router.pathname.includes("403")
          ) {
            router.push({
              pathname: "/403",
              query: { errorMessage: profileError?.response?.data?.detail },
            });
          } else if (!WebshareApi.getFederationToken()) {
            deleteToken();
            setSessionToken(undefined);
            deleteFederationToken();
            router.push({ pathname: "/profile/deleteaccount/deleted" });
          }
        } else if (
          profileError?.response?.data?.code === "account_suspended" &&
          !router.pathname.includes("accountsuspension")
        ) {
          router.push("/accountsuspension");
        } else {
          if (
            !router.pathname.includes("403") &&
            !router.pathname.includes("accountsuspension")
          ) {
            const errorMessage = profileError?.response?.data?.detail;
            if (errorMessage) {
              router.push({
                pathname: "/403",
                query: { errorMessage },
              });
            }
          }
        }
      } else {
        //If there is a 401 error due to token logout the user.
        if (
          profileError?.response?.status === 401 ||
          subscriptionError?.response?.status === 401 ||
          planError?.response?.status === 401
        ) {
          deleteToken();
          setSessionToken(undefined);
          deleteFederationToken();
          !router.pathname?.includes("/login") &&
            router.push(
              {
                pathname: "/login",
                query: { next: router.asPath },
              },
              undefined,
              { shallow: true }
            );
        }
      }
    }

    //Redirect logged in user visiting /login.
    if (token && loggedoutPaths.includes(path)) {
      const queryParams = urlAndParams[1]?.split("&");
      const next = queryParams
        ?.filter((item) => item.includes("next="))[0]
        ?.split("=")[1];

      router.push(next ? decodeURIComponent(next) : "/proxy/list");
    }

    // No token but is a loggeout path.
    // loggedout paths with dynamic parameters should use `router.pathname`
    // instead of path, otherwise it will not any value in the loggedPaths array
    if (!token && loggedoutPaths.includes(router.pathname)) {
      return;
    }

    // redirect to login page if accessing a private page and not logged in
    if (!token && !publicPaths.includes(path)) {
      setSessionToken(undefined);
      router.push(
        {
          pathname: "/login",
          query: { next: router.asPath },
        },
        undefined,
        { shallow: true }
      );
      return;
    }
  }, [
    deleteFederationToken,
    planError,
    profileError,
    router,
    subscriptionError,
  ]);

  //TODO: Test running authCheck without events.
  useEffect(() => {
    setSessionToken(getToken());
    authCheck();
  }, [authCheck]);

  useEffect(() => {
    if (profile) {
      timezoneUtils.setTimezone(profile.timezone);
      setIsTimeZoneSet(true);
      // Init tracking
      const trackingId = profile?.tracking_id; //_tid may become different than UserProfile.tracking_id after some time.
      if (trackingId) {
        setTrackingIdCookie(trackingId);
      }
    }
  }, [profile]);

  return !!(
    profile &&
    subscription &&
    plan &&
    timezoneUtils.getTimezone() &&
    isTimeZoneSet
  ) || publicPaths.includes(router.pathname) ? (
    <SessionMutateContext.Provider
      value={{
        mutatePlan,
        mutateProfile,
        mutateSubscription,
        mutateAll,
        setSessionToken,
        mutateActivationStatus,
      }}
    >
      <AuthContext.Provider
        value={{
          isLoggedIn: !!sessionToken && !!(profile && subscription && plan),
          isLoading: isSubscriptionLoading || isPlanLoading || isProfileLoading,
          profile,
          subscription,
          plan,
          activationStatus,
        }}
      >
        <IntercomProvider
          appId={INTERCOM_APP_ID}
          autoBoot
          autoBootProps={{
            ...(profile && {
              userId: profile.tracking_id,
              userHash: profile.intercom_signature,
              email: profile.email,
              name: `${profile.first_name} ${profile.last_name}`,
            }),
            hideDefaultLauncher: true,
          }}
        >
          {children}
        </IntercomProvider>
      </AuthContext.Provider>
    </SessionMutateContext.Provider>
  ) : (
    <SessionMutateContext.Provider
      value={{
        mutatePlan,
        mutateProfile,
        mutateSubscription,
        mutateAll,
        setSessionToken,
      }}
    >
      <LoadingView />
    </SessionMutateContext.Provider>
  );
}
