var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"YIC3f52VhVg71qIQX9TkF"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
// eslint-disable-next-line no-restricted-imports
import posthog from "posthog-js";

/* disable errors originating from injected scripts such as Google Tag Manager */

// keeping this here for testing

function isInjectedCode(event: Sentry.Event | undefined): boolean {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

  if (!frames || frames.length === 0) return false;

  const firstFrame = frames[0];
  if (
    firstFrame.filename?.includes("<anonymous>") ||
    firstFrame.filename?.includes("<unknown>") ||
    firstFrame.filename?.includes("<frame-modern>") ||
    firstFrame.filename?.includes("widget-v2")
  ) {
    return true;
  }

  const lastFrame = frames[frames.length - 1];
  if (
    typeof lastFrame.filename === "string" &&
    (lastFrame.filename === window.location.pathname ||
      (lastFrame.filename.startsWith(window.location.origin) &&
        !lastFrame.filename.includes("/static/")))
  ) {
    return true;
  }

  if (
    frames.some(
      (frame) =>
        typeof frame.filename === "string" &&
        (frame.filename.startsWith("https://www.googletagmanager.com") ||
          frame.filename.startsWith("https://googleads."))
    )
  ) {
    return true;
  }

  return false;
}

if (process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: "https://465fe54d3cae462d8d002e6a525fbf92@o73057.ingest.sentry.io/4503931616100352",
    release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    tracesSampleRate: 0,
    beforeSend(event) {
      if (isInjectedCode(event)) {
        return null;
      }
      return event;
    },
    ignoreErrors: [
      // Random plugins/extensions
      "removeChild",
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "i.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      "Script error.",
      // Avast extension error
      "_avast_submit",
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
      "ResizeObserver loop limit exceeded",
      "Cannot redefine property: ethereum",
      "Cannot redefine property: solana",
      "Blocked a frame with origin",
      "msDiscoverChatAvailable",
      "ResizeObserver",
      "NetFunnel is not defined",
      "QuotaExceededError",
      "Talisman extension",
      "win is not defined",
      "redeclaration of const hideMyLocation",
      "TypeError: Failed to fetch",
      "Can't find variable: zaloJSV2",
      "TypeError: Properties can only be defined on Objects.",
      'Permission denied to access property "unlock"',
      "uv.handler",
      "removeAllUnloadsIframes",
      "<anonymous>",
      "<unknown>",
      "Can't find variable: unsafeWindow",
      "getReadModeConfig",
      "getReadModeExtract",
      "getReadModeRender",
      "nexusWebSocket",
      "transover-type-and-translate-popup",
      "transover-popup",
      "grecaptcha",
      "popperjs",
      "missing ) after argument list",
      "NotFoundError",
      /SecurityError: Permission denied to access property "navigator" on cross-origin object/,
      /SecurityError: Permission denied to access property "227g" on cross-origin object/,
      /NotAllowedError: Clipboard write was blocked due to lack of user activation/,
      /SecurityError: The operation is insecure/,
      /SecurityError: Permission denied to access property "g1d5" on cross-origin object/,
      /SecurityError: Permission denied to define property "Date" on cross-origin object/,
      /ReferenceError: Can't find variable: __torBrowser/,
      /TypeError: can't redefine non-configurable property "solana"/,
      /TypeError: can't redefine non-configurable property "ethereum"/,
      /SecurityError: Permission denied to access property "pfv" on cross-origin object/,
      /TypeError: Cannot redefine property: userAgent/,
      /TypeError: null is not an object \(evaluating 'parent.document'\)/,
      /Error: Menu caption text is required!/,
      /TypeError: selectElement is null/,
      /ReferenceError: Can't find variable: __endless/,
      /TypeError: Cannot read properties of null (reading 'offsetTop')/,
      /Error: Cancel rendering route/,
      /TypeError: native is undefined/,
      /TypeError: WeakMap key undefined must be an object or an unregistered symbol/,
      /TypeError: Property descriptor must be an object, got undefined/,
      /ReferenceError: tgetT is not defined/,
      /TypeError: window.ethereum.setConfig is not a function/,
      /TypeError: Cannot read properties of undefined (reading 'sendMessage')/,
      /TypeError: undefined is not an object \(evaluating 'window.webkit.messageHandlers[e].postMessage'\)/,
      /Error: Permission denied to access property "__SENTRY_LOADER__"/,
      /Error: bb/,
      /Error: frame window is not ready/,
      /TypeError: 'TRACE' HTTP method is unsupported./,
      /TypeError: can't redefine non-configurable property "adoptedStyleSheets"/,
      /TypeError: setting getter-only property "chainId"/,
      /Error: Permission denied to access property "getAttribute"/, // https://github.com/mui/material-ui/issues/41514
      /TypeError: Cannot read properties of undefined \(reading 'Symbol\(\)'\)/,
      /TypeError: Blob is not a constructor/,
      /Error: gb/,
      /SecurityError: Permission denied to access property "3x5v" on cross-origin object/,
      /<unknown>/,
      /SyntaxError: Unexpected end of input/,
      /SyntaxError: No identifiers allowed directly after numeric literal/,
      /TypeError: b.focus is not a function/,
      /Error: Invalid call to runtime.sendMessage(). Tab not found./,
      /TypeError: oldDescriptor is undefined/,
      /AxiosError: timeout exceeded/,
      /AxiosError: Network Error/,
      /NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node./,
      /NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node./,
      /NotFoundError: Node.removeChild: The node to be removed is not a child of this node/,
      /TypeError: can't access property "dropEffect", i.dataTransfer is null/,
      /SyntaxError: Identifier 'wsAllowlisted' has already been declared/,
      /TypeError: ReadableStream is locked/,
      /Error: Cannot parse given Error object/,
      /TypeError: i.dataTransfer is null/,
    ],
    denyUrls: [
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /^safari-web-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      "recaptcha/releases",
      "userscript",
      "translate_http",
      "maps/api/js",
      "recorder-v2",
      "recorder-v2.js",
      "recorder.js",
      "user-guiding",
      "posthog-js",
      "useGridTreeDataPreProcessors.js",
      "inject.js",
      "ChartUtils",
      "d3-scale",
      "_ssgManifest",
      "ucbrowser",
      "vim.js",
      "popperjs",
      "widget-v2",
    ],
    integrations: [
      new posthog.SentryIntegration(
        posthog,
        "webshare-proxy",
        4503931616100352
      ),
    ],
  });
}
