import { Dialog } from "@mui/material";
import InfoCard from "@/ui/InfoCard";
import type { LottieProps } from "react-lottie-player";
import DialogCloseButton from "./DialogCloseButton";

export default function InfoCardDialog({
  onClose,
  open,
  title,
  description,
  lottieOptions,
  imageSrc,
  imageAlt,
  children,
}: {
  onClose: () => void;
  open: boolean;
  title: string;
  description: string;
  lottieOptions?: LottieProps;
  imageAlt?: string;
  imageSrc?: string;
  children?: React.ReactNode;
}) {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      keepMounted={false}
      onClose={onClose}
    >
      <DialogCloseButton onClick={onClose} />
      <InfoCard
        title={title}
        description={description}
        lottieOptions={lottieOptions}
        imageSrc={imageSrc}
        imageAlt={imageAlt}
      >
        {children}
      </InfoCard>
    </Dialog>
  );
}
