import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { deleteCookie } from "cookies-next";
import { logout } from "@/api/auth";
import { useSessionMutate } from "@/security/RouteGuards";
import { deleteToken } from "@/security/authentication";
import { captureMessage } from "@sentry/nextjs";
import { useFederatedUser } from "@/security/federation";
import webshareAPI from "../api";
import { LocalStorageKeysThatShouldNotBePersist } from "@/config/localStorageKeys";
import { removeFromStorage } from "@/utils/localStorageUtils";
import useIntercom from "./useIntercom";

interface IProps {
  shouldRedirectToLogin?: boolean;
}

export default function useAccountLogout(props?: IProps) {
  const shouldRedirect =
    typeof props?.shouldRedirectToLogin === "undefined" ?? true;

  const router = useRouter();
  const posthog = usePostHog();
  const { deleteFederationToken, federationToken } = useFederatedUser();
  const { setSessionToken } = useSessionMutate();
  const { hardShutdown } = useIntercom();

  return async function () {
    try {
      await logout();
      posthog && posthog.reset(true);
      deleteCookie("_tid");
      hardShutdown && hardShutdown();
    } catch (err) {
      captureMessage(`logout error ${err}`, "info");
    } finally {
      deleteToken();
      if (setSessionToken) {
        setSessionToken(undefined);
      }
      webshareAPI.removeAuthToken();
      if (federationToken) {
        deleteFederationToken();
      }
      LocalStorageKeysThatShouldNotBePersist.forEach((key) => {
        removeFromStorage(key);
      });
      shouldRedirect && router.push("/login");
    }
  };
}
