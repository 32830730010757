import * as React from "react";
import { useRouter } from "next/router";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Collapse, ListItemButton } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { NavItemType } from "./types";
import NavItem from "./NavItem";
import checkIfItemSelected from "./utils/checkIfItemSelected";

interface DrawerCollapseProps {
  icon: React.ReactNode;
  title: string;
  titleComponent?: React.ReactNode;
  items: NavItemType[];
  isOpen?: boolean;
  autoCloseDrawer: () => void;
}

export default function DrawerCollapse({
  icon,
  title,
  titleComponent,
  items,
  isOpen,
  autoCloseDrawer,
}: DrawerCollapseProps) {
  const router = useRouter();
  const [open, setOpen] = React.useState(isOpen ?? false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        key={"key-list-item-" + title}
        onClick={handleClick}
        data-testid={`DrawerCollapseLink_${title}`}
        selected={items?.some((item) => checkIfItemSelected(router, item))}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={titleComponent ?? title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        key={"key-list-item-collapse-" + title}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        {items.map((item, index) => (
          <NavItem
            key={`NavItemType_${index}`}
            {...item}
            autoCloseDrawer={autoCloseDrawer}
            icon={undefined}
            variant="list-item"
          />
        ))}
      </Collapse>
    </>
  );
}
