import useTracking from "@/tracking/useTracking";
import Drawer from "./Drawer";
import { useSession } from "@/security/RouteGuards";
import { useEffect } from "react";
import useIntercom from "@/hooks/useIntercom";

type DashboardLayoutProps = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: DashboardLayoutProps) {
  useTracking();

  const { profile } = useSession();
  const { update } = useIntercom();

  useEffect(() => {
    if (window && profile) {
      update({
        userId: profile.tracking_id,
        userHash: profile.intercom_signature,
        email: profile.email,
        name: `${profile.first_name} ${profile.last_name}`,
      });
    }
  }, [profile, update]);

  return profile ? <Drawer>{children}</Drawer> : <> {children} </>;
}
