import { Box, Button, Typography } from "@mui/material";
import ArrowForwardFilledIcon from "@mui/icons-material/ArrowForward";
import { FlexBetween, FlexCentered } from "../common/styles";
import { Header } from "./commonStyles";
import { NextLink } from "@/components/common/styles";
import { useRouter } from "next/router";
import useIsFlagEnabled from "@/hooks/useIsFlagEnabled";
import {
  DEFAULT_CUSTOMIZE_PATHNAME,
  formCustomizePageHref,
} from "@/utils/formHref";
import { ColoredCard } from "@/ui/ColoredCard";

export default function BlackFridayBanner() {
  const route = useRouter();

  if (!useIsFlagEnabled("black_friday_2022")) return null;

  return (
    <ColoredCard type="warning" sx={{ padding: 2 }}>
      <Box sx={{ px: 3, py: 2 }}>
        {route.pathname.includes(DEFAULT_CUSTOMIZE_PATHNAME) ? (
          <FlexCentered>
            <Header variant="body1" fontWeight="bold" type="warning">
              Black Friday 20% OFF ALL PLANS &#127881;
            </Header>
          </FlexCentered>
        ) : (
          <FlexBetween>
            <Box>
              <Header variant="body1" fontWeight="bold" type="warning">
                Our Black Friday Deal is here &#127881; - Get 20% OFF
              </Header>
              <Typography variant="body2" color="text.secondary">
                Enjoy 20% off for the first month by upgrading plan before next
                Monday!
              </Typography>
            </Box>

            <NextLink
              href={formCustomizePageHref({
                source: "black-friday-2022-banner",
              })}
              passHref
            >
              <Button
                variant="contained"
                color="warning"
                endIcon={<ArrowForwardFilledIcon />}
              >
                Get 20% Off
              </Button>
            </NextLink>
          </FlexBetween>
        )}
      </Box>
    </ColoredCard>
  );
}
