import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import CustomSnackbar from "../components/snakcbars/CustomSnackBar";

interface ISnackbarErrors {
  setSnackbarErrorMessage: Dispatch<SetStateAction<string | null>>;
}

const SnackbarErrorsDefautValue: ISnackbarErrors = {
  setSnackbarErrorMessage: () => {},
};

export const SnackbarErrorsContext = createContext<ISnackbarErrors>(
  SnackbarErrorsDefautValue
);

interface IProps {
  children: ReactNode;
}

export const SnackbarErrorsProvider = ({ children }: IProps) => {
  const [nonFieldErrors, setSnackbarErrorMessage] = useState<null | string>(
    null
  );

  return (
    <SnackbarErrorsContext.Provider value={{ setSnackbarErrorMessage }}>
      <CustomSnackbar
        autoHideDuration={undefined}
        isError
        snackbarOpen={!!nonFieldErrors}
        message={nonFieldErrors ?? ""}
        setSnackbarOpen={() => {
          setSnackbarErrorMessage(null);
        }}
      />
      {children}
    </SnackbarErrorsContext.Provider>
  );
};
