import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button } from "@mui/material";
import NotificationItem, {
  NotificationItemComponent,
} from "./NotificationItem";
import Link from "next/link";

const QuestionIsAddedNotification: NotificationItemComponent = ({ item }) => {
  return (
    <NotificationItem variant={!item.dismissed_at ? "warning" : "default"}>
      <NotificationItem.Title created_at={item.created_at}>
        Additional Information Required
      </NotificationItem.Title>
      <NotificationItem.Content>
        We need additional information to complete the verification.
      </NotificationItem.Content>
      <NotificationItem.Actions {...item}>
        <Button
          size="small"
          variant={!item.dismissed_at ? "contained" : "outlined"}
          color={!item.dismissed_at ? "warning" : "primary"}
          disableElevation
          endIcon={<OpenInNewIcon fontSize="small" />}
          href="accountverificationforms"
          LinkComponent={Link}
        >
          View Verification
        </Button>
      </NotificationItem.Actions>
    </NotificationItem>
  );
};
export default QuestionIsAddedNotification;
