import Box from "@mui/material/Box";

import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpArticle from "../buttons/HelpArticle";
import { articles } from "@/utils/intercom";
import { DialogContentWithInput } from "@/ui/dialogs/DialogContentWithInput";
import useModalTracking from "@/hooks/useModalTracking";

interface BaseModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  title: string;
  maxWidth: Breakpoint | false;
  article?: keyof typeof articles;
  dialogAction?: React.ReactNode;
}

export default function BaseModal({
  open,
  setOpen,
  children,
  title,
  maxWidth,
  article,
  dialogAction,
}: BaseModalProps) {
  const handleClose = () => setOpen(false);

  useModalTracking({ modalName: title, trackOpenVariable: open });

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={maxWidth}
      keepMounted={false}
    >
      <DialogTitle id="id">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Stack direction="row" alignItems="center">
              {title}
              {article ? <HelpArticle id={article} /> : null}
            </Stack>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContentWithInput dividers>{children}</DialogContentWithInput>
      {dialogAction ? <DialogActions>{dialogAction}</DialogActions> : null}
    </Dialog>
  );
}
