export const articles = {
  accountCredits: 8370536,
  activePaymentMethod: 8370533,
  asn: 8375289,
  authenticationMethod: 8375312,
  automaticRefreshes: 8370523,
  bandwidthUsage: 8375632,
  billingInformation: 8370532,
  configure: 8375285,
  verified: 8581597,
  connectionMethod: 8375305,
  country: 8370539,
  dashboardNotification: 8370529,
  highConcurrency: 8375281,
  highPriority: 8375284,
  hostname: 8375273,
  invoices: 8370532,
  ipAuthorizations: 8375419,
  manageAccount: 8370537,
  paymentMethod: 8370525,
  pendingVerification: 8375269,
  proxyCountry: 8375651,
  proxyDetails: 8375303,
  proxyPlan: 8370543,
  proxyProtocol: 8370540,
  proxySessionTimeout: 8375624,
  proxyUsage: 8370531,
  proxyUsername: 8375637,
  replacements: 8375639,
  replacing: 8375627,
  rotatingProxyEndpoint: 8375645,
  selectProxyDetails: 8370542,
  status: 8374686,
  subscription: 8370526,
  totalBandwidthUsed: 8370524,
  yourSubscription: 8370534,
  countryConfidence: 9726395,
};
