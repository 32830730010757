import WebshareApi from "../api";
import React, { useCallback } from "react";
import { getCookie, setCookie, deleteCookie } from "cookies-next";
import { setDomain } from "./authentication";
import { FEDERATION_TOKEN_COOKIE_KEY } from "@/config/cookiesKeys";

const cookieOptions = {
  domain: String(process.env.NEXT_PUBLIC_COOKIE_DOMAIN) ?? ".webshare.io",
  secure: true,
};

export function useFederatedUser() {
  const [federationToken, _setFederationToken] = React.useState<
    string | undefined
  >(undefined);

  const setFederationToken = useCallback((token: string) => {
    WebshareApi.setFederationToken(token);
    setCookie(FEDERATION_TOKEN_COOKIE_KEY, token, setDomain());
    _setFederationToken(token);
  }, []);

  const deleteFederationToken = useCallback(() => {
    WebshareApi.removeFederationToken();
    deleteCookie(FEDERATION_TOKEN_COOKIE_KEY, {
      ...cookieOptions,
      sameSite: "strict",
    });
    _setFederationToken(undefined);
    setCookie("sessionid", "", { ...cookieOptions, sameSite: "strict" });
  }, []);

  React.useEffect(() => {
    const cookie = getCookie(FEDERATION_TOKEN_COOKIE_KEY);
    if (cookie && String(cookie).trim() !== "") {
      _setFederationToken(String(cookie));
      WebshareApi.setFederationToken(String(cookie));
    }
  }, []);

  return {
    federationToken,
    deleteFederationToken,
    setFederationToken,
  };
}
