import { SeverityLevel, captureMessage, withScope } from "@sentry/nextjs";

interface IProps {
  extras?: [string, unknown][];
  level?: SeverityLevel;
}

export default function logToSentry(message: string, props?: IProps) {
  const { extras, level = "error" } = props || {};
  withScope((scope) => {
    scope.setLevel(level);

    if (extras) {
      extras.forEach(([key, value]) => {
        scope.setExtra(key, value);
      });
    }
    captureMessage(message);
  });
}
