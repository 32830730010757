import { createContext, useContext } from "react";

export const DRAWER_WIDTH = 256;

export type DrawerType = {
  open: boolean;
  drawerWidth: number;
};

export const DrawerContext = createContext<DrawerType>({
  open: false,
  drawerWidth: DRAWER_WIDTH,
});

export function useDrawerContext() {
  return useContext(DrawerContext);
}
