import { NavItemType } from "../types";

export default function checkIfItemSelected(
  router: any,
  item: Partial<NavItemType>
) {
  return (
    item.href === router.pathname ||
    (item.alternativeHrefs
      ? item.alternativeHrefs.includes(router.pathname)
      : false)
  );
}
