export enum LocalStorageKeys {
  SessionEmailChanged = "session:emailchanged",
  ProxyListColumnsVisibility = "ProxyListColumnsVisibility",
  GlobalConfig = "GlobalConfig",
  VipPageShown = "VipPageShown",
  // TODO: Remove right after implementing it on the backend
  isActivityPageViewed = "isActivityPageViewed",
}

export const LocalStorageKeysThatShouldNotBePersist = [
  LocalStorageKeys.SessionEmailChanged,
];
