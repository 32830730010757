import { styled, Card } from "@mui/material";

export const ColoredCard = styled(Card)<{
  type: "warning" | "error" | "primary" | "grey" | "greyMid" | "info";
}>(({ theme, type }) => ({
  "&::before": {
    opacity: type === "grey" || type === "greyMid" ? 1 : 0.1,
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: {
      error: theme.palette.error.main,
      warning: theme.palette.warning.main,
      primary: theme.palette.primary.main,
      info: theme.palette.info.main,
      grey: theme.palette.grey[50],
      greyMid: theme.palette.grey[100],
    }[type],
    zIndex: -1,
  },
  position: "relative",
  padding: theme.spacing(3),
  borderRadius: 8,
  zIndex: 0,
}));
