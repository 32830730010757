import axios, { AxiosInstance, AxiosRequestHeaders, AxiosError } from "axios";
import { getCookie } from "cookies-next";
import { FEDERATION_TOKEN_COOKIE_KEY } from "@/config/cookiesKeys";

export function isAxiosError<ResponseType>(
  error: unknown
): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error);
}

class WebhsareApi {
  private baseUrl = process.env.NEXT_PUBLIC_BACKEND;
  private axiosInstance: AxiosInstance;
  private headerConfig: AxiosRequestHeaders;
  private AuthToken?: string;
  private FederationToken?: string;

  constructor() {
    this.axiosInstance = axios.create({ baseURL: this.baseUrl });
    this.axiosInstance.defaults.withCredentials = true;
    this.headerConfig = this.axiosInstance.defaults.headers.common;

    this.axiosInstance.interceptors.request.use(
      (config) => {
        config.headers = {
          ...this.headerConfig,
          ...config.headers,
        };
        return config;
      },
      (error) => Promise.reject(error)
    );
    // We need this part to avoid calling backend without when we already have it inside cookies
    const cookie = getCookie(FEDERATION_TOKEN_COOKIE_KEY);
    if (cookie && String(cookie).trim() !== "") {
      this.setFederationToken(String(cookie));
    }
  }

  public setAuthToken(authToken: string) {
    this.headerConfig = {
      ...this.headerConfig,
      Authorization: "Token " + authToken,
    };
    this.AuthToken = authToken;
  }

  public removeFederationToken() {
    this.FederationToken = undefined;
    this.headerConfig = {
      ...this.headerConfig,
    };
    delete this.headerConfig["X-Webshare-Federated-Access"];
  }

  public setFederationToken(userId: string) {
    this.headerConfig = {
      ...this.headerConfig,
      "X-Webshare-Federated-Access": userId,
    };
    this.FederationToken = userId;
  }

  public setSubuserHeader(subUserId: string) {
    this.headerConfig = {
      ...this.headerConfig,
      "X-Subuser": subUserId,
    };
  }

  public removeSubUserHeader() {
    delete this?.headerConfig?.["X-Subuser"];
  }

  public getAuthToken() {
    return this.AuthToken;
  }

  public getFederationToken() {
    return this.FederationToken;
  }

  public getSubUserId() {
    return this?.headerConfig?.["X-Subuser"] as string;
  }

  public removeAuthToken() {
    delete this.headerConfig.Authorization;
  }

  public getInstance() {
    return this.axiosInstance;
  }
}

const webshareAPI = new WebhsareApi();
export default webshareAPI;
