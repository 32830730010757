import React, { createContext, useState } from "react";
import { Snackbar, Typography, useTheme } from "@mui/material";
import Alert from "@/components/alerts/Alert";

type UpdateFeedBackType = {
  open: boolean;
  type: "error" | "default" | "success" | "warning";
  message?: string | React.ReactNode;
  duration?: number;
  placement?: "bottom" | "top";
};

interface ISnackbar {
  feedback: UpdateFeedBackType;
  setFeedback: React.Dispatch<React.SetStateAction<UpdateFeedBackType>>;
}

const SnackbarContextDefaultValue: ISnackbar = {
  feedback: {
    open: false,
    type: "default",
    duration: 3000,
    placement: "bottom",
  },
  setFeedback: () => {},
};

export const SnackbarContext = createContext<ISnackbar>(
  SnackbarContextDefaultValue
);

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { palette } = useTheme();

  const [feedback, setFeedback] = useState<UpdateFeedBackType>(
    SnackbarContextDefaultValue.feedback
  );

  const dismissFeedback = () =>
    setFeedback((data) => ({ ...data, open: false }));

  // Having a div around Alert is needed "SnackBar's child needs to be an element that can hold a ref"
  return (
    <SnackbarContext.Provider
      value={{
        feedback,
        setFeedback,
      }}
    >
      {children}

      <Snackbar
        style={{ backgroundColor: palette.grey[50], borderRadius: 4 }}
        open={feedback.open && feedback.type === "default"}
        autoHideDuration={feedback.duration}
        onClose={dismissFeedback}
        anchorOrigin={{
          vertical: feedback.placement || "bottom",
          horizontal: "center",
        }}
        message={feedback.message ?? "Your settings have been updated."}
        color={palette.common.white}
      />

      <Snackbar
        style={{ backgroundColor: palette.grey[50], borderRadius: 4 }}
        open={feedback.open && feedback.type !== "default"}
        autoHideDuration={feedback.duration}
        onClose={dismissFeedback}
        anchorOrigin={{
          vertical: feedback.placement || "bottom",
          horizontal: "center",
        }}
      >
        <div>
          <Alert
            onClose={dismissFeedback}
            severity={feedback.type !== "default" ? feedback.type : "info"}
            sx={{
              width: "100%",
              backgroundColor: palette.grey[50],
              color: palette.common.white,
            }}
          >
            <Typography variant="body2" color={palette.common.white}>
              {feedback.message ? (
                <span>{feedback.message}</span>
              ) : (
                <span>Something went wrong, please try again later.</span>
              )}
            </Typography>
          </Alert>
        </div>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
