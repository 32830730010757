import subroutes from "../components/navigation/secondaryroutes";
import routes from "../components/navigation/routes";

export type RouteTypes = typeof routes | typeof subroutes;

export const getParentIndexOfRoute = (route: string, routes: RouteTypes) =>
  routes.findIndex((parentRoute) =>
    parentRoute?.items?.some((childRoute) => {
      return (
        childRoute.href === route ||
        childRoute?.alternativeHrefs?.includes(route)
      );
    })
  );
